import { Container, Unstable_Grid2 as Grid, styled, Typography } from '@mui/material'
import { useLoaderData, useNavigation } from 'react-router-dom'
import LocationHandler from '../../../components/data/locations/LocationHandler'
import { ListExcerptSkeleton } from '../../../components/feedback/skeleton/Skeletons'
import { PageWrapper } from '../../../components/layout/box/PageWrapper'
import DateBar from '../../../components/sections/DateBar'
import CategoryService from '../../../services/categoryService'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(() => ({
	maxWidth: 620,
	margin: 'auto',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	alignItems: 'flex-start',
}))

// ----------------------------------------------------------------------

export async function loader({ params }) {
	const category = await CategoryService.findOneById(params.categoryId)
	return { category }
}

// ----------------------------------------------------------------------

export default function Category() {
	// @ts-ignore
	const { category } = useLoaderData()
	const navigation = useNavigation()

	return (
		<PageWrapper
			id={category ? `hlp-${category.data[0].slug}` : 'hlp-category'}
			description={category.data[0].description}
			imageAlt={category.data[0].alt}
			imagePath={category.data[0].image}
			title={category.data[0].title}>
			<Container>
				<ContentStyle>
					<Grid
						container
						alignItems='center'
						direction='row'
						justifyContent='flex-start'>
						<Grid xs={12}>
							<DateBar />
						</Grid>
						<Grid xs={12}>
							{navigation.state === 'loading' ? (
								<ListExcerptSkeleton />
							) : !category ? (
								<Typography
									align='center'
									variant='subtitle1'>
									Something went wrong!
								</Typography>
							) : (
								<LocationHandler category={category.data[0].category} />
							)}
						</Grid>
					</Grid>
				</ContentStyle>
			</Container>
		</PageWrapper>
	)
}
