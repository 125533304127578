import { NavigateNextTwoTone } from '@mui/icons-material'
import { Breadcrumbs } from '@mui/material'

/**
 * @param {{ breadcrumbs: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; }} props
 */
export default function GlobalBreadcrumbs(props) {
	const { breadcrumbs } = props

	return (
		<Breadcrumbs
			component='span'
			maxItems={4}
			separator={<NavigateNextTwoTone fontSize='small' />}
			aria-label='breadcrumb'>
			{breadcrumbs}
		</Breadcrumbs>
	)
}
