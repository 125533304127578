import { Box, Card, Unstable_Grid2 as Grid, Stack, useTheme } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'
import {
	ListingButton,
	ListingContent,
	ListingDivider,
	ListingHeader,
	ListingImage,
	ListingLink,
	ListingTitle,
} from './ListingComponents'

// ====================== ListingExcerpt ====================== //
/**
 * @param {{ alt: string; business: string; category: string; content: string; createdAt: string, id: string; location: string; logo: string; title: string; }} props
 */
export function ListingExcerpt(props) {
	const { alt, business, category, content, createdAt, id, location, logo, title } =
		props
	const smDown = useResponsive('down', 'sm')
	const smUp = useResponsive('up', 'sm')
	const mdDown = useResponsive('down', 'md')
	const theme = useTheme()

	return (
		<ListingLink
			id={id}
			category={category}
			createdAt={createdAt}
			mdDown={mdDown}
			children={
				<Card
					sx={{
						flexDirection: {
							xs: 'column', // mobile
							sm: 'row', // tablet and up
						},
					}}>
					<Grid
						container
						alignItems='center'
						direction='row'
						justifyContent='space-evenly'>
						<Grid
							xs={12}
							sm={8}>
							<Stack
								alignItems='flex-start'
								direction='column'
								justifyContent='space-evenly'>
								<ListingHeader
									alt={category + 'logo' || null}
									business={business}
									category={category}
									location={location}
								/>
								<Box
									pl={3}
									pb={3}
									pr={smDown ? 3 : 2}>
									<ListingDivider theme={theme} />
									<ListingTitle
										theme={theme}
										title={title}
									/>
									<ListingContent
										content={content}
										smDown={smDown}
										theme={theme}
									/>
								</Box>
							</Stack>
						</Grid>
						<Grid
							xs={0}
							sm={4}>
							{smUp && (
								<Stack
									alignItems='flex-end'
									direction='column'
									justifyContent='space-evenly'>
									<Box
										pr={3}
										py={3}>
										<ListingImage
											alt={alt}
											logo={logo}
										/>
										<ListingButton />
									</Box>
								</Stack>
							)}
						</Grid>
					</Grid>
				</Card>
			}
		/>
	)
}
