import { Link, Typography } from '@mui/material'
import { Outlet, Link as RouterLink } from 'react-router-dom'
import StaticLayout from '../components/layout/container/StaticLayout'


// ----------------------------------------------------------------------

const breadcrumbs = [
	<Link
		key={1}
		variant='subtitle1'
		component={RouterLink}
		underline='hover'
		to='/home'>
		Home
	</Link>,
	<Typography
		key={2}
		variant='subtitle1'
		color='text.primary'>
		Login
	</Typography>,
]

// ----------------------------------------------------------------------

export default function Login() {
	return (
		<StaticLayout
			id='hlp-login'
			breadcrumbs={breadcrumbs}
			description='Log in to access your FREE Helper account. Connect and share your business with prospective clients. Become a top Helper and grow your profits today!'
			heading='Login'
			imageAlt='helper-login'
			imagePath={`${process.env.REACT_APP_IMAGES_DOMAIN}/3f57e41c-cabd-41e4-9dde-81a59465d500`}
			title='Helper Login - Free & Easy Steps to Grow Your Business'
			children={<Outlet />}
		/>
	)
}
