import { Suspense, lazy } from 'react'
import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
	redirect,
} from 'react-router-dom'
import { FallbackSpinner } from './components/feedback/progress/FallbackSpinner'
import ErrorPage from './error-page'
import Index from './routes/Index'
import Layout from './routes/Layout'
import Login from './routes/Login'
import NoMatch from './routes/NoMatch'
import PublicLayout, { loader as publicLayoutLoader } from './routes/PublicLayout'
import Dashboard from './routes/auth/Dashboard'
import MyPosts, { loader as myPostsLoader } from './routes/auth/MyPosts'
import NewPost from './routes/auth/NewPost'
import AuthLayout, {
	loader as authLayoutLoader,
} from './routes/auth/components/AuthLayout'
import AuthNavigation, {
	loader as authNavigationLoader,
} from './routes/auth/components/AuthNavigation'
import Category, {
	loader as categoryLoader,
} from './routes/directory/categories/Category'
import { authService } from './services/authService'

// ----------------------------------------------------------------------

const Account = lazy(() => import('./routes/auth/Account'))
const Cookie = lazy(() => import('./routes/directory/Cookie'))
const Directory = lazy(() => import('./routes/directory/Directory'))
const Home = lazy(() => import('./routes/home/Home'))
const Listing = lazy(() => import('./routes/listings/Listing'))
const LoginIndex = lazy(() => import('./routes/LoginIndex'))
const ResetPassword = lazy(() =>
	import('./components/feedback/dialog/ResetPassword'),
)
const Pricing = lazy(() => import('./routes/static/Pricing'))
const Privacy = lazy(() => import('./routes/directory/Privacy'))
const Progress = lazy(() => import('./routes/static/Progress'))
const ProgCustomer = lazy(() => import('./routes/static/Progress.Customer'))
const ProgRegister = lazy(() => import('./routes/static/Progress.Register'))
const ProgReset = lazy(() => import('./routes/static/Progress.Reset'))
const ProgVerify = lazy(() => import('./routes/static/Progress.Verify'))
const Register = lazy(() => import('./routes/Register'))
const Settings = lazy(() => import('./routes/auth/Settings'))
const Terms = lazy(() => import('./routes/directory/Terms'))
const Toolbox = lazy(() => import('./routes/auth/Toolbox'))

const getCurrentHelperData = () =>
	new Promise((resolve) => {
		const currentHelper = authService.getCurrentHelper()
		resolve(currentHelper)
	})

// ----------------------------------------------------------------------

const router = createBrowserRouter(
	createRoutesFromElements(
		<>
			<Route
				id='root'
				path='/'
				loader={async () => {
					await getCurrentHelperData()
					return { currentHelper: authService.currentHelper }
				}}
				element={<Layout />}
				errorElement={<ErrorPage />}>
				<Route errorElement={<ErrorPage />}>
					<Route
						index
						element={<Index />}
					/>

					<Route
						path='home'
						element={
							<Suspense fallback={<FallbackSpinner />}>
								<Home />
							</Suspense>
						}
					/>

					<Route
						loader={categoryLoader}
						path='categories/:categoryId'
						element={
							<Suspense fallback={<FallbackSpinner />}>
								<Category />
							</Suspense>
						}
					/>

					<Route path='listings'>
						<Route
							path=':listingId/*'
							element={
								<Suspense fallback={<FallbackSpinner />}>
									<Listing />
								</Suspense>
							}
						/>
					</Route>

					<Route
						loader={publicLayoutLoader}
						element={<PublicLayout />}>
						<Route
							path='register'
							element={
								<Suspense fallback={<FallbackSpinner />}>
									<Register />
								</Suspense>
							}
						/>
						<Route
							path='login/*'
							element={
								<Suspense fallback={<FallbackSpinner />}>
									<Login />
								</Suspense>
							}>
							<Route
								index
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<LoginIndex />
									</Suspense>
								}
							/>
							<Route
								path=':email/:token'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<ResetPassword />
									</Suspense>
								}
							/>
						</Route>
						<Route
							path='progress/*'
							element={
								<Suspense fallback={<FallbackSpinner />}>
									<Progress />
								</Suspense>
							}>
							<Route
								index
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<ProgRegister />
									</Suspense>
								}
							/>
							<Route
								path=':email/:token'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<ProgVerify />
									</Suspense>
								}
							/>
							<Route
								path='new-helper'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<ProgCustomer />
									</Suspense>
								}
							/>
							<Route
								path='reset-password'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<ProgReset />
									</Suspense>
								}
							/>
						</Route>
					</Route>

					<Route
						loader={authLayoutLoader}
						element={<AuthLayout />}>
						<Route
							path='auth/*'
							loader={authNavigationLoader}
							element={
								<Suspense fallback={<FallbackSpinner />}>
									<AuthNavigation />
								</Suspense>
							}>
							<Route
								path='dashboard'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<Dashboard />
									</Suspense>
								}
								loader={authNavigationLoader}
							/>
							<Route
								path='listings/new'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<NewPost />
									</Suspense>
								}
								loader={authNavigationLoader}
							/>
							<Route
								path='listings'
								loader={myPostsLoader}
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<MyPosts />
									</Suspense>
								}
							/>
							<Route
								path='toolbox'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<Toolbox />
									</Suspense>
								}
								loader={authNavigationLoader}
							/>
							<Route
								path='account'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<Account />
									</Suspense>
								}
								loader={authNavigationLoader}
							/>
							<Route
								path='settings'
								element={
									<Suspense fallback={<FallbackSpinner />}>
										<Settings />
									</Suspense>
								}
								loader={authNavigationLoader}
							/>
						</Route>
					</Route>

					<Route
						path='directory'
						element={
							<Suspense fallback={<FallbackSpinner />}>
								<Directory />
							</Suspense>
						}
					/>
					<Route
						path='cookies'
						element={
							<Suspense fallback={<FallbackSpinner />}>
								<Cookie />
							</Suspense>
						}
					/>
					<Route
						path='pricing'
						element={
							<Suspense fallback={<FallbackSpinner />}>
								<Pricing />
							</Suspense>
						}
					/>
					<Route
						path='privacy'
						element={
							<Suspense fallback={<FallbackSpinner />}>
								<Privacy />
							</Suspense>
						}
					/>
					<Route
						path='terms'
						element={
							<Suspense fallback={<FallbackSpinner />}>
								<Terms />
							</Suspense>
						}
					/>
					<Route
						path='*'
						element={<NoMatch />}
					/>
				</Route>
			</Route>

			<Route
				path='/logout'
				action={async () => {
					await authService.logout()
					return redirect('/home')
				}}
			/>
		</>,
	),
)

export default function App() {
	return (
		<RouterProvider
			router={router}
			fallbackElement={<FallbackSpinner />}
		/>
	)
}
