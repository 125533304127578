// ----------------------------------------------------------------------

/**
 * @param {string | number} tel
 */
export function telsplit(tel) {
	const digits = String(tel).split('').map(Number)

	const arrOne = digits.slice(0, 3)
	const arrTwo = digits.slice(3, 6)
	const arrThree = digits.slice(6, 10)

	const setOne = arrOne.join('')
	const setTwo = arrTwo.join('')
	const setThree = arrThree.join('')

	return { setOne, setTwo, setThree }
}
