// ----------------------------------------------------------------------

/**
 * @param {import("@mui/material").Theme} theme
 */
export default function Link(theme) {
	return {
		MuiLink: {
			styleOverrides: {
				root: {
					color: theme.palette.secondary.main,
					textDecoration: 'underline',
				},
				button: {
					color: theme.palette.primary.main,
				},
			},
		},
	}
}
