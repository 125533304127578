import { TabContext, TabList } from '@mui/lab'
import { Box, Container, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import OnboardStepper from '../../../components/navigation/stepper/OnboardStepper'
import RouterLinkTab from '../../../components/navigation/tabs/RouterLinkTab'
import RouterLinkTabPanel from '../../../components/navigation/tabs/RouterLinkTabPanel'
import useResponsive from '../../../hooks/useResponsive'
import { authService } from '../../../services/authService'
import HelperService from '../../../services/helperService'

// ----------------------------------------------------------------------

export async function loader() {
	const id = authService.currentHelper
	return await HelperService.findOneByAuthUser(id)
}

// ----------------------------------------------------------------------

export default function AuthNavigation() {
	// @ts-ignore
	const [value, setValue] = useState('0')
	const mdUp = useResponsive('up', 'md')
	const theme = useTheme()

	const handleChange = (
		/** @type {any} */ event,
		/** @type {import("react").SetStateAction<string>} */ newValue,
	) => {
		setValue(newValue)
	}

	// @ts-ignore
	const { data } = useLoaderData()
	const { id, isOnboarded } = data

	return !isOnboarded ? (
		<OnboardStepper id={id} />
	) : (
		<Box sx={{ flexGrow: 1 }}>
			<Container
				disableGutters
				maxWidth='lg'>
				<Grid
					container
					spacing={2}
					mt={4}>
					<TabContext value={value}>
						<Grid
							xs={12}
							md={3}
							mt={mdUp ? 20 : 2}>
							<Box
								borderRight={mdUp ? 1 : null}
								borderBottom={mdUp ? null : 1}
								borderColor='divider'>
								<TabList
									selectionFollowsFocus
									aria-label={mdUp ? 'centered tab group' : 'full width tab group'}
									centered={mdUp ? true : false}
									onChange={handleChange}
									orientation='vertical'
									variant={mdUp ? 'standard' : 'fullWidth'}
									sx={
										mdUp
											? {
													'display': 'flex',
													'overflow': 'auto',
													'justifyContent': 'space-between',
													'alignSelf': 'flex-end',
													'& a': {
														flexShrink: 0,
														display: 'flex',
														alignItems: 'center',
														padding: theme.spacing(1, 2),
														cursor: 'pointer',
														textDecoration: 'none',
														transition: '0.2s ease-out',
													},
													'& a:hover': {
														transform: 'translateY(-4px)',
														color: theme.palette.primary.main,
													},
													'& a:focus': {
														color: theme.palette.primary.dark,
													},
													'& a:active': {
														color: theme.palette.primary.dark,
													},
													'& a.Mui-selected': {
														color: theme.palette.secondary.dark,
														background: 'transparent',
														backgroundImage: `linear-gradient(to top, ${theme.palette.primary.light}) 100%, rgba(227,230,232,.6) 50%, rgba(249,250,251,.1) 70%)`,
													},
													'& .MuiTabs-indicator': {
														backgroundColor: theme.palette.primary.main,
													},
											  }
											: {
													'mb': 1,
													'mx': 'auto',
													'width': '50%',
													'& a': {
														flexShrink: 0,
														cursor: 'pointer',
														textDecoration: 'none',
														transition: '0.2s ease-out',
													},
													'& a:hover': {
														transform: 'translateY(-4px)',
														color: theme.palette.primary.main,
													},
													'& a:focus': {
														color: theme.palette.primary.dark,
													},
													'& a:active': {
														color: theme.palette.primary.dark,
													},
													'& a.Mui-selected': {
														'background': (theme) => theme.palette.secondary.dark,
														'color': (theme) => theme.palette.primary.main,
														'mx': 'auto',
														'maxWidth': '360px',
														'&:hover': {
															background: (theme) => theme.palette.secondary.main,
														},
													},
													'& .MuiTabs-indicator': {
														display: mdUp ? 'inherit' : 'none',
														backgroundColor: theme.palette.primary.main,
													},
											  }
									}>
									<RouterLinkTab
										label='Dashboard'
										value='0'
										to='dashboard'
									/>
									<RouterLinkTab
										label='New Post'
										value='1'
										to='listings/new'
									/>
									<RouterLinkTab
										label='My Posts'
										value='2'
										to='listings'
									/>
									<RouterLinkTab
										label='Toolbox'
										value='3'
										to='toolbox'
									/>
									<RouterLinkTab
										label='Account'
										value='4'
										to='account'
									/>
									<RouterLinkTab
										label='Settings'
										value='5'
										to='settings'
									/>
								</TabList>
							</Box>
						</Grid>
						<Grid
							xs={12}
							md={9}>
							<RouterLinkTabPanel value='0' />
							<RouterLinkTabPanel value='1' />
							<RouterLinkTabPanel value='2' />
							<RouterLinkTabPanel value='3' />
							<RouterLinkTabPanel value='4' />
							<RouterLinkTabPanel value='5' />
						</Grid>
					</TabContext>
				</Grid>
			</Container>
		</Box>
	)
}
