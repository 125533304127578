import { Box, Grid, Stack, Typography } from '@mui/material'
import { ResponsiveDivider } from '../../display/divider/ResponsiveDivider'
import SingleLocationAutocomplete from '../../inputs/autocomplete/SingleLocationAutocomplete'

// -------------------------------------------------------------------

/**
 * @param {{ lgUp: boolean; mdUp: boolean; smUp: boolean; }} props
 */
export default function SectionOne(props) {
	const { lgUp, mdUp, smUp } = props
	return (
		<Box
			component='section'
			bgcolor='background.default'
			minHeight={500}
			position='relative'
			{...(mdUp && { height: '60vh' })}>
			<Grid
				container
				alignItems='center'
				direction='column'
				justifyContent='center'
				position='absolute'>
				<Grid
					item
					xs={12}
					mb={lgUp ? 6 : mdUp ? 5 : 4}>
					<Typography
						align='center'
						color='primary.main'
						component='h1'
						display={smUp ? 'inline' : 'default'}
						letterSpacing={2.5}
						variant='h1'>
						HELP
						<wbr />
						<span
							className='stroke-text smooth-16'
							style={{
								fontSize: lgUp
									? '92.9728px'
									: mdUp
									? '82.6896px'
									: smUp
									? '72.4064px'
									: '53px',
							}}>
							LISTED
						</span>
					</Typography>
				</Grid>
				<ResponsiveDivider />
				<Grid
					item
					xs={12}
					mt={lgUp ? 6 : mdUp ? 5 : 4}>
					<Typography
						align='center'
						color='text.secondary'
						component='h2'
						textTransform='none'
						variant='h3'>
						Looking for home service pros near you?
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					mt={lgUp ? 6 : mdUp ? 5 : 4}>
					<Stack
						direction={mdUp ? 'row' : 'column'}
						spacing={1}
						width={mdUp ? '500px' : '250px'}
						{...(mdUp && { justifyContent: 'center' })}
						{...(!mdUp && { alignItems: 'center' })}>
						<SingleLocationAutocomplete mdUp={mdUp} />
					</Stack>
				</Grid>
			</Grid>
		</Box>
	)
}
