import { TextField, Typography } from '@mui/material'

/**
 * @param {{ formik: { handleBlur: import("react").FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>; handleChange: import("react").ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>; values: { tel: unknown; }; touched: { tel: any; }; errors: { tel: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; }; }; mdUp: boolean; sending: boolean; smUp: boolean; }} props
 */
export function HelperTelInput(props) {
	const { formik, mdUp, sending, smUp } = props
	const { errors, handleBlur, handleChange, touched, values } = formik
	return (
		<TextField
			id='hlp-onboard-tel'
			fullWidth
			required
			aria-describedby='helperText'
			aria-required='true'
			autoComplete='tel-national'
			color='secondary'
			disabled={sending}
			error={touched.tel && Boolean(errors.tel)}
			label='Phone'
			margin='normal'
			name='tel'
			onBlur={handleBlur}
			onChange={handleChange}
			placeholder='8008675309'
			type='text'
			value={values.tel}
			variant='outlined'
			helperText={
				<Typography
					component='span'
					variant='caption'>
					{touched.tel && Boolean(errors.tel)
						? errors.tel
						: 'A number where customers can reach you'}
				</Typography>
			}
			sx={{ mt: 2, width: mdUp ? '450px' : smUp ? '275px' : 'default' }}
		/>
	)
}
