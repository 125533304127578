import { Divider, useTheme } from '@mui/material'

export function ResponsiveDivider() {
	const theme = useTheme()
	return (
		<Divider
			aria-hidden='true'
			sx={{
				borderRadius: '3px',
				borderTop: `6px solid ${theme.palette.primary.main}`,
				mx: 'auto',
				width: '10%',
			}}
		/>
	)
}
