import { Tab } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

// ----------------------------------------------------------------------

/**
 * @param {{ label: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; to: import("react-router-dom").To; value: string; }} props
 */
export default function RouterLinkTab(props) {
	const { label, to, value } = props
	return (
		<Tab
			component={RouterLink}
			disableRipple
			label={label}
			to={to}
			value={value}
			sx={{ my: 0.5 }}
			{...props}
		/>
	)
}
