import { ClickAwayListener } from '@mui/base'
import { AppBar, Box, useTheme } from '@mui/material'
import { Suspense, lazy, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useResponsive from '../../hooks/useResponsive'
import {
	LeftDrawerSkeleton,
	RightDrawerSkeleton,
} from '../feedback/skeleton/Skeletons'
import ScrollTop from '../inputs/FAB/ScrollTop'
import { Navbar, NavbarLite } from './navbar/Navbar'
import StyledToolbar from './navbar/StyledToolbar'

// ----------------------------------------------------------------------

const DrawerLeft = lazy(() => import('./drawer/DrawerLeft'))
const DrawerRight = lazy(() => import('./drawer/DrawerRight'))
const DrawerSlideTransition = lazy(() => import('./drawer/DrawerSlideTransition'))

// ----------------------------------------------------------------------

export default function Navigation() {
	const [leftDrawerOpen, setLeftDrawerOpen] = useState(false)
	const [rightDrawerOpen, setRightDrawerOpen] = useState(false)
	const [visible, setVisible] = useState(false)
	const mdUp = useResponsive('up', 'md')
	const { pathname } = useLocation()
	const theme = useTheme()

	const handleLeftDrawerOpen = () => {
		setLeftDrawerOpen(true)
	}

	const handleLeftDrawerClose = () => {
		setLeftDrawerOpen(false)
	}

	const handleRightDrawerOpen = () => {
		setRightDrawerOpen(true)
	}

	const handleRightDrawerClose = () => {
		setRightDrawerOpen(false)
	}

	const delayRightDrawer = async () => {
		await new Promise((resolve) =>
			setTimeout(() => {
				resolve(setVisible(true))
			}, 3000),
		)
	}

	delayRightDrawer()

	return (
		<>
			<AppBar
				id='hlp-navbar'
				position='fixed'
				sx={{
					backgroundColor: theme.palette.grey[0],
					boxShadow: `0, 2px, 2px, -4px ${theme.palette.grey[200]}`,
					zIndex: theme.zIndex.drawer + 1,
				}}>
				{pathname !== '/' && mdUp ? (
					<Navbar
						open={leftDrawerOpen}
						handleDrawerOpen={handleLeftDrawerOpen}
						handleDrawerClose={handleLeftDrawerClose}
					/>
				) : (
					<NavbarLite
						open={leftDrawerOpen}
						handleDrawerOpen={handleLeftDrawerOpen}
						handleDrawerClose={handleLeftDrawerClose}
					/>
				)}
			</AppBar>
			<StyledToolbar />
			<ScrollTop />
			<Suspense fallback={<LeftDrawerSkeleton />}>
				<DrawerLeft
					open={leftDrawerOpen}
					handleDrawerClose={handleLeftDrawerClose}
				/>
			</Suspense>

			<Suspense fallback={<RightDrawerSkeleton />}>
				<ClickAwayListener onClickAway={handleRightDrawerClose}>
					<Box
						id='hlp-drawer-right'
						component='aside'
						role='presentation'>
						<DrawerSlideTransition
							visible={visible && !rightDrawerOpen}
							handleDrawerOpen={handleRightDrawerOpen}
						/>
						<DrawerRight
							open={rightDrawerOpen}
							setDrawerOpen={setRightDrawerOpen}
							handleDrawerClose={handleRightDrawerClose}
						/>
					</Box>
				</ClickAwayListener>
			</Suspense>
		</>
	)
}
