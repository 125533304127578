// ----------------------------------------------------------------------

/**
 * @param {import("@mui/material").Theme} theme
 */
export default function Button(theme) {
	return {
		MuiButton: {
			styleOverrides: {
				outlined: {
					color: 'inherit',
				},
				outlinedPrimary: {
					border: `default solid ${theme.palette.primary.main}`,
				},
				containedSecondary: {
					'color': theme.palette.primary.main,
					'backgroundColor': theme.palette.secondary.dark,
					'&:hover': {
						backgroundColor: theme.palette.secondary.main,
					},
				},
				sizeLarge: {
					height: 42,
				},
			},
		},
	}
}
