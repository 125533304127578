import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, Container, Stack, Tab, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export default function CtaTabs() {
	const [value, setValue] = useState('1')
	const theme = useTheme()

	const handleChange = (
		/** @type {any} */ event,
		/** @type {import("react").SetStateAction<string>} */ newValue,
	) => {
		setValue(newValue)
	}

	return (
		<TabContext value={value}>
			<TabPanel value='1'>
				<Typography
					align='center'
					component='h3'
					variant='h5'>
					I'm a <strong>homeowner / property manager</strong> looking to hire a local
					contractor.
				</Typography>
			</TabPanel>
			<TabPanel value='2'>
				<Typography
					align='center'
					component='h3'
					variant='h5'>
					I'm a <strong>contractor/ home service pro</strong> looking to promote my
					business and find new clients.
				</Typography>
			</TabPanel>
			<Stack
				direction={'column'}
				justifyContent='center'
				alignItems='center'
				spacing={4}>
				<Container maxWidth='sm'>
					<TabList
						centered
						aria-label={'call-to-action-tab-group'}
						onChange={handleChange}
						textColor='inherit'
						variant='fullWidth'
						sx={{
							'backgroundColor': theme.palette.common.white,
							'borderRadius': 2,
							'minHeight': 64,
							'& .MuiTabs-indicator': {
								'top': 3,
								'bottom': 3,
								'right': 3,
								'height': 'auto',
								'background': 'none',
								'&:after': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									left: 4,
									right: 4,
									bottom: 0,
									borderRadius: 2,
									boxShadow: '0 4px 12px 0 rgba(0,0,0,0.16)',
									border: `1px solid ${
										value === '1'
											? theme.palette.primary.main
											: theme.palette.info.main
									}`,
								},
							},
						}}>
						<Tab
							disableRipple
							wrapped
							label='Customer'
							value='1'
							sx={{
								'borderRadius': 2,
								'minHeight': 64,
								'&:hover': {
									opacity: 1,
								},
								'&.MuiTab-wrapped': {
									color: theme.palette.text.primary,
								},
							}}
						/>
						<Tab
							disableRipple
							wrapped
							label='Contractor'
							value='2'
							sx={{
								'borderRadius': 2,
								'minHeight': 64,
								'&:hover': {
									opacity: 1,
								},
								'&.MuiTab-wrapped': {
									color: theme.palette.text.primary,
								},
							}}
						/>
					</TabList>
				</Container>
				<Button
					component={RouterLink}
					to={value === '1' ? '/home' : '/register'}
					variant='contained'
					{...(value === '2' && { color: 'info' })}>
					<Typography
						variant='button'
						{...(value === '2' && { color: theme.palette.grey[50] })}>
						{value === '1' ? 'Find Contractors' : 'Find Customers'}
					</Typography>
				</Button>
			</Stack>
		</TabContext>
	)
}
