import { Button, ButtonGroup, useTheme } from '@mui/material'
import { Link as RouterLink, useFetcher } from 'react-router-dom'
import { authService } from '../../../services/authService'

// -------------------------------------------------------------------

const buttonGroupId = 'hlp-nav-button-group'

// -------------------------------------------------------------------

export default function NavbarButtons() {
	const fetcher = useFetcher()
	const isLoggingOut = fetcher.formData != null
	const theme = useTheme()

	return (
		<ButtonGroup id={buttonGroupId}>
			{authService.isAuthenticated ? (
				<>
					<Button
						id='hlp-dashboard'
						component={RouterLink}
						size='large'
						to='/auth/dashboard'
						variant='contained'
						sx={{
							'background': theme.palette.secondary.dark,
							'color': theme.palette.primary.main,
							'maxWidth': '360px',
							'mr': 0.25,
							'&:hover': {
								background: theme.palette.secondary.main,
							},
						}}>
						Dashboard
					</Button>
					<fetcher.Form
						action='/logout'
						method='post'>
						<Button
							id='hlp-logout'
							aria-label='logout current user'
							disabled={isLoggingOut}
							size='large'
							type='submit'
							variant='contained'
							sx={{
								'background': theme.palette.secondary.dark,
								'color': theme.palette.primary.main,
								'maxWidth': '360px',
								'ml': 0.25,
								'&:hover': {
									background: theme.palette.secondary.main,
								},
							}}>
							{isLoggingOut ? '...👋' : 'Logout'}
						</Button>
					</fetcher.Form>
				</>
			) : (
				<>
					<Button
						id='hlp-register'
						component={RouterLink}
						size='large'
						to='/register'
						type='button'
						variant='contained'
						sx={{
							'background': theme.palette.secondary.dark,
							'color': theme.palette.primary.main,
							'mx': 'auto',
							'maxWidth': '360px',
							'mr': 0.25,
							'&:hover': {
								background: theme.palette.secondary.main,
							},
						}}>
						Register
					</Button>
					<Button
						id='hlp-login'
						component={RouterLink}
						size='large'
						to='/login'
						type='button'
						variant='contained'
						sx={{
							'background': theme.palette.secondary.dark,
							'color': theme.palette.primary.main,
							'maxWidth': '360px',
							'ml': 0.25,
							'&:hover': {
								background: theme.palette.secondary.main,
							},
						}}>
						Login
					</Button>
				</>
			)}
		</ButtonGroup>
	)
}
