import axios from 'axios'

// ----------------------------------------------------------------------

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_DOMAIN}/authapi/v1`,
	headers: {
		'Content-Type': 'application/json',
	},
	timeout: 15000,
	withCredentials: true,
})

export default instance
