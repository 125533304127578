import {
	CssBaseline,
	ThemeProvider as MUIThemeProvider,
	StyledEngineProvider,
	createTheme,
	responsiveFontSizes,
} from '@mui/material'
import { enUS } from '@mui/material/locale'
import { useMemo } from 'react'
import { componentsOverride } from './overrides'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'

// ----------------------------------------------------------------------

/**
 * @param {{ children: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | import("react").ReactPortal }} props
 */
export default function ThemeProvider(props) {
	const { children } = props
	const themeOptions = useMemo(
		() => ({
			palette,
			typography,
			shadows,
		}),
		[],
	)

	// @ts-ignore
	let theme = createTheme(themeOptions, enUS)
	theme = responsiveFontSizes(theme)
	theme.components = componentsOverride(theme)

	return (
		<StyledEngineProvider injectFirst>
			<MUIThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</MUIThemeProvider>
		</StyledEngineProvider>
	)
}
