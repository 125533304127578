// ----------------------------------------------------------------------

export default function Alert() {
	return {
		MuiAlert: {
			styleOverrides: {
				root: {
					elevation: 6,
					variant: 'outlined',
				},
			},
		},
	}
}
