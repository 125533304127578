import { Avatar, useTheme } from '@mui/material'
import { getLogoFromCategory } from '../../../utils/categoryHandler'

/**
 * @param {{ alt: string; category: string; }} props
 */
export default function CategoryAvatar(props) {
	const { alt, category } = props
	const theme = useTheme()
	return (
		<Avatar
			alt={alt}
			src={getLogoFromCategory(category)}
			sx={{
				border: `1px solid ${theme.palette.secondary.dark}`,
				backgroundColor: theme.palette.primary.light,
				height: 66,
				m: 1,
				width: 66,
			}}
			variant='rounded'
		/>
	)
}
