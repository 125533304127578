import { H6464 } from './variants'

// ----------------------------------------------------------------------

const paths = [
	'/categories/cleaning',
	'/categories/electrical',
	'/categories/flooring',
	'/categories/handyman',
	'/categories/hvac',
	'/categories/design',
	'/categories/landscaping',
	'/categories/locksmithing',
	'/categories/moving',
	'/categories/painting',
	'/categories/pest',
	'/categories/plumbing',
	'/categories/pool',
	'/categories/restoration',
	'/categories/roofing',
	'/categories/removal',
	'/categories/smart',
	'/categories/windoors',
]

// ----------------------------------------------------------------------

const getLogoFromCategory = (/** @type {string} */ category) =>
	category === 'Cleaning'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/0d7b31e8-54ab-44a4-d567-dea3d53d0b00/${H6464}`
		: category === 'Electrical'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/efb080e8-7e90-4e82-c5b3-2aad825c8400/${H6464}`
		: category === 'Flooring'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/2b8da27f-d43a-4451-8f52-c3ee9ba54d00/${H6464}`
		: category === 'Handyman'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/e3d5246e-0736-4fac-b520-62a80e310f00/${H6464}`
		: category === 'HVAC'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/a915b023-776c-4956-7ba4-c71ff3880000/${H6464}`
		: category === 'Interior Design'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/96d1c0b4-5aad-411e-8857-e67781642300/${H6464}`
		: category === 'Landscaping'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/a0bffd6a-28a7-423c-defa-fe27f4a15700/${H6464}`
		: category === 'Locksmithing'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/9ae5dbd3-2814-493f-9878-3267ccb3c400/${H6464}`
		: category === 'Moving'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/fc763071-8665-45eb-3d20-7706df46af00/${H6464}`
		: category === 'Painting'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/313e454b-8308-43ae-292d-1d7f2aeb8f00/${H6464}`
		: category === 'Pest Control'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/d400a3c3-510d-4318-54ae-c8ad29b78300/${H6464}`
		: category === 'Plumbing'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/0962311f-1d73-4745-8bbe-6df612bd2900/${H6464}`
		: category === 'Pool Services'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/ed3d1506-1af0-4942-93b4-d0a66064e300/${H6464}`
		: category === 'Restoration'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/da5c188c-d7e4-4199-b00b-4f84f9e6a500/${H6464}`
		: category === 'Roofing'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/ad2a4e04-107a-41d5-ee37-e478c8964e00/${H6464}`
		: category === 'Scrap Removal'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/a545f215-fd7b-4590-5b3a-869df1ff7f00/${H6464}`
		: category === 'Smart Home'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/05aea5b4-addd-4727-7859-38ef1e9b3100/${H6464}`
		: category === 'Window & Door'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/bda2f9f7-7bd2-4d10-1b2a-aaf3c7e3ec00/${H6464}`
		: `${process.env.REACT_APP_IMAGES_DOMAIN}/2c233077-d4bd-46a3-efe4-4ac0442f7600/${H6464}`

const getPathFromCategory = (/** @type {string} */ category) =>
	category === 'Cleaning'
		? '/categories/cleaning'
		: category === 'Electrical'
		? '/categories/electrical'
		: category === 'Flooring'
		? '/categories/flooring'
		: category === 'Handyman'
		? '/categories/handyman'
		: category === 'HVAC'
		? '/categories/hvac'
		: category === 'Interior Design'
		? '/categories/design'
		: category === 'Landscaping'
		? '/categories/landscaping'
		: category === 'Locksmithing'
		? '/categories/locksmithing'
		: category === 'Moving'
		? '/categories/moving'
		: category === 'Painting'
		? '/categories/painting'
		: category === 'Pest Control'
		? '/categories/pest'
		: category === 'Plumbing'
		? '/categories/plumbing'
		: category === 'Pool Services'
		? '/categories/pool'
		: category === 'Restoration'
		? '/categories/restoration'
		: category === 'Roofing'
		? '/categories/roofing'
		: category === 'Scrap Removal'
		? '/categories/removal'
		: category === 'Smart Home'
		? '/categories/smart'
		: category === 'Window & Door'
		? '/categories/windoors'
		: '/home'

const getLogoFromPath = (
	/** @type {string} */ pathname,
	/** @type {string} */ size,
) =>
	pathname === '/categories/cleaning'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/0d7b31e8-54ab-44a4-d567-dea3d53d0b00/${size}`
		: pathname === '/categories/electrical'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/efb080e8-7e90-4e82-c5b3-2aad825c8400/${size}`
		: pathname === '/categories/flooring'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/2b8da27f-d43a-4451-8f52-c3ee9ba54d00/${size}`
		: pathname === '/categories/handyman'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/e3d5246e-0736-4fac-b520-62a80e310f00/${size}`
		: pathname === '/categories/hvac'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/a915b023-776c-4956-7ba4-c71ff3880000/${size}`
		: pathname === '/categories/design'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/96d1c0b4-5aad-411e-8857-e67781642300/${size}`
		: pathname === '/categories/landscaping'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/a0bffd6a-28a7-423c-defa-fe27f4a15700/${size}`
		: pathname === '/categories/locksmithing'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/9ae5dbd3-2814-493f-9878-3267ccb3c400/${size}`
		: pathname === '/categories/moving'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/fc763071-8665-45eb-3d20-7706df46af00/${size}`
		: pathname === '/categories/painting'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/313e454b-8308-43ae-292d-1d7f2aeb8f00/${size}`
		: pathname === '/categories/pest'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/d400a3c3-510d-4318-54ae-c8ad29b78300/${size}`
		: pathname === '/categories/plumbing'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/0962311f-1d73-4745-8bbe-6df612bd2900/${size}`
		: pathname === '/categories/pool'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/ed3d1506-1af0-4942-93b4-d0a66064e300/${size}`
		: pathname === '/categories/restoration'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/da5c188c-d7e4-4199-b00b-4f84f9e6a500/${size}`
		: pathname === '/categories/roofing'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/ad2a4e04-107a-41d5-ee37-e478c8964e00/${size}`
		: pathname === '/categories/removal'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/a545f215-fd7b-4590-5b3a-869df1ff7f00/${size}`
		: pathname === '/categories/smart'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/05aea5b4-addd-4727-7859-38ef1e9b3100/${size}`
		: pathname === '/categories/windoors'
		? `${process.env.REACT_APP_IMAGES_DOMAIN}/bda2f9f7-7bd2-4d10-1b2a-aaf3c7e3ec00/${size}`
		: null

const getCategoryFromPath = (/** @type {string} */ pathname) =>
	pathname === '/categories/cleaning'
		? 'Cleaning'
		: pathname === '/categories/electrical'
		? 'Electrical'
		: pathname === '/categories/flooring'
		? 'Flooring'
		: pathname === '/categories/handyman'
		? 'Handyman'
		: pathname === '/categories/hvac'
		? 'HVAC'
		: pathname === '/categories/design'
		? 'Interior Design'
		: pathname === '/categories/landscaping'
		? 'Landscaping'
		: pathname === '/categories/locksmithing'
		? 'Locksmithing'
		: pathname === '/categories/moving'
		? 'Moving'
		: pathname === '/categories/painting'
		? 'Painting'
		: pathname === '/categories/pest'
		? 'Pest Control'
		: pathname === '/categories/plumbing'
		? 'Plumbing'
		: pathname === '/categories/pool'
		? 'Pool Services'
		: pathname === '/categories/restoration'
		? 'Restoration'
		: pathname === '/categories/roofing'
		? 'Roofing'
		: pathname === '/categories/removal'
		? 'Scrap Removal'
		: pathname === '/categories/smart'
		? 'Smart Home'
		: pathname === '/categories/windoors'
		? 'Window & Door'
		: null

// ----------------------------------------------------------------------

export {
	paths,
	getLogoFromCategory,
	getPathFromCategory,
	getCategoryFromPath,
	getLogoFromPath,
}
