import { MenuOpenTwoTone, MenuTwoTone } from '@mui/icons-material'
import {
	Avatar,
	Grid,
	IconButton,
	Link,
	Skeleton,
	Stack,
	Typography,
	useTheme,
} from '@mui/material'
import { Suspense, lazy } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import useResponsive from '../../../hooks/useResponsive'
import {
	getCategoryFromPath,
	getLogoFromPath,
	paths,
} from '../../../utils/categoryHandler'
import { H1515, H6464, H7575 } from '../../../utils/variants'
import NavbarButtons from '../../inputs/button/NavbarButtons'
import StyledToolbar from './StyledToolbar'

const AlgoliaSearchbar = lazy(() => import('./searchbar/AlgoliaSearchbar'))
const NavbarMobileMenu = lazy(() => import('../menu/NavbarMobileMenu'))

// ----------------------------------------------------------------------

function Navbar(props) {
	const { handleDrawerOpen, handleDrawerClose, open } = props
	const { pathname } = useLocation()
	const theme = useTheme()

	return (
		<StyledToolbar>
			<Grid
				container
				component='nav'
				direction='row'
				justifyContent='space-between'
				alignItems='flex-end'
				sx={{ '& > *': { mx: 2 } }}>
				<Grid
					item
					xs={6}>
					<Stack
						direction='row'
						justifyContent='flex-start'
						alignItems='flex-end'>
						<IconButton
							disableRipple
							edge='start'
							aria-label='open-left-drawer'
							onClick={open ? handleDrawerClose : handleDrawerOpen}
							sx={{
								'ml': 2,
								'mb': 1,
								'color': (theme) => theme.palette.primary.main,
								'background': (theme) => theme.palette.secondary.dark,
								'&:hover': {
									background: (theme) => theme.palette.secondary.main,
								},
							}}>
							{open ? <MenuOpenTwoTone /> : <MenuTwoTone />}
						</IconButton>
						<Link
							aria-label='return-to-homepage'
							component={RouterLink}
							to={pathname === '/home' || pathname === '/' ? '/' : '/home'}>
							<Stack
								ml={4}
								direction='row'
								alignItems='center'>
								<img
									alt='offical HelpListed logo'
									style={{
										height: 128,
										width: 128,
									}}
									src={`${process.env.REACT_APP_IMAGES_DOMAIN}/88725df2-2672-4de1-fdfa-9d254083ae00/${H1515}`}
								/>
								<img
									alt='HelpListed spelled out in yellow lettering'
									style={{
										objectFit: 'contain',
									}}
									src={`${process.env.REACT_APP_IMAGES_DOMAIN}/b369cbcf-84b5-4060-baac-963599c48d00/${H1515}`}
								/>
							</Stack>
						</Link>
					</Stack>
				</Grid>
				<Grid
					item
					xs={6}>
					<Stack direction='column'>
						{paths.includes(pathname) && (
							<Stack
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								sx={{ '& > *': { mr: 2, mb: 1 } }}>
								<Typography
									component='h1'
									variant='overline'
									sx={{
										color: theme.palette.primary.main,
										fontSize: '22px',
										mb: 1,
										mr: 1,
									}}>
									{getCategoryFromPath(pathname)}
								</Typography>
								<Avatar
									src={getLogoFromPath(pathname, H6464)}
									sx={{
										height: 64,
										width: 64,
									}}
									variant='rounded'
								/>
							</Stack>
						)}
					</Stack>
					<Stack
						direction='row'
						justifyContent='flex-end'
						alignItems='flex-end'
						sx={{ '& > *': { mr: 2, mb: 1 } }}>
						<Suspense
							fallback={
								<Skeleton
									animation='wave'
									variant='rectangular'>
									<StyledToolbar />
								</Skeleton>
							}>
							<AlgoliaSearchbar />
						</Suspense>
						<Suspense
							fallback={
								<Skeleton
									animation='wave'
									variant='circular'>
									<IconButton />
								</Skeleton>
							}>
							<NavbarButtons />
						</Suspense>
					</Stack>
				</Grid>
			</Grid>
		</StyledToolbar>
	)
}

// ----------------------------------------------------------------------

function NavbarLite(props) {
	const { handleDrawerOpen, handleDrawerClose, open } = props
	const smUp = useResponsive('up', 'sm')
	const { pathname } = useLocation()
	const theme = useTheme()

	const size = smUp ? H6464 : H7575

	return (
		<StyledToolbar>
			<Grid
				container
				component='nav'
				direction='row'
				justifyContent='center'
				alignItems='flex-end'
				sx={{ '& > *': { mx: 2 } }}>
				<Grid
					item
					xs={4}>
					<IconButton
						aria-label='open-left-drawer'
						edge='start'
						size={smUp ? 'medium' : 'small'}
						onClick={open ? handleDrawerClose : handleDrawerOpen}
						sx={{
							'ml': smUp ? 2 : 1,
							'mb': 1,
							'color': theme.palette.primary.main,
							'background': theme.palette.secondary.dark,
							'&:hover': {
								background: theme.palette.secondary.main,
							},
						}}>
						{open ? (
							<MenuOpenTwoTone fontSize='inherit' />
						) : (
							<MenuTwoTone fontSize='inherit' />
						)}
					</IconButton>
				</Grid>
				<Grid
					item
					xs={4}>
					<Stack
						direction='column'
						justifyContent='flex-end'
						alignItems='center'>
						<Link
							aria-label='return-to-homepage'
							component={RouterLink}
							to={pathname === '/home' || pathname === '/' ? '/' : '/home'}>
							<img
								alt='offical HelpListed logo'
								src={
									paths.includes(pathname)
										? getLogoFromPath(pathname, size)
										: `${process.env.REACT_APP_IMAGES_DOMAIN}/88725df2-2672-4de1-fdfa-9d254083ae00/${size}`
								}
								style={{
									height: smUp ? 64 : 75,
									width: smUp ? 64 : 75,
									marginLeft: 'auto',
									marginRight: 'auto',
								}}
							/>
						</Link>
						<Typography
							noWrap
							component='h1'
							variant={smUp ? 'overline' : 'body2'}
							sx={{
								color: theme.palette.primary.main,
								fontSize: smUp ? '20px' : '14px',
								fontWeight: 600,
								textTransform: 'uppercase',
							}}>
							{paths.includes(pathname)
								? getCategoryFromPath(pathname)
								: 'Helplisted'}
						</Typography>
					</Stack>
				</Grid>
				<Grid
					item
					xs={4}>
					<Stack
						direction='row'
						justifyContent='flex-end'
						alignItems='flex-end'
						sx={{ '& > *': { mr: smUp ? 2 : 1, mb: 1 } }}>
						<Suspense
							fallback={
								<Skeleton
									animation='wave'
									variant='rectangular'>
									<StyledToolbar />
								</Skeleton>
							}>
							<AlgoliaSearchbar />
						</Suspense>
						<Suspense
							fallback={
								<Skeleton
									animation='wave'
									variant='rectangular'>
									<IconButton />
								</Skeleton>
							}>
							<NavbarMobileMenu />
						</Suspense>
					</Stack>
				</Grid>
			</Grid>
		</StyledToolbar>
	)
}

export { Navbar, NavbarLite }
