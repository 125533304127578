import { Typography } from '@mui/material'
import { HelperTelInput } from '../../../inputs/textfield/HelperTelInput'

/**
 * @param {{ formik: { handleBlur: import("react").FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>; handleChange: import("react").ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>; values: { tel: unknown; }; touched: { tel: any; }; errors: { tel: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; }; }; mdUp: boolean; sending: boolean; smUp: boolean; }} props
 */
export function HelperTelForm(props) {
	const { formik, mdUp, sending, smUp } = props

	return (
		<>
			<Typography
				gutterBottom
				align='left'
				color='primary.main'
				component='h1'
				variant='h3'>
				Let&apos;s connect you to the world
			</Typography>
			<Typography
				align='left'
				color='text.secondary'
				component='h2'
				textTransform='none'
				variant='h4'
				my={3}>
				How should your customers contact you?
			</Typography>
			<HelperTelInput
				formik={formik}
				mdUp={mdUp}
				sending={sending}
				smUp={smUp}
			/>
		</>
	)
}
