// ----------------------------------------------------------------------

/**
 * @param {number} value
 */
function pxToRem(value) {
	return `${value / 16}rem`
}

export { pxToRem }

const FONT_HEADING = '"Libre Franklin", sans-serif'

const typography = {
	fontFamily: '"Open Sans", "Fira Sans", "Lucida Sans", sans-serif',
	h1: {
		fontFamily: FONT_HEADING,
		fontWeight: 900,
		textTransform: 'uppercase',
	},
	h2: {
		fontFamily: FONT_HEADING,
		fontWeight: 900,
		textTransform: 'uppercase',
	},
	h3: {
		fontWeight: 700,
		textTransform: 'uppercase',
	},
	h4: {
		fontWeight: 600,
	},
	h5: {
		fontWeight: 600,
	},
	button: {
		fontWeight: 700,
		letterSpacing: 1.25,
	},
	overline: {
		fontFamily: FONT_HEADING,
		fontWeight: 900,
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: 1.5,
		textTransform: 'uppercase',
	},
}

export default typography
