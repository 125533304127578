import { Container, Divider, Stack, Typography } from '@mui/material'
import { PageWrapper } from '../../../components/layout/box/PageWrapper'
import GlobalBreadcrumbs from '../../../components/navigation/breadcrumbs/GlobalBreadcrumbs'
import useResponsive from '../../../hooks/useResponsive'

// ----------------------------------------------------------------------

/**
 * @param {{ id: string; breadcrumbs: string | number | boolean | import("react").ReactPortal | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode>; children: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; description: string; heading: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; imageAlt: string; imagePath: string; title: string; }} props
 */
export default function AuthHeader(props) {
	const {
		id,
		breadcrumbs,
		children,
		description,
		heading,
		imageAlt,
		imagePath,
		title,
	} = props

	const smUp = useResponsive('up', 'sm')
	const mdUp = useResponsive('up', 'md')

	return (
		<PageWrapper
			id={id}
			description={description}
			imageAlt={imageAlt}
			imagePath={imagePath}
			title={title}>
			<Container
				disableGutters
				maxWidth='lg'>
				<Stack mb={5}>
					<Stack
						spacing={1}
						role='presentation'
						sx={{ mt: mdUp ? 2 : 0.5, ml: mdUp ? 3.5 : smUp ? 2.5 : 1.5 }}>
						<Typography
							align='left'
							component='h1'
							variant='h2'
							color='primary.main'>
							{heading}
						</Typography>
						<Divider
							aria-hidden='true'
							variant='middle'
						/>
						<GlobalBreadcrumbs breadcrumbs={breadcrumbs} />
					</Stack>
					{children}
				</Stack>
			</Container>
		</PageWrapper>
	)
}
