import { OpenInNewTwoTone } from '@mui/icons-material'
import {
	Box,
	CardHeader,
	CardMedia,
	Divider,
	Link,
	Stack,
	Typography,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { getPathFromCategory } from '../../../utils/categoryHandler'
import { calendar, clock } from '../../../utils/dateBlocks'
import CategoryAvatar from '../../display/avatar/CategoryAvatar'

// ====================== ListingButton ====================== //
function ListingButton() {
	return (
		<Stack
			alignItems='center'
			direction='row'
			justifyContent='center'
			pt={3}
			spacing={1}>
			<Typography
				component='span'
				variant='button'
				sx={{ textDecoration: 'underline' }}>
				Details
			</Typography>
			<OpenInNewTwoTone fontSize='small' />
		</Stack>
	)
}
// ====================== ListingContent ====================== //
/**
 * @param {{ content: string; smDown: boolean; theme: { palette: { text: { secondary: any; }; }; }; }} props
 */
function ListingContent(props) {
	const { content, theme } = props
	return (
		<Typography
			gutterBottom
			paragraph
			variant={'body2'}
			sx={{
				color: theme.palette.text.secondary,
				display: '-webkit-box',
				margin: '0 auto',
				maxWidth: '100%',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: 3,
			}}>
			{content}
		</Typography>
	)
}
// ====================== ListingDivider ====================== //
/**
 * @param {{ theme: { palette: { primary: { main: any; }; }; } }} props
 */
function ListingDivider(props) {
	const { theme } = props
	return (
		<Divider
			aria-hidden='true'
			sx={{
				borderRadius: 0.35,
				borderTop: `3px solid ${theme.palette.primary.main}`,
				mb: 1.5,
				ml: 0.05,
				mr: '75%',
				mt: 2,
			}}
		/>
	)
}
// ====================== ListingHeader ====================== //
/**
 * @param {{ [alt]: string; business: string; category: string; location: string; }} props
 */
function ListingHeader(props) {
	const { alt, business, category, location } = props
	return (
		<CardHeader
			avatar={
				<CategoryAvatar
					alt={alt}
					category={category}
				/>
			}
			title={business}
			titleTypographyProps={{
				component: 'h2',
				variant: 'subtitle1',
				sx: {
					display: '-webkit-box',
					fontWeight: 600,
					lineHeight: 1.5,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: 2,
				},
			}}
			subheader={location}
		/>
	)
}
// ====================== ListingImage ====================== //
/**
 * @param {{ [alt]: string; logo: string; }} props
 */
function ListingImage(props) {
	const { alt, logo } = props
	return (
		<CardMedia
			alt={alt}
			component='img'
			src={logo}
			sx={{
				'height': 150,
				'width': '100%',
				'&:hover': {
					transition: 'transform .3s',
					transform: 'scale(1.3) rotate(3deg)',
				},
			}}
		/>
	)
}
// ====================== ListingLink ====================== //
/**
 * @param {{ category: string; [children]: import("react").ReactNode; [createdAt]: string | number | Date; [id]: string; smDown: boolean; }} props
 */
function ListingLink(props) {
	const { category, children, createdAt, id, mdDown } = props

	return (
		<Box
			component='div'
			maxWidth={mdDown ? '100vw' : '620px'}
			my={4}>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='flex-end'
				py={0.25}
				px={3.75}>
				<Link
					color='inherit'
					component={RouterLink}
					to={category ? getPathFromCategory(category) : null}
					fontWeight={600}
					textTransform='uppercase'
					underline={'hover'}
					variant='body2'>
					{category}
				</Link>
				<Box>
					<Typography
						fontSize={12}
						fontWeight={500}
						variant='body2'>
						<Box
							component={calendar}
							date={createdAt}
						/>{' '}
						-{' '}
						<Box
							component={clock}
							time={createdAt}
						/>
					</Typography>
				</Box>
			</Stack>
			<Link
				color='inherit'
				component={RouterLink}
				to={`/listings/${id}/helper`}
				sx={{ textDecoration: 'none' }}>
				{children}
			</Link>
		</Box>
	)
}
// ====================== ListingTitle ====================== //
/**
 * @param {{ theme: { palette: { text: { primary: any; }; }; }; title: string | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>>  }} props
 */
function ListingTitle(props) {
	const { title } = props
	return (
		<Typography
			gutterBottom
			component='h3'
			variant='body1'
			sx={{
				display: '-webkit-box',
				letterSpacing: 0.2,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				textTransform: 'uppercase',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: 1,
			}}>
			{title}
		</Typography>
	)
}

export {
	ListingButton,
	ListingContent,
	ListingDivider,
	ListingHeader,
	ListingImage,
	ListingLink,
	ListingTitle,
}
