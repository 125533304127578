import { EditTwoTone, SaveTwoTone } from '@mui/icons-material'
import {
	Box,
	Button,
	Card,
	CardContent,
	Divider,
	InputLabel,
	Typography,
} from '@mui/material'
import { useState } from 'react'
import { useNavigation } from 'react-router-dom'
import useResponsive from '../../../hooks/useResponsive'
import Marquee from '../../display/typography/Marquee'
import { ListExcerptSkeleton } from '../../feedback/skeleton/Skeletons'
import { ListingExcerpt } from './ListingExcerpt'

// ----------------------------------------------------------------------

export default function HelperListings(props) {
	const { listings } = props
	console.log(listings)

	const [open, setOpen] = useState(false)
	const smUp = useResponsive('up', 'sm')

	const navigation = useNavigation()
	const loading = navigation.state === 'loading'

	const handleOpen = () => {
		setOpen(!open)
	}

	return (
		<Card>
			<Box
				component='section'
				p={smUp ? 4 : 2}
				display='flex'
				alignItems='center'
				justifyContent='space-between'>
				<InputLabel
					htmlFor={
						open ? 'hlp-manage.current-listings' : 'hlp-view.current-listings'
					}>
					<Typography
						gutterBottom
						align='left'
						component='h2'
						fontWeight={700}
						variant='h4'>
						{open ? 'Edit: My Posts' : 'My Posts'}
					</Typography>
				</InputLabel>
				<Button
					onClick={handleOpen}
					startIcon={open ? <SaveTwoTone /> : <EditTwoTone />}
					type='button'
					variant='contained'
					sx={{
						'background': (theme) => theme.palette.secondary.dark,
						'color': (theme) => theme.palette.primary.main,
						'maxWidth': '360px',
						'&:hover': {
							background: (theme) => theme.palette.secondary.main,
						},
					}}>
					{open ? 'Save' : 'Edit'}
				</Button>
			</Box>
			<Divider aria-hidden='true' />
			<CardContent sx={{ p: 4 }}>
				<Box component='section'>
					{loading ? (
						<ListExcerptSkeleton />
					) : listings ? (
						listings.map(
							(
								/** @type {{ id: string; category: string; content: string; createdAt: string; helper: { alt: string; business: string; logo: string; }; location: string; title: string; }} */ listing,
							) => {
								const { id, category, content, createdAt, helper, location, title } =
									listing
								return (
									<ListingExcerpt
										id={id}
										alt={helper.alt}
										business={helper.business}
										category={category}
										content={content}
										createdAt={createdAt}
										logo={helper.logo}
										key={id}
										location={location}
										title={title}
									/>
								)
							},
						)
					) : (
						<Marquee message='No posts yet!' />
					)}
				</Box>
			</CardContent>
		</Card>
	)
}
