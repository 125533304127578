import { Button, Container, Stack, Typography } from '@mui/material'
import { Link as RouterLink, useRouteError } from 'react-router-dom'

export default function ErrorPage() {
	const error = useRouteError()
	console.error(error)

	return (
		<div id='hlp-default-error'>
			<Container maxWidth='md'>
				<Stack
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					spacing={4}
					mt={5}>
					<Typography
						gutterBottom
						align='center'
						component='h1'
						variant='h3'>
						Oops! Sorry, an unexpected error has occurred.
					</Typography>
					{error && (
						<Typography
							gutterBottom
							color='text.secondary'
							component='h2'
							mb={5}
							variant='subtitle1'>
							<dfn>Error Details:</dfn>
							<i>
								{
									// @ts-ignore
									error.statusText || error.message
								}
							</i>
						</Typography>
					)}
					<Button
						component={RouterLink}
						size='large'
						to='/'
						variant='contained'>
						Go Home
					</Button>
				</Stack>
			</Container>
		</div>
	)
}
