const H1515 = 'hl150x150'
const H3025 = 'hl300x250'
const H3828 = 'hl384x288'
const H5015 = 'hl50x150'
const H6464 = 'hl64x64'
const H6644 = 'hl660x440'
const H7575 = 'hl75x75'
const HF9478 = 'hlfb940x788'
const O1263 = 'og1200x630'

export { H1515, H3025, H3828, H5015, H6464, H6644, H7575, HF9478, O1263 }
