import { Typography } from '@mui/material'
import { HelperBizInput } from '../../../inputs/textfield/HelperBizInput'

/**
 * @param {{ formik: { handleBlur: import("react").FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>; handleChange: import("react").ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>; values: { business: unknown; }; touched: { business: any; }; errors: { business: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; }; }; mdUp: boolean; sending: boolean; smUp: boolean; }} props
 */
export function HelperBizForm(props) {
	const { formik, mdUp, sending, smUp } = props

	return (
		<>
			<Typography
				gutterBottom
				align='left'
				color='primary.main'
				component='h1'
				variant='h3'>
				Welcome, Happy Helper!
			</Typography>
			<Typography
				align='left'
				color='text.secondary'
				component='h2'
				textTransform='none'
				variant='h4'
				my={3}>
				What's the name of your business?
			</Typography>
			<HelperBizInput
				formik={formik}
				mdUp={mdUp}
				sending={sending}
				smUp={smUp}
			/>
		</>
	)
}
