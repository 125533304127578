import { SaveTwoTone } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	Unstable_Grid2 as Grid,
} from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useResponsive from '../../../../hooks/useResponsive'
import HelperService from '../../../../services/helperService'
import { TELREGEX } from '../../../../utils/regecies'
import { helperSchema } from '../../../../utils/validationSchema'
import Snackbars from '../../../feedback/snackbar/Snackbars'
import HelperCategoryAutocomplete from '../../../inputs/autocomplete/HelperCategoryAutocomplete'
import HelperLocationAutocomplete from '../../../inputs/autocomplete/HelperLocationAutocomplete'
import { HelperBizInput } from '../../../inputs/textfield/HelperBizInput'
import { HelperTelInput } from '../../../inputs/textfield/HelperTelInput'

// ----------------------------------------------------------------------

export default function AccountEdit(props) {
	const { business, category, id, location, tel } = props

	const [message, setMessage] = useState(null)
	const [hasError, setHasError] = useState(null)

	const smUp = useResponsive('up', 'sm')
	const mdUp = useResponsive('up', 'md')
	const navigate = useNavigate()

	const formik = useFormik({
		initialValues: {
			business,
			category,
			location,
			tel,
		},
		validationSchema: helperSchema,
		onSubmit: async (values, { setSubmitting }) => {
			var formattedPhoneNumber = values.tel.replace(TELREGEX, '$1$2$3')
			var data = {
				business: values.business,
				category: values.category,
				location: values.location,
				tel: formattedPhoneNumber,
			}
			setSubmitting(true)
			try {
				await HelperService.updateOne(id, data)
				setHasError(false)
				setTimeout(() => {
					setHasError(null)
				}, 1500)
				navigate('/auth/account')
			} catch (error) {
				error.response
					? setMessage(error.response.data.message)
					: error.request
					? setMessage(error.request)
					: setMessage(error.config)
				setHasError(true)
				setTimeout(() => {
					setHasError(null)
					setMessage(null)
				}, 5000)
			}
		},
	})

	const handleClose = (/** @type {any} */ _event, /** @type {string} */ reason) => {
		if (reason === 'clickaway') {
			return
		}
		setHasError(null)
		setMessage(null)
	}

	return (
		<Card>
			<CardHeader
				subheader='The information can be edited'
				title='My Business'
			/>
			<form
				id='hlp-account-edit'
				noValidate
				onSubmit={formik.handleSubmit}>
				<CardContent sx={{ p: smUp ? 4 : 2 }}>
					<Box sx={{ m: -1.5 }}>
						<Grid
							container
							spacing={2}>
							<Grid xs={12}>
								<HelperBizInput
									// @ts-ignore
									formik={formik}
									mdUp={mdUp}
									smUp={smUp}
								/>
							</Grid>
							<Grid xs={12}>
								<HelperCategoryAutocomplete
									// @ts-ignore
									formik={formik}
									mdUp={mdUp}
									smUp={smUp}
								/>
							</Grid>
							<Grid xs={12}>
								<HelperLocationAutocomplete
									// @ts-ignore
									formik={formik}
									mdUp={mdUp}
									smUp={smUp}
								/>
							</Grid>
							<Grid xs={12}>
								<HelperTelInput
									// @ts-ignore
									formik={formik}
									mdUp={mdUp}
									smUp={smUp}
								/>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
				<Divider
					aria-hidden='true'
					sx={{ width: '95%' }}
				/>
				<CardActions sx={{ justifyContent: 'center' }}>
					<LoadingButton
						id='hlp-edit-profile'
						loading={formik.isSubmitting}
						size='large'
						startIcon={<SaveTwoTone />}
						type='submit'
						variant='contained'>
						Save
					</LoadingButton>
				</CardActions>
				<Snackbars
					errorMessage={message}
					errorOpen={hasError === true}
					handleErrorClose={handleClose}
					handleSuccessClose={handleClose}
					successMessage={message}
					successOpen={hasError === false}
				/>
			</form>
		</Card>
	)
}
