import { Box, Stack } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import ListingService from '../../../services/listingService'
import { ListExcerptSkeleton } from '../../feedback/skeleton/Skeletons'
import { ListingExcerpt } from './ListingExcerpt'
import PageSizeSelect from '../../sections/PageSizeSelect'

// ----------------------------------------------------------------------

/**
 * @param {{ category: string; location: string; }} props
 */
export default function Listings(props) {
	const { category, location } = props
	const [listings, setListings] = useState([])
	const [loading, setLoading] = useState(true)

	const [page, setPage] = useState(1)
	const [count, setCount] = useState(0)
	const [pageSize, setPageSize] = useState(20)

	const pageSizes = [20, 50, 100]

	const getRequestParams = (
		/** @type {string} */ category,
		/** @type {string} */ location,
		/** @type {number} */ page,
		/** @type {number} */ pageSize,
	) => {
		let params = {}

		params.category = category ? category : ''
		params.location = location ? location : ''
		if (page) params.page = page - 1
		if (pageSize) params.pageSize = pageSize
		return params
	}

	const getListings = useCallback(async () => {
		const params = getRequestParams(category, location, page, pageSize)
		setLoading(true)
		try {
			const response =
				!location && !category
					? await ListingService.findAllPublished(params)
					: await ListingService.findAllMatching(params)
			const { listings, totalPages } = response.data
			setListings(listings)
			setCount(totalPages)
		} finally {
			setLoading(false)
		}
	}, [category, location, page, pageSize])

	useEffect(() => {
		getListings()
	}, [category, getListings, location, page, pageSize])

	return (
		<Box component='section'>
			{loading ? (
				<ListExcerptSkeleton />
			) : (
				listings?.map(
					({ id, category, content, createdAt, helper, location, title }) => (
						<ListingExcerpt
							id={id}
							alt={helper.alt}
							business={helper.business}
							category={category}
							content={content}
							createdAt={createdAt}
							logo={helper.logo}
							key={id}
							location={location}
							title={title}
						/>
					),
				)
			)}
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				mb={3}
				spacing={1}>
				<PageSizeSelect
					count={count}
					page={page}
					pageSize={pageSize}
					pageSizes={pageSizes}
					setPage={setPage}
					setPageSize={setPageSize}
				/>
			</Stack>
		</Box>
	)
}
