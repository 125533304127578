import { Autocomplete, Button, TextField } from '@mui/material'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { LOCATION_ENUM } from '../../../utils/enums'

// ----------------------------------------------------------------------

/**
 * @param {{ mdUp: boolean; }} props
 */
export default function SingleLocationAutocomplete(props) {
	const { mdUp } = props
	const locVal = Cookies.get('_hlpLoc')
	const [locationValue, setLocationValue] = useState(locVal || 'Miami, FL')

	const handleLocation = () => {
		Cookies.set('_hlpLoc', locationValue, {
			expires: 183,
			secure: true,
			sameSite: 'strict',
		})
	}

	return (
		<>
			<Autocomplete
				autoComplete
				autoHighlight
				disableClearable
				fullWidth
				getOptionLabel={(location) => location}
				onChange={(_e, location) => setLocationValue(location)}
				options={LOCATION_ENUM}
				value={locationValue}
				renderInput={(params) => (
					<TextField
						{...params}
						color='secondary'
						name='location'
						variant='outlined'
					/>
				)}
			/>
			<Button
				fullWidth
				component={RouterLink}
				onClick={() => {
					handleLocation()
				}}
				to='/home'
				variant='contained'
				{...(!mdUp && { size: 'large' })}>
				Find Help Now
			</Button>
		</>
	)
}
