import { LoadingButton } from '@mui/lab'
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Divider,
	LinearProgress,
	Stack,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import placeholderDefaultLogo from '../../../../images/webp/plc-default-logo.webp'
import FileUploadService from '../../../../services/fileUploadService'
import { ErrorSnackbar } from '../../../feedback/snackbar/Snackbars'
import CancelIconButton from '../../../inputs/button/CancelIconButton'

// ----------------------------------------------------------------------

const fileTypeError = 'Logo must be a .gif, .jpg, .png, .svg, or .webp'
const serverError =
	'Sorry, something went wrong. Please refresh or try again in a little while!'

// ----------------------------------------------------------------------

/**
 * @param {{ alt: string; id: string; logo: string; }} props
 */
export default function LogoEdit(props) {
	const { alt, id, logo } = props

	const [editing, setEditing] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [hasError, setHasError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [selection, setSelection] = useState(null)
	const [uploadUrl, setUploadUrl] = useState(null)
	const [preview, setPreview] = useState(null)
	const [progress, setProgress] = useState(0)

	const upload = async () => {
		setProgress(0)
		setLoading(true)

		try {
			const response = await FileUploadService.uploadLogo(
				id,
				uploadUrl,
				selection,
				(/** @type {{ loaded: number; total: number; }} */ e) => {
					setProgress(Math.round((100 * e.loaded) / e.total))
				},
			)
			console.log(response)
		} catch (error) {
			error.response
				? error.response.status === 415
					? setErrorMessage(fileTypeError)
					: setErrorMessage(serverError)
				: error.request
				? setErrorMessage(error.request)
				: setErrorMessage(error.config)
			setHasError(true)
			setTimeout(() => {
				setHasError(null)
				setErrorMessage(null)
				setSelection(null)
			}, 5000)
		} finally {
			setEditing(false)
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!selection && logo) {
			setPreview(logo)
			return
		} else if (!selection && !logo) {
			setPreview(
				'https://helplisted.com/cdn-cgi/imagedelivery/V1nQb2Wy2XhC693mJyMETg/1b5eafc7-9251-473d-09e5-311ecedb5800/hl150x150',
			)
			return
		}
		const objectUrl = URL.createObjectURL(selection)
		setPreview(objectUrl)

		return () => URL.revokeObjectURL(objectUrl) // eslint-disable-line consistent-return
	}, [logo, selection])

	const onSelectFile = async (/** @type {{ target: { files: any[]; }; }} */ e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelection(null)
			return
		}

		setEditing(true)
		setSelection(e.target.files[0])
		const response = await FileUploadService.getUploadURL()
		setUploadUrl(response.data.uploadURL)
	}

	const handleCancel = () => {
		setPreview(null)
		setSelection(null)
		setUploadUrl(null)
		setEditing(false)
	}

	const handleClose = (/** @type {any} */ _event, /** @type {string} */ reason) => {
		if (reason === 'clickaway') {
			return
		}
		setHasError(null)
		setErrorMessage(null)
	}

	return (
		<>
			<Card variant='outlined'>
				<Box sx={{ position: 'relative' }}>
					<CancelIconButton
						handleClick={handleCancel}
						visible={editing}
					/>
				</Box>
				<CardContent>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}>
						<Stack
							mx={'auto'}
							my={2}
							maxWidth={150}
							maxHeight={135}>
							<LazyLoadImage
								alt={preview ? '' : alt}
								effect='blur'
								placeholderSrc={placeholderDefaultLogo}
								src={
									preview
										? preview
										: logo
										? logo
										: 'https://helplisted.com/cdn-cgi/imagedelivery/V1nQb2Wy2XhC693mJyMETg/1b5eafc7-9251-473d-09e5-311ecedb5800/hl150x150'
								}
								style={{
									objectFit: 'contain',
									overflow: 'hidden',
								}}
							/>
						</Stack>
					</Box>
				</CardContent>

				{loading ? (
					<Box
						aria-valuemax={100}
						aria-valuemin={0}
						aria-valuenow={progress}
						role='progressbar'>
						<LinearProgress
							value={progress}
							variant='determinate'
						/>
					</Box>
				) : (
					<Divider aria-hidden='true' />
				)}
				<CardActions>
					<LoadingButton
						id={editing ? 'hlp-logo-save' : 'hlp-logo-edit'}
						fullWidth
						color={editing ? 'primary' : 'secondary'}
						component={editing ? 'button' : 'label'}
						htmlFor={editing ? null : 'logo'}
						disabled={editing ? !uploadUrl : loading}
						loading={loading}
						onClick={editing ? upload : null}
						type={editing ? 'submit' : null}
						variant={editing ? 'contained' : 'text'}>
						{editing ? 'Save Changes' : 'Edit Logo'}
						<input
							hidden
							accept='image/gif, image/jpeg, image/png, image/svg+xml, image/webp'
							id='logo'
							name='logo'
							// @ts-ignore
							onChange={onSelectFile}
							type='file'
						/>
					</LoadingButton>
				</CardActions>
			</Card>
			{hasError ? (
				<ErrorSnackbar
					errorMessage={errorMessage}
					errorOpen={hasError}
					handleErrorClose={handleClose}
				/>
			) : null}
		</>
	)
}
