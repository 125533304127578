// ----------------------------------------------------------------------

const CATEGORY_ENUM = [
	'Cleaning',
	'Electrical',
	'Flooring',
	'Handyman',
	'HVAC',
	'Interior Design',
	'Landscaping',
	'Locksmithing',
	'Moving',
	'Painting',
	'Pest Control',
	'Plumbing',
	'Pool Services',
	'Restoration',
	'Roofing',
	'Scrap Removal',
	'Smart Home',
	'Windows & Doors',
	'',
]

// ----------------------------------------------------------------------

const LOCATION_ENUM = [
	'Auburn, AL',
	'Birmingham, AL',
	'Dothan, AL',
	'Huntsville, AL',
	'Mobile, AL',
	'Montgomery, AL',
	'Tuscaloosa, AL',
	'Anchorage, AK',
	'Fairbanks, AK',
	'Juneau, AK',
	'Kenai, AK',
	'Flagstaff, AZ',
	'Kingman, AZ',
	'Phoenix, AZ',
	'Prescott, AZ',
	'Show Low, AZ',
	'Sierra Vista, AZ',
	'Tucson, AZ',
	'Yuma, AZ',
	'Fayetteville, AR',
	'Fort Smith, AR',
	'Jonesboro, AR',
	'Mountain Home, AR',
	'Little Rock, AR',
	'Bakersfield, CA',
	'Fresno, CA',
	'Long Beach, CA',
	'Los Angeles, CA',
	'Monterrey, CA',
	'North Bay, CA',
	'Oakland, CA',
	'Orange County, CA',
	'Sacramento, CA',
	'San Diego, CA',
	'San Jose, CA',
	'Santa Barbara, CA',
	'Santa Cruz, CA',
	'Ventura, CA',
	'Boulder, CO',
	'Colorado Springs, CO',
	'Denver, CO',
	'Durango, CO',
	'Fort Collins, CO',
	'Grand Junction, CO',
	'Hartford, CT',
	'New Haven, CT',
	'New London, CT',
	'Waterbury, CT',
	'Dover, DE',
	'Rehoboth Beach, DE',
	'Wilmington, DE',
	'Washington, DC',
	'Daytona, FL',
	'Fort Lauderdale, FL',
	'Fort Myers, FL',
	'Gainesville, FL',
	'Jacksonville, FL',
	'Keys, FL',
	'Miami, FL',
	'Okaloosa, FL',
	'Orlando, FL',
	'Panama City, FL',
	'Pensacola, FL',
	'Space Coast, FL',
	'Sarasota, FL',
	'St Augustine, FL',
	'Tallahassee, FL',
	'Tampa, FL',
	'West Palm Beach, FL',
	'Albany, GA',
	'Athens, GA',
	'Atlanta, GA',
	'Augusta, GA',
	'Columbus, GA',
	'Macon, GA',
	'Savannah, GA',
	'Valdosta, GA',
	'Honolulu, HI',
	"Kaua'i, HI",
	'Maui, HI',
	"O'ahu, HI",
	'Boise, ID',
	'Idaho Falls',
	'Lewiston, ID',
	'Twin Falls, ID',
	'Bloomington, IL',
	'Carbondale, IL',
	'Champaign, IL',
	'Chicago, IL',
	'Peoria, IL',
	'Rockford, IL',
	'Springfield, IL',
	'Evansville, IN',
	'Fort Wayne, IN',
	'Indianapolis, IN',
	'Lafayette, IN',
	'Richmond, IN',
	'South Bend, IN',
	'Terre Haute, IN',
	'Cedar Rapids, IA',
	'Des Moines, IA',
	'Fort Dodge, IA',
	'Iowa City, IA',
	'Mason City, IA',
	'Sioux City, IA',
	'Quad Cities, KS',
	'Lawrence, KS',
	'Manhattan, KS',
	'Salina, KS',
	'Topeka, KS',
	'Wichita, KS',
	'Bowling Green, KY',
	'Lexington, KY',
	'Louisville, KY',
	'Owensboro, KY',
	'Baton Rouge, LA',
	'Lafayette, LA',
	'Lake Charles, LA',
	'Monroe, LA',
	'New Orleans, LA',
	'Shreveport, LA',
	'Bangor, ME',
	'Lewiston-Auburn, ME',
	'Portland, ME',
	'Annapolis, MD',
	'Baltimore, MD',
	'Cumberland Valley, MD',
	'Frederick, MD',
	'Eastern Shore, MD',
	'Boston, MA',
	'Cape Cod, MA',
	'Springfield, MA',
	'Worcester, MA',
	'Ann Arbor, MI',
	'Battle Creek, MI',
	'Flint, MI',
	'Grand Rapids, MI',
	'Kalamazoo, MI',
	'Lansing, MI',
	'Monroe, MI',
	'Port Huron, MI',
	'Sagin, MI',
	'Brainerd, MN',
	'Duluth, MN',
	'Minneapolis, MN',
	'Rochester, MN',
	'Biloxi, MS',
	'Hattiesburg, MS',
	'Jackson, MS',
	'Meridian, MS',
	'Columbia, MO',
	'Joplin, MO',
	'Kansas City, MO',
	'Lake of the Ozarks, MO',
	'Saint Louis, MO',
	'Springfield, MO',
	'Saint Joseph, MO',
	'Billings, MT',
	'Butte, MT',
	'Great Falls, MT',
	'Helena, MT',
	'Kalispell, MT',
	'Missoula, MT',
	'Grand Island, NE',
	'Lincoln, NE',
	'North Platte, NE',
	'Omaha, NE',
	'Scottsbluff, NE',
	'Elko, NV',
	'Las Vegas, NV',
	'Reno, NV',
	'Virginia City, NV',
	'Concord, NH',
	'Dover, NH',
	'Manchester, NH',
	'Nashua, NH',
	'Central Jersey, NJ',
	'Jersey Shore, NJ',
	'North Jersey, NJ',
	'South Jersey, NJ',
	'Albuquerque, NM',
	'Clovis, NM',
	'Farmington, NM',
	'Las Cruces, NM',
	'Roswell, NM',
	'Santa Fe, NM',
	'Albany, NY',
	'Binghamton, NY',
	'Bronx, NY',
	'Brooklyn, NY',
	'Buffalo, NY',
	'Catskills, NY',
	'Finger Lakes, NY',
	'Ithaca, NY',
	'Long Island, NY',
	'Manhattan, NY',
	'Queens, NY',
	'Rochester, NY',
	'Staten Island, NY',
	'Syracuse, NY',
	'Utica, NY',
	'Westchester, NY',
	'Asheville, NC',
	'Charlotte, NC',
	'Fayetteville, NC',
	'Greensboro, NC',
	'Outer Banks, NC',
	'Raleigh-Durham, NC',
	'Wilmington, NC',
	'Winston-Salem, NC',
	'Bismarck, ND',
	'Fargo, ND',
	'Grand Forks, ND',
	'Minot, ND',
	'Akron, OH',
	'Cincinnati, OH',
	'Cleveland, OH',
	'Columbus, OH',
	'Dayton, OH',
	'Mansfield, OH',
	'Toledo, OH',
	'Youngstown, OH',
	'Lawton, OK',
	'Norman, OK',
	'Oklahoma City, OK',
	'Stillwater, OK',
	'Tulsa, OK',
	'Bend, OR',
	'Corvallis, OR',
	'Eugene, OR',
	'Medford, OR',
	'Portland, OR',
	'Roseburg, OR',
	'Salem, OR',
	'Allentown, PA',
	'Altoona, PA',
	'Erie, PA',
	'Harrisburg, PA',
	'Lancaster, PA',
	'Philadelphia, PA',
	'Pittsburgh, PA',
	'Scranton, PA',
	'State College, PA',
	'Williamsport, PA',
	'York, PA',
	'Providence, RI',
	'Warwick, RI',
	'Charleston, SC',
	'Columbia, SC',
	'Florence, SC',
	'Greenville, SC',
	'Hilton Head, SC',
	'Myrtle Beach, SC',
	'Aberdeen, SD',
	'Pierre, SD',
	'Rapid City, SD',
	'Sioux Falls, SD',
	'Chattanooga, TN',
	'Clarksville, TN',
	'Cookeville, TN',
	'Knoxville, TN',
	'Memphis, TN',
	'Nashville, TN',
	'Tri-Cities, TN',
	'Abilene, TX',
	'Amarillo, TX',
	'Austin, TX',
	'Beaumont, TX',
	'Brownsville, TX',
	'Corpus Christi, TX',
	'Dallas, TX',
	'El Paso, TX',
	'Fort Worth, TX',
	'Galveston, TX',
	'Houston, TX',
	'Laredo, TX',
	'Lubbock, TX',
	'McAllen, TX',
	'Mid-Cities, TX',
	'Odessa, TX',
	'San Antonio, TX',
	'Texarkana, TX',
	'Texoma, TX',
	'Waco, TX',
	'Wichita Falls, TX',
	'Ogden, UT',
	'Provo, UT',
	'Salt Lake City, UT',
	'St. George, UT',
	'Burlington, VT',
	'Colchester, VT',
	'Essex, VT',
	'Charlottesville, VA',
	'Chesapeake, VA',
	'Fredericksburg, VA',
	'Harrisonburg, VA',
	'Lynchburg, VA',
	'Newport News, VA',
	'Norfolk, VA',
	'Portsmouth, VA',
	'Richmond, VA',
	'Roanoke, VA',
	'Suffolk, VA',
	'Virginia Beach, VA',
	'Bellingham, WA',
	'Mount Vernon, WA',
	'Olympia, WA',
	'Seattle, WA',
	'Spokane, WA',
	'Tacoma, WA',
	'Tri-Cities, WA',
	'Charleston, WV',
	'Huntington, WV',
	'Martinsburg, WV',
	'Parkersburg, WV',
	'Wheeling, WV',
	'Appleton, WI',
	'Eau Claire, WI',
	'Green Bay, WI',
	'La Crosse, WI',
	'Madison, WI',
	'Milwaukee, WI',
	'Racine, WI',
	'Sheboygan, WI',
	'Casper, WY',
	'Cheyenne, WY',
	'Laramie, WY',
	'',
]

export { CATEGORY_ENUM, LOCATION_ENUM }
