import {
	Box,
	Button,
	Container,
	Unstable_Grid2 as Grid,
	Stack,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useResponsive from '../../../hooks/useResponsive'
import HelperService from '../../../services/helperService'
import { TELREGEX } from '../../../utils/regecies'
import {
	helperBizSchema,
	helperSortSchema,
	helperTelSchema,
} from '../../../utils/validationSchema'
import { H3828 } from '../../../utils/variants'
import { ErrorSnackbar } from '../../feedback/snackbar/Snackbars'
import ColorLibConnector from './components/ColorLibConnector'
import ColorlibStepIcon from './components/ColorLibStepIcon'
import SuccessStep from './components/SuccessStep'
import { HelperBizForm } from './forms/HelperBizForm'
import { HelperSortForm } from './forms/HelperSortForm'
import { HelperTelForm } from './forms/HelperTelForm'

/**
 * @param {{ id: string; }} props
 */
export default function OnboardStepper(props) {
	const { id } = props
	const [sending, setSending] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [hasError, setHasError] = useState(null)
	const [activeStep, setActiveStep] = useState(0)

	const getSteps = () => ['Business Name', 'Category/Location', 'Contact Info']

	const mdUp = useResponsive('up', 'md')
	const navigate = useNavigate()
	const smUp = useResponsive('up', 'sm')
	const steps = getSteps()

	// ====================================================== //

	const formikHelperBiz = useFormik({
		initialValues: {
			business: '',
		},
		validationSchema: helperBizSchema,
		onSubmit: (values) => handleNext(), // eslint-disable-line no-unused-vars
	})

	const formikHelperSort = useFormik({
		initialValues: {
			category: '',
			location: '',
		},
		validationSchema: helperSortSchema,
		onSubmit: (values) => handleNext(), // eslint-disable-line no-unused-vars
	})

	const formikHelperTel = useFormik({
		initialValues: {
			tel: '',
		},
		validationSchema: helperTelSchema,
		onSubmit: (
			values, // eslint-disable-line no-unused-vars
		) => {
			handleFormSubmit()
		},
	})

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}

	const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

	const handleReset = () => setActiveStep(0)

	const getStepContent = (/** @type {number} */ step) => {
		switch (step) {
			case 0:
				return (
					<HelperBizForm
						// @ts-ignore
						formik={formikHelperBiz}
						mdUp={mdUp}
						sending={sending}
						smUp={smUp}
					/>
				)
			case 1:
				return (
					<HelperSortForm
						// @ts-ignore
						formik={formikHelperSort}
						mdUp={mdUp}
						sending={sending}
						smUp={smUp}
					/>
				)
			case 2:
				return (
					<HelperTelForm
						// @ts-ignore
						formik={formikHelperTel}
						mdUp={mdUp}
						sending={sending}
						smUp={smUp}
					/>
				)
			case 3:
				return <SuccessStep />
			default:
				return 'Unknown step'
		}
	}

	const handleSubmit = () => {
		switch (activeStep) {
			case 0:
				formikHelperBiz.handleSubmit()
				break
			case 1:
				formikHelperSort.handleSubmit()
				break
			case 2:
				formikHelperTel.handleSubmit()
				break
			case 3:
				break
			default:
				break
		}
	}

	const handleFormSubmit = async () => {
		var formattedPhoneNumber = formikHelperTel.values.tel.replace(TELREGEX, '$1$2$3')
		var data = {
			business: formikHelperBiz.values.business,
			category: formikHelperSort.values.category,
			location: formikHelperSort.values.location,
			tel: formattedPhoneNumber,
			isOnboarded: true,
		}
		try {
			setSending(true)
			const response = await HelperService.updateOne(id, data)
			if (response.status === 200) {
				handleNext()
				setTimeout(() => {
					navigate('/auth/dashboard', { replace: true })
				}, 3000)
			}
		} catch (error) {
			const errMessage =
				(error.response && error.response.data && error.response.data.message) ||
				(error.request &&
					error.request.response &&
					error.request.response.message) ||
				error.message ||
				error.toString()

			setHasError(true)
			setErrorMessage(errMessage)
			setTimeout(() => {
				setErrorMessage(null)
				setHasError(null)
			}, 5000)
		} finally {
			setSending(false)
		}
	}

	const handleClose = (/** @type {any} */ _event, /** @type {string} */ reason) => {
		if (reason === 'clickaway') {
			return
		}
		setHasError(null)
		setErrorMessage(null)
	}

	return (
		<Box
			bgcolor='transparent'
			component='section'
			display='flex'
			maxHeight={1300}
			minHeight={mdUp ? 600 : smUp ? 700 : 664}
			position='relative'
			sx={{
				backgroundImage: smUp && [
					'linear-gradient(15deg, rgba(247,182,27,1) 12%, rgba(245,245,245,1) 18%)',
					'-moz-linear-gradient(15deg, rgba(247,182,27,1) 12%, rgba(245,245,245,1) 18%)',
					'-webkit-linear-gradient(15deg, rgba(247,182,27,1) 12%, rgba(245,245,245,1) 18%)',
				],
				overflow: 'hidden',
			}}
			{...(mdUp && { height: activeStep === 1 ? '110vh' : '80vh' })}>
			<Container maxWidth='lg'>
				<Grid
					container
					alignItems='flex-start'
					direction='column'
					justifyContent='center'
					mx={smUp && 'auto'}
					my={4}
					spacing={4}
					width={smUp ? '60%' : '100%'}>
					<Grid xs={12}>
						<Typography
							component='span'
							fontWeight='bold'>
							{activeStep === steps.length
								? 'Step 3 of 3'
								: `Step ${activeStep + 1} of 3`}
						</Typography>
					</Grid>
					<form
						id='hlp-onboard'
						noValidate
						autoComplete='off'>
						<Grid xs={12}>
							<Stepper
								activeStep={activeStep}
								connector={<ColorLibConnector />}>
								{steps.map((label) => (
									<Step key={label}>
										<StepLabel StepIconComponent={ColorlibStepIcon} />
									</Step>
								))}
							</Stepper>
						</Grid>

						{activeStep === steps.length ? (
							<Grid xs={12}>
								<SuccessStep />
							</Grid>
						) : (
							<>
								<Grid xs={12}>{getStepContent(activeStep)}</Grid>
								<Grid xs={mdUp ? 4 : smUp ? 8 : 12}>
									<Stack
										direction='row'
										justifyContent='flex-start'
										alignItems='center'>
										<Button
											id='hlp-onboard-return'
											fullWidth
											onClick={activeStep === 0 ? handleReset : handleBack}
											type='button'
											variant='contained'
											color='secondary'
											disabled={sending}
											sx={{ m: 1, minWidth: smUp ? 120 : 80 }}>
											{activeStep === 0 ? 'Reset' : 'Previous'}
										</Button>
										<Button
											id='hlp-onboard-submit'
											fullWidth
											disabled={sending}
											onClick={handleSubmit}
											variant='contained'
											sx={{ m: 1, minWidth: smUp ? 120 : 80 }}>
											{activeStep === steps.length - 1 ? 'Submit' : 'Next'}
										</Button>
									</Stack>
								</Grid>
							</>
						)}
						{hasError && (
							<ErrorSnackbar
								errorMessage={errorMessage}
								errorOpen={hasError}
								handleErrorClose={handleClose}
							/>
						)}
					</form>
				</Grid>

				{mdUp && (
					<Stack
						width='384px'
						height='384px'
						position='relative'>
						<img
							src={`${process.env.REACT_APP_IMAGES_DOMAIN}/2aacb63f-ac2e-45f3-8ae4-2216e8c5f000/${H3828}`}
							alt='happy helper character with yellow glow in background'
							style={{
								position: 'fixed',
								bottom: -74,
								right: -65,
								border: 'none',
								margin: 'auto',
								width: '384px',
								height: '384px',
								objectFit: 'cover',
								zIndex: '1020',
							}}
						/>
					</Stack>
				)}
			</Container>
		</Box>
	)
}
