export function checkIfFilesAreTooBig(/** @type {{ size: any; }[]} */ files) {
	let valid = true
	if (files) {
		// eslint-disable-line array-callback-return
		files.map((file) => {
			const size = file.size / 1024 / 1024
			if (size > 5) {
				valid = false
			}
		})
	}
	return valid
}

export function checkIfFilesAreCorrectType(/** @type {{ type: any; }[]} */ files) {
	let valid = true
	if (files) {
		// eslint-disable-line array-callback-return
		files.map((file) => {
			if (
				![
					'image/avif',
					'image/jpeg',
					'image/png',
					'image/svg+xml',
					'image/webp',
				].includes(file.type)
			) {
				valid = false
			}
		})
	}
	return valid
}
