import { LoadingButton } from '@mui/lab'
import {
	Box,
	Button,
	Card,
	CardContent,
	Checkbox,
	Divider,
	FormControlLabel,
	Unstable_Grid2 as Grid,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useResponsive from '../../../hooks/useResponsive'
import ListingService from '../../../services/listingService'
import { listingSchema } from '../../../utils/validationSchema'
import { ErrorSnackbar } from '../../feedback/snackbar/Snackbars'
import SlateEditor from '../../sections/SlateEditor'
import { ListingShell } from './ListingShell'

const newNow = new Date()
const today = dayjs(newNow)
const lastDay = today.add(1, 'month')

export default function NewListing(props) {
	const { business, category, credits, location, logo } = props
	const [preview, setPreview] = useState(false)
	const [dateTime, setDateTime] = useState(today)
	const [dateTimeError, setDateTimeError] = useState(null)
	const [errorMessage, setErrorMessage] = useState(null)
	const [hasError, setHasError] = useState(null)

	const handlePreview = () => {
		setPreview((prev) => !prev)
	}

	const handlePublish = () => {
		if (preview) {
			setPreview(false)
		}
	}

	const navigate = useNavigate()
	const smUp = useResponsive('up', 'sm')

	const finalDateTime = dateTime ? dayjs(dateTime) : today

	const formik = useFormik({
		initialValues: {
			title: '',
			content: '',
			autofill: true,
		},
		validationSchema: listingSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			try {
				const response = await ListingService.createOne({
					autofill: values.autofill,
					category,
					content: values.content,
					location,
					logo,
					scheduled: finalDateTime,
					title: values.title,
				})
				if (response.status === 201) {
					setTimeout(() => {
						navigate('/auth/listings/new')
					}, 1500)
				}
			} catch (error) {
				error.response
					? setErrorMessage(error.response.data.message)
					: error.request
					? setErrorMessage(error.request)
					: setErrorMessage(error.config)
				setHasError(true)
				setTimeout(() => {
					setHasError(null)
					setErrorMessage(null)
				}, 5000)
			}
		},
	})

	const handleClose = (/** @type {any} */ _event, /** @type {string} */ reason) => {
		if (reason === 'clickaway') {
			return
		}
		setHasError(null)
		setErrorMessage(null)
	}

	return (
		<>
			<Stack sx={{ minHeight: 350, my: 4, mx: smUp ? 2 : 0 }}>
				<Card>
					<form
						id='hlp-listing'
						noValidate
						onSubmit={formik.handleSubmit}>
						<CardContent sx={{ p: smUp ? 4 : 2 }}>
							<Stack
								component='section'
								display='flex'>
								<Typography
									component='h3'
									variant='h5'
									gutterBottom>
									Using your own words, tell your story.
								</Typography>

								<Typography
									component='span'
									variant='body1'
									color='text.secondary'
									mb={2}>
									Add details like service area, years in business, hours of
									operation, and any licenses or certifications you have.
								</Typography>

								<Grid
									container
									direction={smUp ? 'row' : 'column'}
									justifyContent={smUp ? 'space-evenly' : 'center'}
									alignItems='center'
									rowSpacing={1}
									columnSpacing={2}>
									{preview ? (
										<ListingShell
											business={business}
											category={category}
											content={formik.values.content}
											location={location}
											logo={logo}
											title={formik.values.title}
										/>
									) : (
										<>
											<Grid xs={12}>
												<TextField
													id='hlp-listing-title'
													fullWidth
													required
													aria-describedby='helperText'
													aria-required='true'
													autoComplete='on'
													color='secondary'
													disabled={formik.isSubmitting}
													error={
														formik.touched.title && Boolean(formik.errors.title)
													}
													label='Title'
													margin='normal'
													name='title'
													onBlur={formik.handleBlur}
													onChange={formik.handleChange}
													placeholder='Your Title Goes Here...'
													type='text'
													value={formik.values.title}
													variant='outlined'
													helperText={
														<Typography
															component='span'
															variant='caption'>
															{formik.touched.title && Boolean(formik.errors.title)
																? formik.errors.title
																: 'A short, clear title attracts potential customers'}
														</Typography>
													}
												/>
											</Grid>
											<Grid xs={12}>
												<TextField
													id='hlp-listing-content'
													fullWidth
													multiline
													required
													aria-describedby='helperText'
													aria-required='true'
													autoComplete='on'
													color='secondary'
													disabled={formik.isSubmitting}
													error={
														formik.touched.content && Boolean(formik.errors.content)
													}
													label='Content'
													margin='normal'
													name='content'
													onBlur={formik.handleBlur}
													onChange={formik.handleChange}
													placeholder='Your Content Goes Here...'
													type='textarea'
													value={formik.values.content}
													variant='outlined'
													helperText={
														<Typography
															component='span'
															variant='caption'>
															{formik.touched.content &&
															Boolean(formik.errors.content)
																? formik.errors.content
																: 'This box expands as needed based on your input.'}
														</Typography>
													}
												/>
											</Grid>
										</>
									)}

									<Grid xs={6}>
										<FormControlLabel
											control={
												<Checkbox
													checked={formik.values.autofill}
													inputProps={{ 'aria-label': 'autofill checkbox' }}
													name='autofill'
													onChange={formik.handleChange}
												/>
											}
											label={
												<Typography
													component='span'
													variant='body2'>
													Remember my choices and prefill next time
												</Typography>
											}
										/>
									</Grid>
									<Grid xs={3}>
										<Button
											id='hlp-listing-preview'
											fullWidth
											disabled={formik.isSubmitting}
											color='secondary'
											onClick={handlePreview}
											type='button'
											variant='contained'>
											{preview === false ? 'Preview' : 'Return'}
										</Button>
									</Grid>
									<Grid xs={3}>
										<LoadingButton
											id='hlp-listing-submit'
											fullWidth
											disabled={credits <= 1}
											loading={formik.isSubmitting}
											onClick={handlePublish}
											type='submit'
											variant='contained'>
											Publish
										</LoadingButton>
									</Grid>
								</Grid>
								{hasError ? (
									<ErrorSnackbar
										errorMessage={errorMessage}
										errorOpen={hasError}
										handleErrorClose={handleClose}
									/>
								) : null}
							</Stack>
						</CardContent>
					</form>
				</Card>
			</Stack>
			<Stack sx={{ minHeight: 350, my: 4, mx: smUp ? 2 : 0 }}>
				<Card>
					<Box
						component='section'
						p={smUp ? 4 : 2}
						display='flex'
						alignItems='center'
						justifyContent='space-between'>
						<Typography
							gutterBottom
							align='left'
							component='h2'
							fontWeight={700}
							variant='h4'>
							Coming Soon...
						</Typography>
						<Typography
							gutterBottom
							align='right'
							component='h3'
							variant='h6'
							m={2}>
							Scheduled Posts 👉
						</Typography>
						<DateTimePicker
							label='Date/Time'
							minDate={today}
							maxDate={lastDay}
							onChange={(newDateTime) => setDateTime(newDateTime)}
							onError={(newDateTimeError) => setDateTimeError(newDateTimeError)}
							value={dateTime}
							slotProps={{
								textField: {
									'aria-required': 'true',
									'color': 'secondary',
									'helperText': (
										<Typography
											component='span'
											variant='caption'>
											{dateTimeError
												? dateTimeError
												: 'Enter value to schedule your post. Defaults to immediately'}
										</Typography>
									),
									'margin': 'normal',
								},
							}}
							sx={{ spacing: 1, minWidth: 300 }}
						/>
					</Box>
					<Divider aria-hidden='true' />

					<Grid xs={12}>
						<Typography
							gutterBottom
							align='center'
							component='h3'
							variant='h6'
							m={2}>
							Rich Text Editor 👇
						</Typography>
						<SlateEditor formik={formik} />
					</Grid>
				</Card>
			</Stack>
		</>
	)
}

/**
			<Card>
				<Box
					component='section'
					p={smUp ? 4 : 2}
					display='flex'
					alignItems='center'
					justifyContent='space-between'>
					<Typography
						gutterBottom
						align='left'
						component='h2'
						fontWeight={700}
						variant='h4'>
						Add a Time
					</Typography>
					<DateTimePicker
						disablePast
						label='Date/Time'
						maxDate={lastDay}
						onChange={(newDateTime) => setDateTime(newDateTime)}
						onError={(newDateTimeError) => setDateTimeError(newDateTimeError)}
						slotProps={{
							textField: {
								'aria-required': 'true',
								'color': 'secondary',
								'helperText': (
									<Typography
										component='span'
										variant='caption'>
										{dateTimeError
											? dateTimeError
											: 'Enter value to schedule your post. Defaults to immediately'}
									</Typography>
								),
								'margin': 'normal',
							},
						}}
						sx={{ spacing: 1, minWidth: 300 }}
					/>
				</Box>
				<Divider aria-hidden='true' />
				<form
					id='hlp-listing'
					noValidate
					onSubmit={formik.handleSubmit}>
					<CardContent sx={{ p: smUp ? 4 : 2 }}>
						<Stack
							component='section'
							display='flex'>
							<Typography
								component='h3'
								variant='h5'
								gutterBottom>
								Using your own words, tell your story.
							</Typography>

							<Typography
								component='span'
								variant='body1'
								color='text.secondary'
								mb={2}>
								Add details like service area, years in business, hours of operation,
								and any licenses or certifications you have.
							</Typography>

							<Grid
								container
								direction={smUp ? 'row' : 'column'}
								justifyContent={smUp ? 'space-evenly' : 'center'}
								alignItems='center'
								rowSpacing={1}
								columnSpacing={2}>
								{preview ? (
									<ListingShell
										business={business}
										category={category}
										content={formik.values.content}
										location={location}
										thumbnail={logo}
										title={formik.values.title}
									/>
								) : (
									<>
										<Grid xs={12}>
											<TextField
												id='hlp-listing-title'
												fullWidth
												required
												aria-describedby='helperText'
												aria-required='true'
												autoComplete='on'
												color='secondary'
												disabled={formik.isSubmitting}
												error={formik.touched.title && Boolean(formik.errors.title)}
												label='Title'
												margin='normal'
												name='title'
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												placeholder='Your Title Goes Here...'
												type='text'
												value={formik.values.title}
												variant='outlined'
												helperText={
													<Typography
														component='span'
														variant='caption'>
														{formik.touched.title && Boolean(formik.errors.title)
															? formik.errors.title
															: 'A short, clear title attracts potential customers'}
													</Typography>
												}
											/>
										</Grid>
										<Grid xs={12}>
											<TextField
												id='hlp-listing-content'
												fullWidth
												multiline
												required
												aria-describedby='helperText'
												aria-required='true'
												autoComplete='on'
												color='secondary'
												disabled={formik.isSubmitting}
												error={
													formik.touched.content && Boolean(formik.errors.content)
												}
												label='Content'
												margin='normal'
												name='content'
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												placeholder='Your Content Goes Here...'
												type='textarea'
												value={formik.values.content}
												variant='outlined'
												helperText={
													<Typography
														component='span'
														variant='caption'>
														{formik.touched.content && Boolean(formik.errors.content)
															? formik.errors.content
															: 'This box expands as needed based on your input.'}
													</Typography>
												}
											/>
										</Grid>
									</>
								)}

								<Grid xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.autofill}
												inputProps={{ 'aria-label': 'autofill checkbox' }}
												name='autofill'
												onChange={formik.handleChange}
											/>
										}
										label={
											<Typography
												component='span'
												variant='body2'>
												Remember my choices and prefill next time
											</Typography>
										}
									/>
								</Grid>
								<Grid xs={3}>
									<Button
										id='hlp-listing-preview'
										fullWidth
										disabled={formik.isSubmitting}
										color='secondary'
										onClick={handlePreview}
										type='button'
										variant='contained'>
										{preview === false ? 'Preview Post' : 'Cont'}
									</Button>
								</Grid>
								<Grid xs={3}>
									<LoadingButton
										id='hlp-listing-submit'
										fullWidth
										disabled={credits <= 1}
										loading={formik.isSubmitting}
										size='large'
										type='submit'
										variant='contained'>
										Create Post
									</LoadingButton>
								</Grid>
							</Grid>
							{hasError ? (
								<ErrorSnackbar
									errorMessage={errorMessage}
									errorOpen={hasError}
									handleErrorClose={handleClose}
								/>
							) : null}
						</Stack>
					</CardContent>
				</form>
				<form
				id='hlp-listing'
				noValidate
				onSubmit={formik.handleSubmit}>
				<CardContent sx={{ p: smUp ? 4 : 2 }}>
					<Stack
						component='section'
						display='flex'>
						<Typography
							component='h3'
							variant='h5'
							gutterBottom>
							Using your own words, tell your story.
						</Typography>

						<Typography
							component='span'
							variant='body1'
							color='text.secondary'
							mb={2}>
							Add details like service area, years in business, hours of operation,
							and any licenses or certifications you have.
						</Typography>

						<Grid
							container
							direction={smUp ? 'row' : 'column'}
							justifyContent={smUp ? 'space-evenly' : 'center'}
							alignItems='center'
							rowSpacing={1}
							columnSpacing={2}>
							{preview ? (
								<ListingShell
									business={business}
									category={category}
									content={formik.values.content}
									location={location}
									thumbnail={logo}
									title={formik.values.title}
								/>
							) : (
								<>
									<Grid xs={12}>
										<TextField
											id='hlp-listing-title'
											fullWidth
											required
											aria-describedby='helperText'
											aria-required='true'
											autoComplete='on'
											color='secondary'
											disabled={formik.isSubmitting}
											error={formik.touched.title && Boolean(formik.errors.title)}
											label='Title'
											margin='normal'
											name='title'
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											placeholder='Your Title Goes Here...'
											type='text'
											value={formik.values.title}
											variant='outlined'
											helperText={
												<Typography
													component='span'
													variant='caption'>
													{formik.touched.title && Boolean(formik.errors.title)
														? formik.errors.title
														: 'A short, clear title attracts potential customers'}
												</Typography>
											}
										/>
									</Grid>
									<Grid xs={12}>
										<SlateEditor formik={formik} />
									</Grid>
								</>
							)}
							<Grid xs={6}>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik.values.autofill}
											inputProps={{ 'aria-label': 'autofill checkbox' }}
											name='autofill'
											onChange={formik.handleChange}
										/>
									}
									label={
										<Typography
											component='span'
											variant='body2'>
											Remember my choices and prefill next time
										</Typography>
									}
								/>
							</Grid>
							<Grid xs={3}>
								<Button
									id='hlp-listing-preview'
									fullWidth
									disabled={formik.isSubmitting}
									color='secondary'
									onClick={handlePreview}
									type='button'
									variant='contained'>
									{preview === false ? 'Preview Post' : 'Cont'}
								</Button>
							</Grid>
							<Grid xs={3}>
								<LoadingButton
									id='hlp-listing-submit'
									fullWidth
									disabled={credits <= 1}
									loading={formik.isSubmitting}
									size='large'
									type='submit'
									variant='contained'>
									Create Post
								</LoadingButton>
							</Grid>
						</Grid>
						{hasError ? (
							<ErrorSnackbar
								errorMessage={errorMessage}
								errorOpen={hasError}
								handleErrorClose={handleClose}
							/>
						) : null}
					</Stack>
				</CardContent>
			</form>
			</Card>
							*/
