import { Outlet, redirect } from 'react-router-dom'
import { authService } from '../../../services/authService'

// ----------------------------------------------------------------------

/**
 * @param {{ request: any; }} props
 */
export async function loader(props) {
	const { request } = props
	if (!authService.isAuthenticated) {
		const params = new URLSearchParams()
		params.set('from', new URL(request.url).pathname)
		return redirect(`/login?${params.toString()}`)
	}
	return null
}

// ----------------------------------------------------------------------

export default function AuthLayout() {
	return <Outlet />
}
