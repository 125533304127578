import { Autocomplete, TextField, Typography } from '@mui/material'
import { LOCATION_ENUM } from '../../../utils/enums'

/**
 * @param {{ formik: { errors: {location: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal;}; handleBlur: {(event: import("react").FocusEvent<HTMLDivElement, Element>): void;(event: import("react").FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void;}; isSubmitting: boolean; setFieldValue: (arg0: string, arg1: any) => void; touched: {location: any;}; values: {location: unknown;}; }; mdUp: boolean; sending: boolean; smUp: boolean; }} props
 */
export default function HelperLocationAutocomplete(props) {
	const { formik, mdUp, smUp } = props

	const { errors, handleBlur, isSubmitting, setFieldValue, touched, values } = formik

	return (
		<Autocomplete
			id='hlp-onboard-loc'
			disableClearable
			fullWidth
			disabled={isSubmitting}
			getOptionLabel={(option) => option.toString() || ''}
			onChange={(_, value) => setFieldValue('location', value)}
			onBlur={handleBlur}
			options={LOCATION_ENUM}
			value={values.location}
			ListboxProps={{
				sx: {
					color: (theme) => theme.palette.secondary.main,
				},
			}}
			sx={{ mt: 2, width: mdUp ? '450px' : smUp ? '275px' : 'default' }}
			renderInput={(params) => (
				<TextField
					{...params}
					required
					color='secondary'
					label='Location'
					name='location'
					onBlur={handleBlur}
					value={values.location}
					variant='outlined'
					error={touched.location && Boolean(errors.location)}
					helperText={
						<Typography
							component='span'
							variant='caption'>
							{touched.location && Boolean(errors.location)
								? errors.location
								: 'Choose the nearest option.'}
						</Typography>
					}
				/>
			)}
		/>
	)
}
