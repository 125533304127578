import { useCallback } from 'react'
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Divider,
	FormControlLabel,
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
} from '@mui/material'

export default function Notifications() {
	const handleSubmit = useCallback((event) => {
		event.preventDefault()
	}, [])

	return (
		<form onSubmit={handleSubmit}>
			<Card>
				<CardHeader
					subheader='Manage the notifications'
					title='Notifications'
				/>
				<Divider />
				<CardContent>
					<Grid
						container
						spacing={6}
						wrap='wrap'>
						<Grid
							xs={12}
							sm={6}
							md={4}>
							<Stack spacing={1}>
								<Typography variant='h6'>Notifications</Typography>
								<Stack>
									<FormControlLabel
										control={<Checkbox defaultChecked />}
										label='Email'
									/>
									<FormControlLabel
										control={<Checkbox defaultChecked />}
										label='Push Notifications'
									/>
								</Stack>
							</Stack>
						</Grid>
						<Grid
							md={4}
							sm={6}
							xs={12}>
							<Stack spacing={1}>
								<Typography variant='h6'>Messages</Typography>
								<Stack>
									<FormControlLabel
										control={<Checkbox defaultChecked />}
										label='Email'
									/>
									<FormControlLabel
										control={<Checkbox />}
										label='Push Notifications'
									/>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
				<Divider />
				<CardActions sx={{ justifyContent: 'flex-end' }}>
					<Button variant='contained'>Save</Button>
				</CardActions>
			</Card>
		</form>
	)
}
