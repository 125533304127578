import { Alert, AlertTitle, Snackbar } from '@mui/material'

/**
 * @param {{ handleSuccessClose: { (event: Event | import("react").SyntheticEvent<any, Event>, reason: import("@mui/material").SnackbarCloseReason): void; (event: import("react").SyntheticEvent<Element, Event>): void; }; successMessage: string; successOpen: boolean; }} props
 */
export function SuccessSnackbar(props) {
	const { handleSuccessClose, successMessage, successOpen } = props
	return (
		<Snackbar
			open={successOpen}
			autoHideDuration={20000}
			onClose={handleSuccessClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}>
			<Alert
				onClose={handleSuccessClose}
				severity='success'
				sx={{ width: '100%' }}>
				<AlertTitle>{successMessage}</AlertTitle>
			</Alert>
		</Snackbar>
	)
}

/**
 * @param {{ errorMessage: string; errorOpen: boolean; handleErrorClose: { (event: Event | import("react").SyntheticEvent<any, Event>, reason: import("@mui/material").SnackbarCloseReason): void; (event: import("react").SyntheticEvent<Element, Event>): void; };   }} props
 */
export function ErrorSnackbar(props) {
	const { errorMessage, errorOpen, handleErrorClose } = props
	return (
		<Snackbar
			open={errorOpen}
			autoHideDuration={20000}
			onClose={handleErrorClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}>
			<Alert
				onClose={handleErrorClose}
				severity='error'
				sx={{ width: '100%' }}>
				<AlertTitle>{errorMessage}</AlertTitle>
			</Alert>
		</Snackbar>
	)
}

/**
 * @param {{ errorMessage: string; errorOpen: boolean; handleErrorClose: { (event: Event | import("react").SyntheticEvent<any, Event>, reason: import("@mui/material").SnackbarCloseReason): void; (event: import("react").SyntheticEvent<Element, Event>): void; }; handleSuccessClose: { (event: Event | import("react").SyntheticEvent<any, Event>, reason: import("@mui/material").SnackbarCloseReason): void; (event: import("react").SyntheticEvent<Element, Event>): void; }; successMessage: string; successOpen: boolean; }} props
 */
export default function Snackbars(props) {
	const {
		errorMessage,
		errorOpen,
		handleErrorClose,
		handleSuccessClose,
		successMessage,
		successOpen,
	} = props
	return (
		<>
			<SuccessSnackbar
				successOpen={successOpen}
				handleSuccessClose={handleSuccessClose}
				successMessage={successMessage}
			/>
			<ErrorSnackbar
				errorMessage={errorMessage}
				errorOpen={errorOpen}
				handleErrorClose={handleErrorClose}
			/>
		</>
	)
}
