import { CancelTwoTone } from '@mui/icons-material'
import { IconButton } from '@mui/material'

// ----------------------------------------------------------------------
/**
 * @param {{ visible: boolean; handleClick: import("react").MouseEventHandler<HTMLButtonElement>; }} props
 */
export default function CancelIconButton(props) {
	const { visible, handleClick } = props
	return (
		visible && (
			<IconButton
				color='error'
				onClick={handleClick}
				sx={{ position: 'absolute', right: 0, zIndex: 9 }}>
				<CancelTwoTone fontSize='small' />
			</IconButton>
		)
	)
}
