import { StepConnector, stepConnectorClasses, useTheme } from '@mui/material'

export default function ColorLibConnector() {
	const theme = useTheme()

	return (
		<StepConnector
			sx={{
				[`&.${stepConnectorClasses.alternativeLabel}`]: {
					top: 22,
				},
				[`&.${stepConnectorClasses.active}`]: {
					[`& .${stepConnectorClasses.line}`]: {
						backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 50%, ${theme.palette.secondary.light} 100%)`,
					},
				},
				[`&.${stepConnectorClasses.completed}`]: {
					[`& .${stepConnectorClasses.line}`]: {
						backgroundImage: `linear-gradient( 95deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
					},
				},
				[`& .${stepConnectorClasses.line}`]: {
					height: 3,
					border: 0,
					backgroundImage: `linear-gradient( 95deg, ${theme.palette.secondary.light} 0%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.dark} 100%)`,
					borderRadius: 1,
				},
			}}
		/>
	)
}
