import http from '../utils/axios-http'

const findOneById = async (/** @type {any} */ id) => {
	const config = {
		method: 'get',
		url: `/helper/${encodeURIComponent(id)}`,
	}
	return await http(config)
}

const findOneByAuthUser = async (id) => {
	const config = {
		method: 'get',
		url: `/helper/account/${encodeURIComponent(id)}`,
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const findAllByCategory = async () => {
	const config = {
		method: 'get',
		url: '/helpers/category',
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const findAllByLocation = async () => {
	const config = {
		method: 'get',
		url: '/helpers/location',
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const updateOne = async (/** @type {string} */ id, /** @type {any} */ values) => {
	const { business, category, location, logo, tel } = values

	const config = {
		method: 'post',
		url: `/helper/account/${encodeURIComponent(id)}`,
		data: {
			business,
			category,
			isOnboarded: true,
			location,
			logo,
			tel,
		},
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const billingPortal = async (/** @type {any} */ id) => {
	const config = {
		method: 'post',
		url: `/helper/account/${encodeURIComponent(id)}/portal-session`,
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const checkoutSession = async (values) => {
	const { lookupKey } = values
	const config = {
		method: 'post',
		url: `/helper/checkout-session`,
		data: lookupKey,
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const HelperService = {
	billingPortal,
	checkoutSession,
	findOneById,
	findOneByAuthUser,
	findAllByCategory,
	findAllByLocation,
	updateOne,
}

export default HelperService
