// ----------------------------------------------------------------------

export default function AppBar() {
	return {
		MuiAppBar: {
			styleOverrides: {
				root: {
					color: 'transparent',
				},
			},
		},
	}
}
