import { Skeleton, Stack } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'

function StackedSkeleton() {
	const smDown = useResponsive('down', 'sm')
	return (
		<Skeleton
			variant='rounded'
			animation='wave'
			width={smDown ? 'calc(100vw - 32px)' : 620}
			height={245}
			sx={{ mx: 'auto', mb: '32px', borderRadius: '4px' }}
		/>
	)
}

function ListExcerptSkeleton() {
	return (
		<Stack sx={{ mx: 'auto', mt: 2, mb: 4 }}>
			<StackedSkeleton />
			<StackedSkeleton />
			<StackedSkeleton />
			<StackedSkeleton />
		</Stack>
	)
}

function HelperSkeleton() {
	const smDown = useResponsive('down', 'sm')
	return (
		<Stack mx={'auto'}>
			<Skeleton
				variant='rounded'
				animation='wave'
				width={135}
				height={135}
				sx={{ mx: 'auto', mt: 2, mb: 1.5, maxWidth: 150, maxHeight: 135 }}
			/>
			{smDown ? (
				<Skeleton
					variant='rounded'
					animation='wave'
					width={288}
					height={240}
					sx={{ mx: 'auto', mt: 1 }}
				/>
			) : (
				<Skeleton
					variant='rounded'
					animation='wave'
					width={540}
					height={48}
					sx={{ mx: 'auto' }}
				/>
			)}

			<Skeleton
				variant='rounded'
				animation='wave'
				width={850}
				height={640}
				sx={{ mx: 'auto', my: 8 }}
			/>
		</Stack>
	)
}

function RightDrawerSkeleton() {
	return (
		<Skeleton
			animation='wave'
			height={240}
			sx={{
				bottom: 'calc(50% - 120px)',
				display: 'none',
				position: 'fixed',
				right: 0,
			}}
			variant='rounded'
			width={28.09}
		/>
	)
}

function LeftDrawerSkeleton() {
	return (
		<Skeleton
			animation='wave'
			height='100%'
			sx={{
				display: 'none',
				left: 0,
				position: 'fixed',
			}}
			variant='rounded'
			width={300}
		/>
	)
}

function HelperDashSkeleton() {
	return (
		<Stack mx={'auto'}>
			<Skeleton
				variant='rounded'
				animation='wave'
				width={48}
				height={816}
				sx={{ mt: 12.5, mb: 4 }}
			/>
		</Stack>
	)
}

export {
	HelperSkeleton,
	HelperDashSkeleton,
	LeftDrawerSkeleton,
	ListExcerptSkeleton,
	RightDrawerSkeleton,
	StackedSkeleton,
}
