import { Link, Stack, Typography } from '@mui/material'
import { Link as RouterLink, useLoaderData } from 'react-router-dom'
import NewListing from '../../components/data/listings/NewListing'
import useResponsive from '../../hooks/useResponsive'
import AuthHeader from './components/AuthHeader'

// ----------------------------------------------------------------------

export default function NewPost() {
	// @ts-ignore
	const { data } = useLoaderData()
	const { business, category, credits, id, location, logo } = data
	const smUp = useResponsive('up', 'sm')

	const breadcrumbs = [
		<Link
			key={0}
			variant='subtitle1'
			component={RouterLink}
			underline='hover'
			to='/home'>
			Home
		</Link>,
		<Link
			key={1}
			variant='subtitle1'
			component={RouterLink}
			underline='hover'
			to='/auth/dashboard'>
			Dashboard
		</Link>,
		<Typography
			key={2}
			variant='subtitle1'
			color='text.primary'>
			New Post
		</Typography>,
	]

	return (
		<AuthHeader
			id='hlp-schedule-post'
			title={'Create New Post - Helper Account | HelpListed'}
			description={
				'Tell your story however you please, then publish your business online for the whole world to see!'
			}
			heading='New Post'
			breadcrumbs={breadcrumbs}
			children={
				<Stack sx={{ minHeight: 350, my: 4, mx: smUp ? 2 : 0 }}>
					<NewListing
						business={business}
						category={category}
						credits={credits}
						id={id}
						location={location}
						logo={logo}
					/>
				</Stack>
			}
			imageAlt={''}
			imagePath={''}
		/>
	)
}
