import axios from 'axios'
import http from '../utils/axios-http-services'
import HelperService from './helperService'

const getUploadURL = async () => {
	const config = {
		method: 'get',
		url: '/cloudflare/direct_upload',
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const uploadLogo = async (
	/** @type {string} */ id,
	/** @type {string} */ url,
	/** @type {string | Blob} */ file,
	/** @type {any} */ onUploadProgress,
) =>
	await new Promise((resolve) => {
		const formData = new FormData()
		formData.append('file', file)

		resolve(
			axios
				.post(url, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					onUploadProgress,
				})
				.then(async (response) => {
					if (response.data.success === true) {
						await HelperService.updateOne(id, {
							logo: `https://helplisted.com/cdn-cgi/imagedelivery/V1nQb2Wy2XhC693mJyMETg/${response.data.result.id}/hl150x150`,
						})
					}
				}),
		)
	})

const FileUploadService = {
	getUploadURL,
	uploadLogo,
}

export default FileUploadService
