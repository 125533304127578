import { Link, Stack, Typography } from '@mui/material'
import { Link as RouterLink, useLoaderData } from 'react-router-dom'
import HelperListings from '../../components/data/listings/HelperListings'
import useResponsive from '../../hooks/useResponsive'
import ListingService from '../../services/listingService'
import AuthHeader from './components/AuthHeader'

// ----------------------------------------------------------------------

export async function loader() {
	return await ListingService.findListingsByHelper()
}

// ----------------------------------------------------------------------

export default function MyPosts() {
	const {
		// @ts-ignore
		data,
	} = useLoaderData()
	console.log(data)
	const smUp = useResponsive('up', 'sm')

	const breadcrumbs = [
		<Link
			key={0}
			variant='subtitle1'
			component={RouterLink}
			underline='hover'
			to='/home'>
			Home
		</Link>,
		<Link
			key={1}
			variant='subtitle1'
			component={RouterLink}
			underline='hover'
			to='/auth/dashboard'>
			Dashboard
		</Link>,
		<Typography
			key={2}
			variant='subtitle1'
			color='text.primary'>
			Manage Posts
		</Typography>,
	]

	return (
		<AuthHeader
			id='hlp-manage-posts'
			title={'Manage Active Posts - Helper Account | HelpListed'}
			description={
				'Use the Manage Posts area of your Helper dashboard to manage and delete posts you have already published.'
			}
			heading='Manage Posts'
			breadcrumbs={breadcrumbs}
			children={
				<Stack sx={{ minHeight: 350, my: 4, mx: smUp ? 2 : 0 }}>
					<HelperListings listings={data} />
				</Stack>
			}
			imageAlt={''}
			imagePath={''}
		/>
	)
}
