import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { createLRGradient } from '#utils/createGradient'
import { calendar, clock } from '#utils/dateBlocks'

export default function DateBar() {
	const [date, setDate] = useState(new Date())
	const refreshTime = () => setDate(new Date())
	const theme = useTheme()

	useEffect(() => {
		const intervalTimer = setInterval(() => refreshTime, 1000)
		return function cleanup() {
			clearInterval(intervalTimer)
		}
	}, [])

	return (
		<Box
			sx={{
				backgroundColor: 'transparent',
				backgroundImage: createLRGradient(
					theme.palette.secondary.dark,
					theme.palette.secondary.main,
				),
				backgroundSize: 'auto',
				borderBottomLeftRadius: 8,
				borderBottomRightRadius: 8,
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
				boxSizing: 'border-box',
				color: theme.palette.primary.main,
				display: 'block',
				fontWeight: 'bold',
				letterSpacing: 0.8,
				pl: 2,
				pr: 2,
				width: '100%',
			}}>
			<Stack
				alignItems='flex-start'
				direction='row'
				justifyContent='space-between'>
				<Typography
					component={calendar}
					date={date}
				/>
				<Typography
					component={clock}
					time={date}
				/>
			</Stack>
		</Box>
	)
}
