import Cookies from 'js-cookie'
import httpAuth from '../utils/axios-http-auth'

export const authService = {
	currentHelper: null,
	isAuthenticated: false,
	isCraftsman: false,
	isJourneyman: false,
	// forgot password service
	async forgotPass(/** @type {string} */ email) {
		await new Promise((resolve) => {
			const config = {
				method: 'post',
				url: '/password/forgot',
				data: {
					email,
				},
			}
			resolve(httpAuth(config))
		})
	},
	// register service
	/**
	 * @param {{ email: string; password: string; }} data
	 */
	async register(data) {
		await new Promise((resolve) => {
			const { email, password } = data
			const config = {
				method: 'post',
				url: '/register',
				data: {
					email,
					password,
				},
			}
			resolve(httpAuth(config))
		})
	},
	// reset password service
	/**
	 * @param {{ email: string; password: string; token: string; }} data
	 */
	async resetPass(data) {
		await new Promise((resolve) => {
			const { email, password, token } = data
			const config = {
				method: 'post',
				url: '/password/reset',
				data: {
					email,
					password,
					token,
				},
			}
			resolve(httpAuth(config))
		})
	},
	// verify service
	async verify({ email, token }) {
		await new Promise((resolve) => {
			const config = {
				method: 'post',
				url: '/verify',
				data: {
					email,
					token,
				},
			}
			resolve(
				httpAuth(config).then((response) => {
					if (response.data.id) {
						if (response.data.roles?.includes('ROLE_CRAFTSMAN')) {
							authService.isCraftsman = true
						}
						if (response.data.roles?.includes('ROLE_JOURNEYMAN')) {
							authService.isJourneyman = true
						}
						authService.isAuthenticated = true
						authService.currentHelper = response.data.id
						Cookies.set('_hlpAcc', 'nichtNeu', {
							expires: 183,
							secure: true,
							sameSite: 'strict',
						})
					}
					return response
				}),
			)
		})
	},
	// login service
	/**
	 * @param {{ email: string; password: string; }} data
	 */
	async login(data) {
		await new Promise((resolve) => {
			const { email, password } = data
			const config = {
				method: 'post',
				url: '/login',
				data: {
					email,
					password,
				},
			}
			resolve(
				httpAuth(config).then((response) => {
					if (response.data.id) {
						if (response.data.roles.includes('ROLE_CRAFTSMAN')) {
							authService.isCraftsman = true
						}
						if (response.data.roles.includes('ROLE_JOURNEYMAN')) {
							authService.isJourneyman = true
						}
						authService.isAuthenticated = true
						authService.currentHelper = response.data.id
						Cookies.set('_hlpAcc', 'nichtNeu', {
							expires: 183,
							secure: true,
							sameSite: 'strict',
						})
					}
					return response
				}),
			)
		})
	},
	// logout service
	async logout() {
		await new Promise((resolve) => {
			const config = {
				method: 'post',
				url: '/logout',
			}
			resolve(
				httpAuth(config).then(() => {
					authService.isAuthenticated = false
					authService.currentHelper = null
				}),
			)
		})
	},
	// Network getters and setters
	async getCurrentHelper() {
		const config = {
			method: 'get',
			url: '/current',
		}
		try {
			const response = await httpAuth(config)
			if (response.data.id) {
				authService.isAuthenticated = true
				authService.currentHelper = response.data.id
				Cookies.set('_hlpAcc', 'nichtNeu', {
					expires: 183,
					secure: true,
					sameSite: 'strict',
				})
			}
		} catch (e) {
			console.log('Hello from the other side!')
			authService.isAuthenticated = false
			authService.currentHelper = null
		}
	},
}
