import { Outlet, redirect } from 'react-router-dom'
import { authService } from '../services/authService'

// ----------------------------------------------------------------------

const getCurrentHelperData = () =>
	new Promise((resolve) => {
		const { currentHelper } = authService
		authService.getCurrentHelper().then(() => resolve(currentHelper))
	})

// ----------------------------------------------------------------------

export async function loader() {
	await getCurrentHelperData()
	if (authService.isAuthenticated) {
		return redirect('/auth/dashboard')
	}
	return null
}

// ----------------------------------------------------------------------

export default function PublicLayout() {
	return <Outlet />
}
