import { Button, Typography } from '@mui/material'
import Cookies from 'js-cookie'
import { Link as RouterLink } from 'react-router-dom'
import { authService } from '../../../services/authService'

// -------------------------------------------------------------------

/**
 * @param {{ id: string; buttonText: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; color: (string & {}) | import("@mui/system").SystemStyleObject<import("@mui/material").Theme> | (string | number) | ((theme: import("@mui/material").Theme) => string | number | import("@mui/system").SystemStyleObject<import("@mui/material").Theme>) | string[] | (string[] | import("csstype").Property.Color)[] | { [key: string]: string[] | import("csstype").Property.Color; } | ((theme: import("@mui/material").Theme) => import("@mui/system/styleFunctionSx").ResponsiveStyleValue<string[] | import("csstype").Property.Color>); textColor: string | (string & {}) | string[] | (string[] | import("csstype").Property.Color)[] | { [key: string]: string[] | import("csstype").Property.Color; } | ((theme: import("@mui/material").Theme) => import("@mui/system/styleFunctionSx").ResponsiveStyleValue<string[] | import("csstype").Property.Color>); }} props
 */
export default function CtaButton(props) {
	const { id, buttonText, color, textColor } = props
	const accVal = Cookies.get('_hlpAcc')

	return (
		<Button
			id={id}
			fullWidth
			color={color ? color : 'primary'}
			component={RouterLink}
			size='large'
			sx={{ my: 1.25, underline: 'none' }}
			to={
				authService.isAuthenticated
					? '/auth/dashboard'
					: accVal === 'nichtNeu'
					? '/login'
					: '/register'
			}
			variant='contained'>
			<Typography
				variant='button'
				color={textColor ? textColor : 'default'}>
				{buttonText}
			</Typography>
		</Button>
	)
}
