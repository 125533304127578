import { Button, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { PageWrapper } from '../components/layout/box/PageWrapper'
import { ReactComponent as SvgError } from '../images/svg/error_404.svg'

// ----------------------------------------------------------------------

export default function NoMatch() {
	return (
		<PageWrapper
			id='hlp-error-404'
			description='Page Not Found'
			title='Error 404'>
			<Stack
				direction='column'
				justifyContent='center'
				alignItems='center'
				spacing={3}
				mx='auto'
				my={6}>
				<Stack
					sx={{
						width: { xs: 300, sm: 360, md: 480 },
					}}>
					<SvgError />
				</Stack>
				<Typography
					gutterBottom
					align='center'
					component='h1'
					variant='h4'>
					We can&apos;t find the page you&apos;re looking for.
				</Typography>
				<Typography
					gutterBottom
					paragraph
					align='center'
					color='text.secondary'>
					You either tried some shady route or came here by mistake.
					<br />
					Regardless, try using the navigation next time...
				</Typography>
				<Button
					component={RouterLink}
					size='large'
					to='/'
					variant='contained'>
					Go Home
				</Button>
			</Stack>
		</PageWrapper>
	)
}
