import {
	FormatAlignCenterTwoTone,
	FormatAlignLeftTwoTone,
	FormatAlignRightTwoTone,
	FormatBoldTwoTone,
	FormatItalicTwoTone,
	FormatListBulletedTwoTone,
	FormatListNumberedTwoTone,
	FormatQuoteTwoTone,
	FormatStrikethroughTwoTone,
	FormatUnderlinedTwoTone,
	TitleTwoTone,
} from '@mui/icons-material'
import {
	FormControl,
	FormHelperText,
	Unstable_Grid2 as Grid,
	OutlinedInput,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material'
import isHotkey from 'is-hotkey'
import { useCallback, useMemo, useState } from 'react'
import { Editor, Element as SlateElement, Transforms, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, useSlate, withReact } from 'slate-react'

const HOTKEYS = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
	'mod+t': 'strikethrough',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right']

function SlateTextField({ editor, formik, renderElement, renderLeaf }) {
	return (
		<Grid
			container
			direction='column'
			justifyContent='center'
			alignItems='center'>
			<Grid xs={12}>
				{/**<FormControl
				disabled={formik.isSubmitting}
	error={formik.touched.content && Boolean(formik.errors.content)}>*/}
				<OutlinedInput
					id='hlp-listing-content'
					fullWidth
					aria-describedby='hlp-listing-content-error-text'
					aria-required='false'
					autoComplete='on'
					inputComponent={() => (
						<Editable
							disableDefaultStyles
							spellCheck
							placeholder='Your Content Goes Here...'
							renderElement={renderElement}
							renderLeaf={renderLeaf}
							onKeyDown={(event) => {
								for (const hotkey in HOTKEYS) {
									if (isHotkey(hotkey, event)) {
										event.preventDefault()
										const mark = HOTKEYS[hotkey]
										toggleMark(editor, mark)
									}
								}
							}}
							name='content'
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							value={formik.values.content}
						/>
					)}
					required={false}
					sx={{ mt: 2, p: 6, width: '100%' }}
				/>
				{/**<FormHelperText id='hlp-listing-content-error-text'>
					<Typography
						component='span'
						variant='caption'>
						{formik.touched.content && Boolean(formik.errors.content)
							? formik.errors.content
							: 'This box expands as needed based on your input.'}
					</Typography>
				</FormHelperText>
						</FormControl>*/}
			</Grid>
		</Grid>
	)
}

/**
 * @param {{ formik: any; }} props
 */
function SlateEditor(props) {
	const { formik } = props
	const [editor] = useState(() => withReact(withHistory(createEditor())))
	const initialValue = useMemo(
		() =>
			JSON.parse(localStorage.getItem('_sltcont')) || [
				{
					type: 'paragraph',
					children: [{ text: 'Enter some text, then add some flair...' }],
				},
			],
		[],
	)
	const renderElement = useCallback(
		(
			/** @type {import("react/jsx-runtime").JSX.IntrinsicAttributes & { attributes: any; children: any; element: any; }} */ props,
		) => <Element {...props} />,
		[],
	)
	const renderLeaf = useCallback(
		(
			/** @type {import("react/jsx-runtime").JSX.IntrinsicAttributes & { attributes: any; children: any; leaf: any; }} */ props,
		) => <Leaf {...props} />,
		[],
	)
	return (
		<Slate
			editor={editor}
			initialValue={initialValue}
			onChange={(value) => {
				const isAstChange = editor.operations.some(
					(op) => 'set_selection' !== op.type,
				)
				if (isAstChange) {
					// Save the value to Local Storage.
					const content = JSON.stringify(value)
					localStorage.setItem('_sltcont', content)
				}
			}}>
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				spacing={1}>
				<ToggleButtonGroup sx={{ m: 1 }}>
					<MarkButton
						value='bold'
						icon={<FormatBoldTwoTone />}
					/>
					<MarkButton
						value='italic'
						icon={<FormatItalicTwoTone />}
					/>
					<MarkButton
						value='underline'
						icon={<FormatUnderlinedTwoTone />}
					/>
					<MarkButton
						value='strikethrough'
						icon={<FormatStrikethroughTwoTone />}
					/>
				</ToggleButtonGroup>
				<ToggleButtonGroup sx={{ m: 1 }}>
					<BlockButton
						value='heading-one'
						icon={<TitleTwoTone />}
					/>
					<BlockButton
						value='heading-two'
						icon={<TitleTwoTone sx={{ p: 0.5 }} />}
					/>
					<BlockButton
						value='block-quote'
						icon={<FormatQuoteTwoTone />}
					/>
				</ToggleButtonGroup>
				<ToggleButtonGroup sx={{ m: 1 }}>
					<BlockButton
						value='numbered-list'
						icon={<FormatListNumberedTwoTone />}
					/>
					<BlockButton
						value='bulleted-list'
						icon={<FormatListBulletedTwoTone />}
					/>
				</ToggleButtonGroup>
				<ToggleButtonGroup sx={{ m: 1 }}>
					<BlockButton
						value='left'
						icon={<FormatAlignLeftTwoTone />}
					/>
					<BlockButton
						value='center'
						icon={<FormatAlignCenterTwoTone />}
					/>
					<BlockButton
						value='right'
						icon={<FormatAlignRightTwoTone />}
					/>
				</ToggleButtonGroup>
			</Stack>
			<SlateTextField
				editor={editor}
				formik={formik}
				renderElement={renderElement}
				renderLeaf={renderLeaf}
			/>
		</Slate>
	)
}

const toggleBlock = (
	/** @type {import("slate").BaseEditor} */ editor,
	/** @type {string} */ format,
) => {
	const isActive = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
	)
	const isList = LIST_TYPES.includes(format)

	Transforms.unwrapNodes(editor, {
		match: (n) =>
			!Editor.isEditor(n) &&
			SlateElement.isElement(n) &&
			// @ts-ignore
			LIST_TYPES.includes(n.type) &&
			!TEXT_ALIGN_TYPES.includes(format),
		split: true,
	})
	let newProperties
	newProperties = TEXT_ALIGN_TYPES.includes(format)
		? {
				// @ts-ignore
				align: isActive ? undefined : format,
		  }
		: {
				type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		  }
	Transforms.setNodes(editor, newProperties)

	if (!isActive && isList) {
		const block = { type: format, children: [] }
		Transforms.wrapNodes(editor, block)
	}
}

const toggleMark = (
	/** @type {import("slate").BaseEditor} */ editor,
	/** @type {string} */ format,
) => {
	const isActive = isMarkActive(editor, format)

	if (isActive) {
		Editor.removeMark(editor, format)
	} else {
		Editor.addMark(editor, format, true)
	}
}

const isBlockActive = (
	/** @type {import("slate").BaseEditor} */ editor,
	/** @type {any} */ format,
	blockType = 'type',
) => {
	const { selection } = editor
	if (!selection) return false

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: (n) =>
				!Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
		}),
	)

	return !!match
}

const isMarkActive = (
	/** @type {import("slate").BaseEditor} */ editor,
	/** @type {string | number} */ format,
) => {
	const marks = Editor.marks(editor)
	return marks ? marks[format] === true : false
}

/**
 * @param {{ attributes: any; children: any; element: any; }} props
 */
function Element(props) {
	const { attributes, children, element } = props
	const style = { textAlign: element.align }
	switch (element.type) {
		case 'block-quote':
			return (
				<blockquote
					style={style}
					{...attributes}>
					{children}
				</blockquote>
			)
		case 'bulleted-list':
			return (
				<ul
					style={style}
					{...attributes}>
					{children}
				</ul>
			)
		case 'heading-one':
			return (
				<Typography
					variant='h5'
					style={style}
					{...attributes}>
					{children}
				</Typography>
			)
		case 'heading-two':
			return (
				<Typography
					variant='subtitle1'
					style={style}
					{...attributes}>
					{children}
				</Typography>
			)
		case 'list-item':
			return (
				<li
					style={style}
					{...attributes}>
					{children}
				</li>
			)
		case 'numbered-list':
			return (
				<ol
					style={style}
					{...attributes}>
					{children}
				</ol>
			)
		default:
			return (
				<p
					style={style}
					{...attributes}>
					{children}
				</p>
			)
	}
}

function Leaf({ attributes, children, leaf }) {
	if (leaf.bold) {
		children = <strong>{children}</strong>
	}
	if (leaf.italic) {
		children = <em>{children}</em>
	}
	if (leaf.underline) {
		children = <u>{children}</u>
	}
	if (leaf.strikethrough) {
		children = <del>{children}</del>
	}
	return <span {...attributes}>{children}</span>
}

function BlockButton({ value, icon }) {
	const editor = useSlate()
	return (
		<ToggleButton
			children={icon}
			onClick={(/** @type {{ preventDefault: () => void; }} */ event) => {
				event.preventDefault()
				toggleBlock(editor, value)
			}}
			selected={isBlockActive(
				editor,
				value,
				TEXT_ALIGN_TYPES.includes(value) ? 'align' : 'type',
			)}
			size='medium'
			value='value'
		/>
	)
}

function MarkButton({ value, icon }) {
	const editor = useSlate()
	return (
		<ToggleButton
			children={icon}
			onClick={(/** @type {{ preventDefault: () => void; }} */ event) => {
				event.preventDefault()
				toggleMark(editor, value)
			}}
			selected={isMarkActive(editor, value)}
			size='medium'
			value='value'
		/>
	)
}

export default SlateEditor
