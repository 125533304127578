// ----------------------------------------------------------------------

/**
 * @param {import("@mui/material").Theme} theme
 */
export default function FormHelperText(theme) {
	return {
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: theme.palette.text.secondary,
					fontSize: 10,
				},
			},
		},
	}
}
