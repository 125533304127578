// ----------------------------------------------------------------------

/**
 * @param {{ date: string | number | Date; }} props
 */
function calendar(props) {
	const { date } = props

	const isoDate = new Date(date).toISOString()
	const localeDate = new Date(date).toLocaleDateString()

	return <time dateTime={isoDate}>{localeDate}</time>
}

// ----------------------------------------------------------------------

/**
 * @param {{ time: string | number | Date; }} props
 */
function clock(props) {
	const { time } = props

	const isoTime = new Date(time).toISOString()
	const localeTime = new Date(time).toLocaleTimeString([], {
		timeStyle: 'short',
	})

	return <time dateTime={isoTime}>{localeTime}</time>
}

export { calendar, clock }
