import { Pagination } from '@mui/material'

/**
 * @param {{ count: number; page: number; handlePageChange: (event: import("react").ChangeEvent<unknown>, page: number) => void; }} props
 */
export default function ListingPaginator(props) {
	const { count, page, handlePageChange } = props
	return (
		<Pagination
			boundaryCount={1}
			color='secondary'
			count={count}
			onChange={handlePageChange}
			page={page}
			shape='rounded'
			siblingCount={1}
			variant='outlined'
		/>
	)
}
