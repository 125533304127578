import { TextField, Typography } from '@mui/material'

/**
 * @param {{ formik: { handleBlur: import("react").FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>; handleChange: import("react").ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>; values: { business: unknown; }; touched: { business: any; }; errors: { business: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; }; }; mdUp: boolean; sending: boolean; smUp: boolean; }} props
 */
export function HelperBizInput(props) {
	const { formik, mdUp, sending, smUp } = props
	const { errors, handleBlur, handleChange, touched, values } = formik

	return (
		<TextField
			id='hlp-onboard-biz'
			fullWidth
			required
			aria-describedby='helperText'
			aria-required='true'
			autoComplete='organization'
			color='secondary'
			disabled={sending}
			error={touched.business && Boolean(errors.business)}
			label='Business'
			margin='normal'
			name='business'
			onBlur={handleBlur}
			onChange={handleChange}
			placeholder='HelpListed LLC'
			type='text'
			value={values.business}
			variant='outlined'
			helperText={
				<Typography
					component='span'
					variant='caption'>
					{touched.business && Boolean(errors.business)
						? errors.business
						: 'Use a registered name, DBA, or personal name.'}
				</Typography>
			}
			sx={{ mt: 2, width: mdUp ? '450px' : smUp ? '275px' : 'default' }}
		/>
	)
}
