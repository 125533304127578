import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import SeoHelmet from '../../sections/head/SeoHelmet'

// ---------------------------------------------------------------------

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} [props.categoryName]
 * @param {string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | import("react").ReactPortal} props.children
 * @param {string} [props.contentType]
 * @param {string} [props.creator]
 * @param {string} props.description
 * @param {string} [props.imageAlt]
 * @param {string} [props.imagePath]
 * @param {string} [props.pubTime]
 * @param {string[]} [props.tagArray]
 * @param {string} props.title
 * @param {string} [props.updatedTime]
 * @param {any[]} [props.rest]
 */
export function PageWrapper(props) {
	const {
		id,
		categoryName,
		children,
		contentType,
		creator,
		description,
		imageAlt,
		imagePath,
		pubTime,
		tagArray,
		title,
		updatedTime,
		...rest
	} = props

	const location = useLocation()

	return (
		<>
			<SeoHelmet
				categoryName={categoryName}
				contentType={contentType}
				creator={creator}
				description={description}
				imageAlt={imageAlt}
				imagePath={imagePath}
				path={location.toString()}
				pubTime={pubTime}
				tagArray={tagArray}
				title={title}
				updatedTime={updatedTime}
			/>
			<Box
				id={id}
				component='main'
				{...rest}>
				{children}
			</Box>
		</>
	)
}
