import { Box } from '@mui/material'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import Navigation from '../components/navigation/Navigation'
import Footer from '../components/sections/footer/Footer'

// ----------------------------------------------------------------------

export default function Layout() {
	return (
		<Box
			component='div'
			sx={{
				minHeight: '100vh',
			}}>
			<Navigation />

			<ScrollRestoration />
			<Outlet />

			<Footer />
		</Box>
	)
}
