import { TabPanel } from '@mui/lab'
import { Outlet } from 'react-router-dom'

/**
 * @param {{ value: string }} props
 */
export default function RouterLinkTabPanel(props) {
	const { value } = props

	return (
		<TabPanel
			value={value}
			children={<Outlet />}
		/>
	)
}
