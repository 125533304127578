/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup'
import {
	checkIfFilesAreCorrectType,
	checkIfFilesAreTooBig,
} from '../components/data/helpers/widgets/gallery/validation'
import { CAPSREGEX, NUMREGEX, TELREGEX } from './regecies'

// ----------------------------------------------------------------------
const authSchema = yup.object().shape({
	email: yup
		.string()
		.required('Email must be provided!')
		.min(6, 'Email must use at least ${min} chars!')
		.max(254, 'Email must use at most ${max} chars!')
		.email('Email must be valid!'),
	password: yup
		.string()
		.required('Password must be provided!')
		.min(10, 'Use no less than ${min} chars!')
		.max(254, 'Use no more than ${max} chars!')
		.matches(CAPSREGEX, 'Use one or more capital letters!')
		.matches(NUMREGEX, 'Use one or more numbers!'),
})

const forgotPassSchema = yup.object({
	email: yup
		.string()
		.required('Email must be provided!')
		.min(6, 'Email must be at least ${min} chars!')
		.max(254, 'Email must be at most ${max} chars!')
		.email('Email must be valid!'),
})

const helperSchema = yup.object().shape({
	business: yup
		.string()
		.min(4, 'Must be a minimum 4 characters')
		.max(50, 'Must be a maximum 50 characters')
		.required('A business name must be provided'),
	tel: yup
		.string()
		.matches(TELREGEX, 'Should be a valid 10-digit number')
		.required('A phone number must be provided'),
	category: yup
		.string()
		.max(30, 'Must be a maximum 30 characters')
		.required('A category must be selected'),
	location: yup
		.string()
		.max(30, 'Must be a maximum 30 characters')
		.required('A location must be selected'),
})

const helperBizSchema = yup.object({
	business: yup
		.string()
		.max(50, 'No more than 50 characters')
		.required('A business name must be provided'),
})

const helperSortSchema = yup.object().shape({
	category: yup.string().required('A category must be selected').nullable(true),
	location: yup.string().required('A location must be selected').nullable(true),
})

const helperTelSchema = yup.object({
	tel: yup
		.string()
		.matches(TELREGEX, 'A phone number must be provided')
		.required('Should be a valid 10-digit number'),
})

const listingSchema = yup.object().shape({
	title: yup
		.string()
		.required('Title must be provided.')
		.min(6, 'Use no less than ${min} chars!')
		.max(200, 'Use no more than ${max} chars!'),
	content: yup
		.string()
		.required('Content must be provided.')
		.min(20, 'Minimum is 20 characters')
		.max(999, 'Maximum is 999 characters'),
	autofill: yup.bool().oneOf([true, false]),
})

const resetPassSchema = yup.object({
	password: yup
		.string()
		.required('Password must be provided!')
		.min(10, 'Use no less than ${min} chars!')
		.max(254, 'Use no more than ${max} chars!')
		.matches(CAPSREGEX, 'Use one or more capital letters!')
		.matches(NUMREGEX, 'Use one or more numbers!'),
})

const imageUploadSchema = yup.object().shape({
	files: yup
		.array()
		.nullable()
		.required('Image file must be provided')
		.test('is-big-file', 'Image file must be ', checkIfFilesAreTooBig)
		.test(
			'is-correct-file',
			'VALIDATION_FIELD_FILE_WRONG_TYPE',
			checkIfFilesAreCorrectType,
		),
})

export {
	authSchema,
	forgotPassSchema,
	helperBizSchema,
	helperSchema,
	helperSortSchema,
	helperTelSchema,
	imageUploadSchema,
	listingSchema,
	resetPassSchema,
}
