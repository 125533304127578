import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

/**
 * @param {{ handlePageSizeChange: (event: import("@mui/material").SelectChangeEvent<any>, child: import("react").ReactNode) => void; pageSize: number; pageSizes: number[]; }} props
 */
export default function SelectPagination(props) {
	const { handlePageSizeChange, pageSize, pageSizes } = props
	return (
		<FormControl
			sx={{ m: 1, minWidth: 75 }}
			size='small'>
			<InputLabel
				id='hlp-posts-to-load-label'
				htmlFor='page-size-select'
				color='secondary'>
				Posts
			</InputLabel>
			<Select
				labelId='hlp-posts-to-load-label'
				id='hlp-posts-to-load'
				autoWidth
				color='secondary'
				inputProps={{ color: 'secondary' }}
				label='Posts'
				MenuProps={{
					sx: {
						color: (theme) => theme.palette.secondary.main,
					},
				}}
				value={pageSize}
				onChange={handlePageSizeChange}>
				{pageSizes.map((pageSize) => (
					<MenuItem
						key={pageSize.toString()}
						value={pageSize}>
						{pageSize.toString()}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
