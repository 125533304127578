import SelectPagination from '../inputs/select/SelectPagination'
import ListingPaginator from '../navigation/pagination/ListingPaginator'

// ----------------------------------------------------------------------

/**
 * @param {{ count: number; page: number; pageSize: number; pageSizes: number[]; setPage: (arg0: import("react").SetStateAction<number>) => void; setPageSize: (arg0: import("react").SetStateAction<number>) => void; }} props
 */
export default function PageSizeSelect(props) {
	const { count, page, pageSize, pageSizes, setPage, setPageSize } = props

	const handlePageChange = (
		/** @type {any} */ event,
		/** @type {import("react").SetStateAction<number>} */ value,
	) => {
		setPage(value)
	}
	const handlePageSizeChange = (
		/** @type {{ target: { value: import("react").SetStateAction<number>; }; }} */ event,
	) => {
		setPageSize(event.target.value)
		setPage(1)
	}

	return (
		<>
			<SelectPagination
				// @ts-ignore
				handlePageSizeChange={handlePageSizeChange}
				pageSize={pageSize}
				pageSizes={pageSizes}
			/>
			<ListingPaginator
				count={count}
				handlePageChange={handlePageChange}
				page={page}
			/>
		</>
	)
}
