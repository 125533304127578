import { alpha } from '@mui/material'

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
	0: '#FFFFFF',
	100: '#F5F5F5',
	200: '#E3E5E7',
	300: '#C8CCCF',
	400: '#ACB2B7',
	500: '#90989F',
	600: '#757F87',
	700: '#59656F',
	800: '#38414A',
	900: '#161C24',
	500_8: alpha('#90989F', 0.08),
	500_12: alpha('#90989F', 0.12),
	500_16: alpha('#90989F', 0.16),
	500_24: alpha('#90989F', 0.24),
	500_32: alpha('#90989F', 0.32),
	500_48: alpha('#90989F', 0.48),
	500_56: alpha('#90989F', 0.56),
	500_80: alpha('#90989F', 0.8),
}

const PRIMARY = {
	/** rgba(247 182 27 / 1) */
	main: '#F7B61B',
	contrastText: '#000',
}

const SECONDARY = {
	/** rgba(76 91 97 / 1) */
	main: '#5D5C59',
	contrastText: '#FFF',
}

const INFO = {
	main: '#F137AC',
	contrastText: '#000',
}

const BACKGROUND = {
	default: GREY[100],
	neutral: GREY[200],
}

const TEXT = {
	primary: 'rgba(0, 0, 0, 0.95)',
	secondary: 'rgba(0, 0, 0, 0.84)',
	disabled: 'rgba(0, 0, 0, 0.48)',
	hint: 'rgba(0, 0, 0, 0.58)',
}

const CHART_COLORS = {
	violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
	blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
	green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
	yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
	red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
}

const palette = {
	primary: { ...PRIMARY },
	secondary: { ...SECONDARY },
	info: { ...INFO },
	grey: { ...GREY },
	contrastThreshold: 4.5,
	text: { ...TEXT },
	background: { ...BACKGROUND },
	action: {
		active: GREY[800],
		hover: GREY[500_8],
		selected: GREY[500_16],
		disabled: GREY[500_80],
		disabledBackground: GREY[500_24],
		focus: GREY[500_24],
		hoverOpacity: 0.08,
		disabledOpacity: 0.48,
	},
	chart: { ...CHART_COLORS },
}

export default palette
