import {
	AddBusinessTwoTone,
	AddLocationTwoTone,
	ContactPhoneTwoTone,
	DoneTwoTone,
} from '@mui/icons-material'
import { styled } from '@mui/material'
import { createLRGradient } from '../../../../utils/createGradient'

// @ts-ignore
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundImage: createLRGradient(
		theme.palette.secondary.light,
		theme.palette.secondary.dark,
	),
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	...(ownerState.active && {
		backgroundImage: createLRGradient(
			theme.palette.primary.main,
			theme.palette.secondary.light,
		),
	}),
	...(ownerState.completed && {
		color: theme.palette.grey[900],
		backgroundImage: createLRGradient(
			theme.palette.primary.light,
			theme.palette.primary.main,
		),
	}),
}))

/**
 * @param {{ active: boolean; completed: boolean; className: string; icon: Node; }} props
 */
export default function ColorlibStepIcon(props) {
	const { active, completed, className, icon } = props
	const icons = {
		1: completed ? <DoneTwoTone /> : <AddBusinessTwoTone />,
		2: completed ? <DoneTwoTone /> : <AddLocationTwoTone />,
		3: completed ? <DoneTwoTone /> : <ContactPhoneTwoTone />,
	}

	return (
		<ColorlibStepIconRoot
			// @ts-ignore
			ownerState={{ completed, active }}
			className={className}>
			{icons[String(icon)]}
		</ColorlibStepIconRoot>
	)
}
