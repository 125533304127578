import http from '../utils/axios-http'

const findOneById = async (/** @type {string} */ id) => {
	const config = {
		method: 'get',
		url: `/category/${encodeURIComponent(id)}`,
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const findOneByCategory = async (/** @type {string} */ category) => {
	const config = {
		method: 'get',
		url: `/categories/category?category=${encodeURIComponent(category)}`,
	}
	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const CategoryService = {
	findOneById,
	findOneByCategory,
}

export default CategoryService
