import { Container, useTheme } from '@mui/material'
import { PageWrapper } from '../components/layout/box/PageWrapper'
import StyledToolbar from '../components/navigation/navbar/StyledToolbar'
import SectionOne from '../components/sections/index/SectionOne'
import SectionThree from '../components/sections/index/SectionThree'
import SectionTwo from '../components/sections/index/SectionTwo'
import useResponsive from '../hooks/useResponsive'
import './index.css'

// -------------------------------------------------------------------

export default function Index() {
	const lgUp = useResponsive('up', 'lg')
	const mdUp = useResponsive('up', 'md')
	const smDown = useResponsive('down', 'sm')
	const smUp = useResponsive('up', 'sm')
	const theme = useTheme()

	return (
		<PageWrapper
			id='hlp-index'
			title='HelpListed - Helping Contractors and Customers Find One Another'
			description='Connecting Local Contractors with Customers Needing Work Done in their Area'>
			<Container maxWidth='lg'>
				<StyledToolbar />
				<SectionOne
					lgUp={lgUp}
					mdUp={mdUp}
					smUp={smUp}
				/>
				<SectionTwo
					mdUp={mdUp}
					smDown={smDown}
					theme={theme}
				/>
				<SectionThree mdUp={mdUp} />
			</Container>
		</PageWrapper>
	)
}
