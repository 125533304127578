// @ts-ignore
import LibreFranklinBold from '../../fonts/Libre-Franklin/libre-franklin-v13-latin-700.woff2'
// @ts-ignore
import LibreFranklinBlack from '../../fonts/Libre-Franklin/libre-franklin-v13-latin-900.woff2'
// @ts-ignore
import OpenSansRegular from '../../fonts/opensans/open-sans-v35-latin-regular.woff2'
// @ts-ignore
import OpenSansMedium from '../../fonts/opensans/open-sans-v35-latin-500.woff2'
// @ts-ignore
import OpenSansSemiBold from '../../fonts/opensans/open-sans-v35-latin-600.woff2'
// @ts-ignore
import OpenSansSemiBoldItalic from '../../fonts/opensans/open-sans-v35-latin-600italic.woff2'
// @ts-ignore
import OpenSansBold from '../../fonts/opensans/open-sans-v35-latin-700.woff2'

// ----------------------------------------------------------------------

export default function CssBaseline() {
	return {
		MuiCssBaseline: {
			styleOverrides: `
			* {
				margin: 0;
				padding: 0;
				box-sizing: border-box;
			}
			*:focus {
				outline: 0;
			}
			*::-webkit-scrollbar {
				width: 0.2em;
				background-color: rgba(219 223 227 / 0);
			}
			*::-webkit-scrollbar-track {
				box-shadow: inset 0 0 8px rgba(0 0 0 / 0);
			}
			*::-webkit-scrollbar-thumb {
				background-color: rgba(247 182 27 / 0.8);
				border-radius: 0.2em;
			}
			*::-webkit-scrollbar-thumb:hover {
				background-color: rgba(247 182 27 / 1);
			}
			html {
				width: 100%;
				height: 100%;
				overflow-x: hidden;
			}
			body {
				width: 100%;
				height: 100%;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
			}
			a {
				text-decoration: none;
				color: rgba(172 127 18 / 1);
			}
			input::-webkit-scrollbar,
			input::-webkit-scrollbar-thumb,
			input::-webkit-scrollbar-track {
				width: 0;
				display: none;
			}
			input[type='number']::-webkit-outer-spin-button,
			input[type='number']::-webkit-inner-spin-button {
				margin: 0;
				appearance: none;
			}
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
			}
			img {
				display: block;
				max-width: 100%;
			}
			@font-face {
				font-display: swap;
				font-family: 'Libre Franklin';
				font-style: normal;
				font-weight: 700;
				src: url(${LibreFranklinBold}) format('woff2');
				unicode-range: U+20-22, U+26-29, U+2C-3B, U+3F, U+41-4A, U+4C-50, U+52-57, U+59, U+61-7A, U+A9, U+2013;
			}
			@font-face {
				font-display: swap;
				font-family: 'Libre Franklin';
				font-style: normal;
				font-weight: 900;
				src: url(${LibreFranklinBlack}) format('woff2');
				unicode-range: U+20-22, U+26-29, U+2C-3B, U+3F, U+41-4A, U+4C-50, U+52-57, U+59, U+61-7A, U+A9, U+2013;
			}
			@font-face {
				font-display: swap;
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: 400;
				src: url(${OpenSansRegular}) format('woff2');
				unicode-range: U+20-22, U+26-29, U+2C-3B, U+3F, U+41-4A, U+4C-50, U+52-57, U+59, U+61-7A, U+A9, U+2013;
			}
			@font-face {
				font-display: swap;
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: 500;
				src: url(${OpenSansMedium}) format('woff2');
				unicode-range: U+20-22, U+26-29, U+2C-3B, U+3F, U+41-4A, U+4C-50, U+52-57, U+59, U+61-7A, U+A9, U+2013;
			}
			@font-face {
				font-display: swap;
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: 600;
				src: url(${OpenSansSemiBold}) format('woff2');
				unicode-range: U+20-22, U+26-29, U+2C-3B, U+3F, U+41-4A, U+4C-50, U+52-57, U+59, U+61-7A, U+A9, U+2013;
			}
			@font-face {
				font-display: swap;
				font-family: 'Open Sans';
				font-style: italic;
				font-weight: 600;
				src: url(${OpenSansSemiBoldItalic}) format('woff2');
				unicode-range: U+20-22, U+26-29, U+2C-3B, U+3F, U+41-4A, U+4C-50, U+52-57, U+59, U+61-7A, U+A9, U+2013;
			}
			@font-face {
				font-display: swap;
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: 700;
				src: url(${OpenSansBold}) format('woff2');
				unicode-range: U+20-22, U+26-29, U+2C-3B, U+3F, U+41-4A, U+4C-50, U+52-57, U+59, U+61-7A, U+A9, U+2013;
			}
			@keyframes right {
				0% {
					transform: translate(-15px);
				}
					50% {
					transform: translate(15px);
				}
					100% {
					transform: translate(-15px);
				}
			}
			@keyframes left {
				0% {
					transform: translate(15px);
				}
					50% {
					transform: translate(-15px);
				}
					100% {
					transform: translate(15px);
				}
			}
			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
					100% {
					transform: rotate(360deg);
				}
			}
			@keyframes loading {
				0% {
					transform: translate(0, 0) rotate(0deg);
				}
					50% {
					transform: translate(70px, 0) rotate(360deg);
				}
					100% {
					transform: translate(0, 0) rotate(0deg);
				}
			}
			@keyframes loader {
				90% {
					transform: rotate(0deg);
				}
					100% {
					transform: rotate(180deg);
				}
			}
			@keyframes top {
				90% {
					transform: scale(0);
				}
					100% {
					transform: scale(0);
				}
			}
			@keyframes bottom {
				10% {
					transform: scale(0);
				}
					90% {
					transform: scale(1);
				}
					100% {
					transform: scale(1);
				}
			}
			@keyframes line {
				10% {
					height: 20px;
				}
					100% {
					height: 20px;
				}
			}
			`,
		},
	}
}
