// ----------------------------------------------------------------------

export default function Breadcrumbs() {
	return {
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					fontStyle: 'italic',
					fontWeight: 600,
				},
			},
		},
	}
}
