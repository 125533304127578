import { Unstable_Grid2 as Grid, Typography } from '@mui/material'

//----------------------------------------------------------------------

/**
 * @param {{ message: string; }} props
 */
export default function Marquee(props) {
	const { message } = props
	return (
		<Grid
			xs={12}
			sx={{
				py: 0.5,
				pl: 0.5,
			}}>
			<Typography
				align='center'
				variant='subtitle1'>
				{message}
			</Typography>
		</Grid>
	)
}
