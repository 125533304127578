// ----------------------------------------------------------------------

export default function UseMediaQuery() {
	return {
		MuiUseMediaQuery: {
			defaultProps: {
				noSsr: true,
			},
		},
	}
}
