import HourglassBottomTwoToneIcon from '@mui/icons-material/HourglassBottomTwoTone'
import { Stack, useTheme } from '@mui/material'

function Spinner(props) {
	const theme = useTheme()

	return (
		<HourglassBottomTwoToneIcon
			sx={{
				animation: 'spin 1s linear infinite',
				color: theme.palette.primary.main,
				fontSize: '8em',
				[theme.breakpoints.down('xl')]: {
					fontSize: '6em',
				},
				[theme.breakpoints.down('lg')]: {
					fontSize: '3em',
				},
			}}
			aria-label='loading'
			{...props}
		/>
	)
}

export function FallbackSpinner() {
	return (
		<Stack
			justifyContent='center'
			alignItems='center'
			sx={{
				display: 'flex',
				direction: 'column',
				width: '100vw',
				height: '100vh',
				margin: 'auto',
			}}>
			<Spinner component={HourglassBottomTwoToneIcon} />
		</Stack>
	)
}
