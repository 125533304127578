export { headings, subheadings }

const headings = {
	helperBizForm: 'Welcome, Happy Helper!',
	helperSortForm: "Let's make it searchable!",
	helperTelForm: "Let's connect you to the world!",
}

const subheadings = {
	helperBizForm: "What's the name of your business?",
	helperSortForm: {
		category: 'Under which category should we list your business?',
		location: 'And which location?',
	},
	helperTelForm: 'How should your customers contact you?',
}
