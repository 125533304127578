import { Box, Card, Unstable_Grid2 as Grid, Stack, useTheme } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'
import {
	ListingButton,
	ListingContent,
	ListingDivider,
	ListingHeader,
	ListingImage,
	ListingTitle,
} from './ListingComponents'

// ====================== ListingShell ====================== //
/**
 * @param {{ business: string; category: string; content: string; location: string; logo: string; title: string; }} props
 */
export function ListingShell(props) {
	const { business, category, content, location, logo, title } = props
	const smDown = useResponsive('down', 'sm')
	const smUp = useResponsive('up', 'sm')
	const theme = useTheme()

	return (
		<Card
			sx={{
				m: 2,
				flexDirection: {
					xs: 'column', // mobile
					sm: 'row', // tablet and up
				},
			}}>
			<Grid
				container
				alignItems='center'
				direction='row'
				justifyContent='space-evenly'>
				<Grid
					xs={12}
					sm={8}>
					<Stack
						alignItems='flex-start'
						direction='column'
						justifyContent='space-evenly'>
						<ListingHeader
							business={business}
							category={category}
							location={location}
						/>
						<Box
							pl={3}
							pb={3}
							pr={smDown ? 3 : 2}>
							<ListingDivider theme={theme} />
							<ListingTitle
								theme={theme}
								title={title}
							/>
							<ListingContent
								content={content}
								smDown={smDown}
								theme={theme}
							/>
						</Box>
					</Stack>
				</Grid>
				<Grid
					xs={0}
					sm={4}>
					{smUp && (
						<Stack
							alignItems='flex-end'
							direction='column'
							justifyContent='space-evenly'>
							<Box
								pr={3}
								py={3}>
								<ListingImage logo={logo} />
								<ListingButton />
							</Box>
						</Stack>
					)}
				</Grid>
			</Grid>
		</Card>
	)
}
