import { Typography } from '@mui/material'
import HelperCategoryAutocomplete from '../../../inputs/autocomplete/HelperCategoryAutocomplete'
import HelperLocationAutocomplete from '../../../inputs/autocomplete/HelperLocationAutocomplete'
import { headings, subheadings } from './hlpOnboard'

/**
 * @param {{ formik: { errors: { category: string | number | boolean | import("react").ReactPortal | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode>; } | { location: string | number | boolean | import("react").ReactPortal | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode>; }; handleBlur: { (event: import("react").FocusEvent<HTMLDivElement, Element>): void; (event: import("react").FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void; } | { (event: import("react").FocusEvent<HTMLDivElement, Element>): void; (event: import("react").FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void; }; setFieldValue: ((arg0: string, arg1: any) => void) | ((arg0: string, arg1: any) => void); touched: { category: any; } | { location: any; }; values: { category: unknown; } | { location: unknown; }; }; mdUp: boolean; sending: boolean; smUp: boolean; }} props
 */
export function HelperSortForm(props) {
	const { formik, mdUp, sending, smUp } = props
	return (
		<>
			<Typography
				gutterBottom
				align='left'
				color='primary.main'
				component='h1'
				variant='h3'>
				{headings.helperSortForm}
			</Typography>
			<Typography
				align='left'
				color='text.secondary'
				component='h2'
				my={3}
				textTransform='none'
				variant='h4'>
				{subheadings.helperSortForm.category}
			</Typography>
			<HelperCategoryAutocomplete
				// @ts-ignore
				formik={formik}
				mdUp={mdUp}
				sending={sending}
				smUp={smUp}
			/>
			<Typography
				align='left'
				color='text.secondary'
				component='h2'
				my={3}
				textTransform='none'
				variant='h4'>
				{subheadings.helperSortForm.location}
			</Typography>
			<HelperLocationAutocomplete
				// @ts-ignore
				formik={formik}
				mdUp={mdUp}
				sending={sending}
				smUp={smUp}
			/>
		</>
	)
}
