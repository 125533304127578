import { Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'
import { H6644 } from '../../../utils/variants'
import GlobalBreadcrumbs from '../../navigation/breadcrumbs/GlobalBreadcrumbs'
import { PageWrapper } from '../box/PageWrapper'

// ----------------------------------------------------------------------

/**
 * @param {object} props
 * @param {string} props.id
 * @param {any[]} props.breadcrumbs
 * @param {string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | import("react").ReactPortal} props.children
 * @param {string} props.description
 * @param {string} props.heading
 * @param {string} props.imageAlt
 * @param {string} props.imagePath
 * @param {string | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | import("react").ReactPortal} [props.subheading]
 * @param {string} props.title
 */
export default function StaticLayout(props) {
	const {
		id,
		breadcrumbs,
		children,
		description,
		heading,
		imageAlt,
		imagePath,
		subheading,
		title,
	} = props
	const mdUp = useResponsive('up', 'md')

	return (
		<PageWrapper
			id={id}
			description={description}
			title={title}
			imageAlt={imageAlt}
			imagePath={imagePath}>
			<Container maxWidth='xl'>
				<Grid
					container
					alignItems='center'
					mb={8}>
					<Grid
						xs={0}
						md={4}>
						{mdUp && (
							<img
								alt={imageAlt}
								src={`${imagePath}/${H6644}`}
								style={{
									height: 660,
									objectFit: 'contain',
									marginLeft: 'auto',
									marginRight: 'auto',
									width: 440,
								}}
							/>
						)}
					</Grid>
					<Grid
						xs={11}
						sm={8}
						md={6}
						xl={5}
						mx='auto'>
						<Typography
							color='text.primary'
							component='h1'
							mt={5}
							variant='h2'>
							{heading}
						</Typography>
						{subheading && (
							<Typography
								gutterBottom
								align='left'
								color='text.secondary'
								component='h2'
								variant='h5'
								my={1}>
								{subheading}
							</Typography>
						)}

						<Stack
							spacing={2}
							role='presentation'
							sx={{ mt: 1, mb: 1, ml: 1 }}>
							<GlobalBreadcrumbs breadcrumbs={breadcrumbs} />
						</Stack>
						{children}
					</Grid>
				</Grid>
			</Container>
		</PageWrapper>
	)
}
