import http from '../utils/axios-http'

const createOne = async (/** @type {any} */ data) => {
	const config = {
		method: 'post',
		url: '/listing/account',
		data,
	}

	try {
		return await http.request(config)
	} catch (error) {
		return error
	}
}

const findOneById = async (/** @type {string | number | boolean} */ id) => {
	const config = {
		method: 'get',
		url: `/listing/${encodeURIComponent(id)}`,
	}
	try {
		return await http.request(config)
	} catch (error) {
		return error
	}
}

const findListingsByHelper = async () => {
	const config = {
		method: 'get',
		url: '/listings/helper',
	}
	try {
		const response = await http.request(config)
		return response ? response : []
	} catch (error) {
		return error
	}
}

const paginateListingsByHelper = async (
	/** @type {{ id: string; page: number; pageSize: number; }} */ params,
) => {
	const { id, page, pageSize } = params

	const config = {
		method: 'get',
		url: `/listings/all/helper?id=${encodeURIComponent(
			id,
		)}&page=${encodeURIComponent(page)}&size=${encodeURIComponent(pageSize)}`,
	}

	try {
		return await http.request(config)
	} catch (error) {
		return error
	}
}

const findAllByTitle = async (
	/** @type {{ title: any; page: any; pageSize: any; }} */ params,
) => {
	const { title, page, pageSize } = params

	const config = {
		method: 'get',
		url: `/listings/title?title=${encodeURIComponent(
			title,
		)}&page=${encodeURIComponent(page)}&size=${encodeURIComponent(pageSize)}`,
	}

	try {
		return await http.request(config)
	} catch (error) {
		return error
	}
}

const findAllByCategory = async (
	/** @type {{ category: any; page: any; pageSize: any; }} */ params,
) => {
	const { category, page, pageSize } = params

	const config = {
		method: 'get',
		url: `/listings/category?category=${encodeURIComponent(
			category,
		)}&page=${encodeURIComponent(page)}&size=${encodeURIComponent(pageSize)}`,
	}

	try {
		return await http.request(config)
	} catch (error) {
		return error
	}
}

const findAllByLocation = async (
	/** @type {{ location: any; page: any; pageSize: any; }} */ params,
) => {
	const { location, page, pageSize } = params
	const config = {
		method: 'get',
		url: `/listings/location?location=${encodeURIComponent(
			location,
		)}&page=${encodeURIComponent(page)}&size=${encodeURIComponent(pageSize)}`,
	}
	try {
		return await http.request(config)
	} catch (error) {
		return error
	}
}

const findAllMatching = async (
	/** @type {{ location: any; category: any; page: any; pageSize: any; }} */ params,
) => {
	const { location, category, page, pageSize } = params

	const config = {
		method: 'get',
		url: `/listings/matching?location=${encodeURIComponent(
			location,
		)}&category=${encodeURIComponent(category)}&page=${encodeURIComponent(
			page,
		)}&size=${encodeURIComponent(pageSize)}`,
	}

	try {
		return await http.request(config)
	} catch (error) {
		return error
	}
}

const findAllPublished = async (
	/** @type {{ page: any; pageSize: any; }} */ params,
) => {
	const { page, pageSize } = params

	const config = {
		method: 'get',
		url: `/listings/published?page=${encodeURIComponent(
			page,
		)}&size=${encodeURIComponent(pageSize)}`,
	}

	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const updateOne = async (/** @type {string} */ id, /** @type {any} */ values) => {
	const { title, content } = values

	const config = {
		method: 'post',
		url: `/listing/account/${encodeURIComponent(id)}`,
		data: {
			title,
			content,
		},
	}

	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const deleteOne = async (/** @type {any} */ id) => {
	const config = {
		method: 'delete',
		url: `/listing/account/${encodeURIComponent(id)}`,
	}

	try {
		return await http(config)
	} catch (error) {
		return error
	}
}

const ListingService = {
	createOne,
	findOneById,
	findListingsByHelper,
	paginateListingsByHelper,
	findAllByTitle,
	findAllByCategory,
	findAllByLocation,
	findAllMatching,
	findAllPublished,
	updateOne,
	deleteOne,
}

export default ListingService
