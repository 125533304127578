import {
	EditTwoTone,
	ManageAccountsTwoTone,
	WorkspacePremiumTwoTone,
} from '@mui/icons-material'
import {
	Box,
	Button,
	Card,
	Divider,
	Unstable_Grid2 as Grid,
	Link,
	Stack,
	Typography,
	useTheme,
} from '@mui/material'
import { Link as RouterLink, useLoaderData } from 'react-router-dom'
import { Details, LogoEdit, Plan } from '../../components/data/helpers/account'
import OnboardStepper from '../../components/navigation/stepper/OnboardStepper'
import useResponsive from '../../hooks/useResponsive'
import AuthHeader from './components/AuthHeader'

// ----------------------------------------------------------------------

export default function Dashboard() {
	// @ts-ignore
	const { data } = useLoaderData()
	const { alt, business, category, customer, id, isOnboarded, location, logo, tel } =
		data
	const { credits, plan } = customer

	const smUp = useResponsive('up', 'sm')
	const theme = useTheme()

	const maxCredits = plan === 'craftsman' ? '24' : plan === 'journeyman' ? '20' : '4'
	const tier =
		plan === 'craftsman'
			? `${plan} ($16/mo)`
			: plan === 'journeyman'
			? `${plan} ($20/mo)`
			: `${plan} (free)`

	const breadcrumbs = [
		<Link
			key={0}
			variant='subtitle1'
			component={RouterLink}
			underline='hover'
			to='/home'>
			Home
		</Link>,
		<Typography
			key={1}
			variant='subtitle1'
			color='text.primary'>
			Dashboard
		</Typography>,
	]

	return (
		<AuthHeader
			id='hlp-dashboard'
			title={'My Dashboard - Helper Account | HelpListed'}
			description={
				'The dashboard displays the relevant details for each Happy Helper account.'
			}
			heading='Welcome!'
			breadcrumbs={breadcrumbs}
			children={
				<>
					<Stack sx={{ minHeight: 350, my: 4, mx: smUp ? 2 : 0 }}>
						<Grid
							container
							spacing={3}>
							<Grid
								xs={12}
								md={4}>
								<LogoEdit
									alt={alt}
									id={id}
									logo={logo}
								/>
							</Grid>
							<Grid
								xs={12}
								md={8}>
								<Card>
									<Box
										alignItems='center'
										component='section'
										display='flex'
										justifyContent='space-between'
										p={smUp ? 4 : 2}>
										<Typography
											gutterBottom
											align='left'
											component='h2'
											fontWeight={700}
											variant={smUp ? 'h4' : 'h5'}>
											My Account
										</Typography>
										<Button
											id='hlp-account'
											component={RouterLink}
											startIcon={smUp ? <EditTwoTone /> : null}
											to='/auth/account'
											type='button'
											variant='contained'
											sx={{
												'background': theme.palette.secondary.dark,
												'color': theme.palette.primary.main,
												'maxWidth': '360px',
												'&:hover': {
													background: theme.palette.secondary.main,
												},
											}}>
											Edit
										</Button>
									</Box>
									<Divider aria-hidden='true' />
									{!data ? (
										<Typography variant='subtitle1'>
											Something went wrong!
										</Typography>
									) : isOnboarded === false ? (
										<OnboardStepper id={id} />
									) : (
										<Details
											business={business}
											category={category}
											location={location}
											tel={tel}
										/>
									)}
								</Card>
							</Grid>
						</Grid>
					</Stack>
					<Stack sx={{ minHeight: 350, my: 4, mx: smUp ? 2 : 0 }}>
						<Card>
							<Box
								component='section'
								p={smUp ? 4 : 2}
								display='flex'
								alignItems='center'
								justifyContent='space-between'>
								<Box>
									<Typography
										align='left'
										component='h2'
										fontWeight={700}
										variant='h4'>
										My Plan
									</Typography>
								</Box>

								{tier === 'helper (free)' ? (
									<Button
										id='hlp-account-upgrade'
										component={RouterLink}
										startIcon={smUp ? <WorkspacePremiumTwoTone /> : null}
										to='/pricing'
										type='submit'
										variant='contained'
										sx={{
											'background': theme.palette.secondary.dark,
											'color': theme.palette.primary.main,
											'maxWidth': '360px',
											'&:hover': {
												background: theme.palette.secondary.main,
											},
										}}>
										Upgrade
									</Button>
								) : (
									<form
										action={`/api/v1/helper/account/${id}/portal-session`}
										method='POST'>
										<Button
											id='hlp-account-manage'
											startIcon={smUp ? <ManageAccountsTwoTone /> : null}
											type='submit'
											variant='contained'
											sx={{
												'background': theme.palette.secondary.dark,
												'color': theme.palette.primary.main,
												'maxWidth': '360px',
												'&:hover': {
													background: theme.palette.secondary.main,
												},
											}}>
											Manage
										</Button>
									</form>
								)}
							</Box>
							<Divider aria-hidden='true' />
							<Plan
								id={id}
								credits={credits}
								maxCredits={maxCredits}
								tier={tier}
							/>
						</Card>
					</Stack>
				</>
			}
			imageAlt={''}
			imagePath={''}
		/>
	)
}
