import {
	CardContent,
	Unstable_Grid2 as Grid,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material'
import { useNavigation } from 'react-router-dom'
import useResponsive from '../../../../hooks/useResponsive'
import { telsplit } from '../../../../utils/telsplit'

// ----------------------------------------------------------------------

/**
 * @param {{ business: string; category: string; location: string; tel: string | number; }} props
 */
export default function Details(props) {
	const { business, category, location, tel } = props
	const { setOne, setTwo, setThree } = telsplit(tel)
	const smUp = useResponsive('up', 'sm')
	const navigation = useNavigation()

	return (
		<CardContent sx={{ p: smUp ? 4 : 2 }}>
			<Grid
				container
				direction={smUp ? 'row' : 'column-reverse'}
				alignItems={smUp ? 'center' : 'flex-start'}
				justifyContent={smUp ? 'space-between' : 'space-evenly'}>
				<Grid
					xs={12}
					sm={7}>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='flex-start'>
						<Typography
							gutterBottom
							align='left'
							component='h3'
							fontWeight={700}
							variant='h5'>
							{navigation.state === 'loading' ? (
								<Skeleton sx={{ width: '300px' }} />
							) : (
								business
							)}
						</Typography>
						<Typography
							gutterBottom
							align='left'
							component='h3'
							fontWeight={400}
							variant='h5'>
							{navigation.state === 'loading' ? (
								<Skeleton sx={{ width: '250px' }} />
							) : (
								`(${setOne}) ${setTwo}-${setThree}`
							)}
						</Typography>
						<Typography
							gutterBottom
							align='left'
							component='h3'
							fontWeight={400}
							variant='h5'>
							{navigation.state === 'loading' ? (
								<Skeleton sx={{ width: '250px' }} />
							) : (
								category
							)}
						</Typography>
						<Typography
							gutterBottom
							align='left'
							component='h3'
							fontWeight={400}
							variant='h5'>
							{navigation.state === 'loading' ? (
								<Skeleton sx={{ width: '250px' }} />
							) : (
								location
							)}
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</CardContent>
	)
}
