import { AddLocationAltTwoTone, LocationCityTwoTone } from '@mui/icons-material'
import {
	Dialog,
	DialogTitle,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Menu,
	Stack,
	Typography,
} from '@mui/material'
import { amber } from '@mui/material/colors'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useRef, useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import http from '../../../utils/axios-http'

const address = '/locations'

// ----------------------------------------------------------------------

/**
 * @param {{ onClose: (arg0: string) => void; open: boolean; }} props
 */
export default function Locations(props) {
	const { onClose, open } = props
	const [location, setLocation] = useState(undefined)
	const stateRef = useRef(null)

	const handleClick = () => {
		stateRef.current.scrollIntoView({ behavior: 'smooth' })
	}

	const handleClose = () => {
		onClose(location)
	}

	const handleListItemClick = ({ city, partOf }) => {
		const value = `${city}, ${partOf}`
		setLocation(value)
		onClose(value ? value : location)
	}

	const fetcher = async (/** @type {string} */ url) =>
		await http.get(url).then((res) => res.data)
	const { data, error } = useSWRImmutable(address, fetcher)

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			maxWidth='xs'>
			<DialogTitle>
				Hire Trusted, Local
				<br />
				Contractors!
			</DialogTitle>
			<List
				sx={{ width: '100%', bgcolor: 'background.paper', pt: 0 }}
				aria-labelledby='nested-list-subheader'
				subheader={
					<ListSubheader
						component='div'
						id='nested-list-subheader'
						sx={{ py: 2 }}>
						<Typography
							variant='body1'
							sx={{ textAlign: 'center', textDecoration: 'underline' }}>
							Pick Your Service Area
						</Typography>
					</ListSubheader>
				}>
				{error ? (
					<Stack
						component='div'
						sx={{ p: 1 }}>
						<ListItemText>
							<Typography variant='subtitle1'>
								Sorry, an error occurred. Please refresh and try again.
							</Typography>
						</ListItemText>
					</Stack>
				) : data ? (
					data.map(({ id, state, cities }) => (
						<PopupState
							key={id}
							variant='popover'
							popupId={id}>
							{(popupState) => (
								<>
									<ListItemButton
										onClick={handleClick}
										{...bindTrigger(popupState)}>
										<ListItemAvatar>
											<AddLocationAltTwoTone
												sx={{
													color: amber[700],
												}}
											/>
										</ListItemAvatar>
										<ListItemText>
											<Typography
												variant='body1'
												ref={stateRef}>
												{state}
											</Typography>
										</ListItemText>
									</ListItemButton>
									<Menu {...bindMenu(popupState)}>
										{cities.map(({ _id, city, partOf }) => (
											<ListItemButton
												key={_id}
												onClick={() => {
													handleListItemClick({ city, partOf })
													popupState.close()
												}}>
												<ListItemIcon
													sx={{ color: amber[700] }}
													children={<LocationCityTwoTone fontSize='small' />}
												/>
												<ListItemText
													primaryTypographyProps={{
														variant: 'body2',
														color: 'text.primary',
													}}>
													{city}
												</ListItemText>
											</ListItemButton>
										))}
									</Menu>
								</>
							)}
						</PopupState>
					))
				) : (
					<Stack
						component='div'
						sx={{ p: 1 }}>
						<ListItemText>
							<Typography variant='subtitle1'>Loading...</Typography>
						</ListItemText>
					</Stack>
				)}
			</List>
		</Dialog>
	)
}
