import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// ----------------------------------------------------------------------

export default i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				translation: {
					tagline: {
						part1: 'HelpListed',
						part2: '"Helping Customers and Contractors Find One Another"',
					},
				},
			},
			es: {
				translation: {
					tagline: {
						part1: 'AyudaListada',
						part2: '"Ayudando a los clientes y contratistas a encontrarse"',
					},
				},
			},
		},
	})
