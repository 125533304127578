import Card from './Card'
import Link from './Link'
import Lists from './Lists'
import Alert from './Alert'
import Input from './Input'
import Paper from './Paper'
import Switch from './Switch'
import AppBar from './AppBar'
import Button from './Button'
import Tooltip from './Tooltip'
import Backdrop from './Backdrop'
import Typography from './Typography'
import IconButton from './IconButton'
import Breadcrumbs from './Breadcrumbs'
import CssBaseline from './CssBaseline'
import UseMediaQuery from './UseMediaQuery'
import FormHelperText from './FormHelperText'

// ----------------------------------------------------------------------

/**
 * @param {import("@mui/material").Theme} theme
 */
export function componentsOverride(theme) {
	return Object.assign(
		Card(theme),
		Link(theme),
		Lists(theme),
		Alert(),
		Input(theme),
		Paper(),
		Switch(),
		AppBar(),
		Button(theme),
		Tooltip(theme),
		Backdrop(theme),
		Typography(theme),
		IconButton(theme),
		Breadcrumbs(),
		CssBaseline(),
		UseMediaQuery(),
		FormHelperText(theme),
	)
}
