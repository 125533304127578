import { Stack, Typography, useTheme } from '@mui/material'
import Copyright from './Copyright'

function Footer() {
	const theme = useTheme()
	return (
		<Stack
			component='footer'
			sx={{
				pt: 3,
				m: 'auto',
				background: 'transparent',
				backgroundImage:
					'linear-gradient(to right, rgba(249,250,251,.1) 30%, rgba(227,230,232,.6) 50%, rgba(249,250,251,.1) 70%)',
			}}>
			<Typography
				gutterBottom
				align='center'
				component='h6'
				variant='overline'
				color='text.primary'>
				"Happy to Help"
			</Typography>
			<Stack
				sx={{
					mx: 'auto',
					width: '50%',
					borderTop: () => `1px solid ${theme.palette.divider}`,
				}}>
				<Copyright sx={{ pt: 1 }} />
				<Typography
					paragraph
					align='center'
					fontSize='12px'
					variant='caption'
					color='text.secondary'>
					All Rights Reserved
				</Typography>
			</Stack>
		</Stack>
	)
}

export default Footer
