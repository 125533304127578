import {
	CardContent,
	Divider,
	Unstable_Grid2 as Grid,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material'
import { useNavigation } from 'react-router-dom'
import useResponsive from '../../../../hooks/useResponsive'

// ----------------------------------------------------------------------

/**
 * @param {{ credits: string | number; id: string; maxCredits: string | number; tier: string; }} props
 */
export default function AccountPlan(props) {
	const { credits, maxCredits, tier } = props
	const smUp = useResponsive('up', 'sm')
	const navigation = useNavigation()

	return (
		<CardContent sx={{ p: smUp ? 4 : 2 }}>
			<Grid
				container
				direction={smUp ? 'row' : 'column-reverse'}
				alignItems={smUp ? 'center' : 'flex-start'}
				justifyContent={smUp ? 'space-between' : 'space-evenly'}>
				<Grid
					xs={12}
					sm={6}>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='flex-start'>
						<Typography
							gutterBottom
							align='left'
							component='h4'
							fontWeight={700}
							variant='h6'>
							Tier:{' '}
							<Typography
								component='span'
								variant='h5'
								fontWeight={400}
								textTransform={'capitalize'}>
								{navigation.state === 'loading' ? <Skeleton /> : tier}
							</Typography>
						</Typography>
						<Divider aria-hidden='true' />
						<Typography
							gutterBottom
							align='left'
							component='h4'
							fontWeight={700}
							variant='h6'>
							Monthly Posts:{' '}
							<Typography
								component='span'
								variant='h5'
								fontWeight={400}>
								{navigation.state === 'loading' ? <Skeleton /> : maxCredits}
							</Typography>
						</Typography>

						<Typography
							gutterBottom
							align='left'
							component='h4'
							fontWeight={700}
							variant='h6'>
							Remaining Posts:{' '}
							<Typography
								component='span'
								variant='h5'
								fontWeight={400}>
								{navigation.state === 'loading' ? <Skeleton /> : credits}
							</Typography>
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</CardContent>
	)
}
