import { Box, Grid, Link, Typography } from '@mui/material'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useResponsive from '../../../hooks/useResponsive'
import { authService } from '../../../services/authService'
import Listings from '../listings/Listings'
import Locations from './Locations'

// -------------------------------------------------------------------

/**
 * @param {{ category: string; }} props
 */
export default function LocationHandler(props) {
	const { category } = props
	const locationCookie = Cookies.get('_hlpLoc')
	const [locationOpen, setLocationOpen] = useState(false)
	const [location, setLocation] = useState(locationCookie ? locationCookie : '')
	const smDown = useResponsive('down', 'sm')
	const accVal = Cookies.get('_hlpAcc')

	const handleLocationOpen = () => {
		setLocationOpen(true)
	}

	const handleLocationClose = useCallback(
		(/** @type {import("react").SetStateAction<string>} */ value) => {
			if (value) {
				const callback = value.toString()
				Cookies.set('_hlpLoc', callback, {
					expires: 183,
					secure: true,
					sameSite: 'strict',
				})
				setLocation(callback)
			}
			setLocationOpen(false)
		},
		[setLocationOpen, setLocation],
	)

	const handleLocation = useCallback(() => {
		if (!location || location === 'null') {
			handleLocationOpen()
		}
	}, [location])

	useEffect(() => {
		handleLocation()
	}, [handleLocation])

	return (
		<>
			<Box
				id='location'
				component='section'
				my={2}>
				<Typography
					align='center'
					component='h1'
					variant='overline'
					sx={{ ...(!smDown && { mx: '25%' }) }}>
					{location ? location : 'United States'}
				</Typography>
				<Grid
					container
					alignItems='flex-start'
					direction='row'
					justifyContent='space-between'>
					<Grid
						item
						sx={{
							float: 'left',
							pl: smDown ? 1 : 2,
						}}>
						<Link
							color='inherit'
							component='button'
							onClick={handleLocationOpen}
							variant='button'
							sx={{
								...(smDown && { letterSpacing: 0.2 }),
							}}>
							{smDown
								? location
									? 'Edit Location'
									: 'Pick Location'
								: location
								? 'Change Location'
								: 'Choose Location'}
						</Link>
					</Grid>
					<Grid
						item
						sx={{
							float: 'right',
							pr: smDown ? 1 : 2,
						}}>
						<Link
							color='inherit'
							component={RouterLink}
							variant='button'
							to={
								authService.isAuthenticated
									? '/auth/dashboard'
									: accVal === 'nichtNeu'
									? '/login'
									: '/register'
							}
							sx={{
								textDecoration: 'underline',
								...(smDown && { letterSpacing: 0.2 }),
							}}>
							{smDown ? 'Post Free' : 'Create Free Post'}
						</Link>
					</Grid>
				</Grid>
				<Locations
					open={locationOpen}
					onClose={handleLocationClose}
				/>
			</Box>
			<Box
				id='listings'
				component='section'>
				<Listings
					category={category}
					location={location}
				/>
			</Box>
		</>
	)
}
