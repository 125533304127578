import { Helmet } from 'react-helmet-async'
import { O1263 } from '../../../utils/variants'

const DOMAIN = process.env.REACT_APP_DOMAIN
const DEFAULT_TITLE =
	'HelpListed - Helping Contractors and Customers Find One Another'
const DEFAULT_DESCRIPTION =
	'Need a Plumber? A Locksmith? Whether You Need a few Roofers or an Army of Movers, We Help Local Contractors & Customers Find Each Other. Click to Learn More!'
const DEFAULT_IMAGE = `${DOMAIN}/android-chrome-512x512.png`
const DEFAULT_IMAGE_ALT =
	'the Helplisted logo, depicted by a pair of hands holding a paint roller and hammer with a checkbox between them; atop it all, a yellow hard hat is perched at an angle.'
const DEFAULT_OG_IMAGE = `${DOMAIN}/og-graph-1200x630.png`
const POSTFIX_TITLE = ' | HelpListed'

const getMetaTags = ({
	category,
	contentType,
	creator,
	description,
	imageAlt,
	imagePath,
	published,
	tags,
	title,
	updated,
	url,
}) => {
	const metaTags = [
		{ itemprop: 'name', content: `${title}${POSTFIX_TITLE}` || DEFAULT_TITLE },
		{ itemprop: 'description', content: description || DEFAULT_DESCRIPTION },
		{ itemprop: 'image', content: `${imagePath}/${O1263}` || DEFAULT_IMAGE },
		{ name: 'description', content: description || DEFAULT_DESCRIPTION },
		{ name: 'fb:profile_id', content: 'https://www.facebook.com/HelpListed/about' },
		{ name: 'google', content: 'nositelinkssearchbox' },
		{ property: 'og:description', content: description || DEFAULT_DESCRIPTION },
		{
			property: 'og:image',
			content: `${imagePath}/${O1263}` || DEFAULT_OG_IMAGE,
		},
		{ property: 'og:image:alt', content: imageAlt || DEFAULT_IMAGE_ALT },
		{ property: 'og:image:height', content: imagePath ? 788 : 630 },
		{
			property: 'og:image:secure_url',
			content: `${imagePath}/${O1263}` || DEFAULT_OG_IMAGE,
		},
		{
			property: 'og:image:url',
			content: `${imagePath}/${O1263}` || DEFAULT_OG_IMAGE,
		},
		{ property: 'og:image:width', content: imagePath ? 940 : 1200 },
		{ property: 'og:locale', content: 'en_US' },
		{ property: 'og:site_name', content: 'HelpListed' },
		{ property: 'og:title', content: `${title}${POSTFIX_TITLE}` || DEFAULT_TITLE },
		{ property: 'og:type', content: contentType || 'website' },
		{ property: 'og:url', content: url },
		{ name: 'twitter:card', content: 'summary_large_image' },
		{ name: 'twitter:creator', content: `@${creator}` || '@HelpListed' },
		{ name: 'twitter:description', content: description || DEFAULT_DESCRIPTION },
		{ name: 'twitter:image', content: `${imagePath}/${O1263}` || DEFAULT_IMAGE },
		{ name: 'twitter:image:alt', content: imageAlt || DEFAULT_IMAGE_ALT },
		{ name: 'twitter:image:height', content: imagePath ? 675 : 630 },
		{ name: 'twitter:image:width', content: 1200 },
		{ name: 'twitter:site', content: '@HelpListed' },
		{ name: 'twitter:title', content: `${title}${POSTFIX_TITLE}` || DEFAULT_TITLE },
		{ name: 'twitter:url', content: `${url}/?src=twitter` },
	]
	if (published)
		metaTags.push({ name: 'article:published_time', content: published })
	if (updated) metaTags.push({ name: 'article:modified_time', content: updated })
	if (category) metaTags.push({ name: 'article:section', content: category })
	if (tags) metaTags.push({ name: 'article:tag', content: tags })
	return metaTags
}

/**
 * @param {object} props
 * @param {string} [props.categoryName]
 * @param {string} [props.contentType]
 * @param {string} [props.creator]
 * @param {string} props.description
 * @param {string} [props.imageAlt]
 * @param {string} [props.imagePath]
 * @param {string} [props.path]
 * @param {string} [props.pubTime]
 * @param {string} [props.updatedTime]
 * @param {string[]} [props.tagArray]
 * @param {string} props.title
 */
export default function SeoHelmet(props) {
	const {
		categoryName,
		contentType,
		creator,
		description,
		imageAlt,
		imagePath,
		path,
		pubTime,
		tagArray,
		title,
		updatedTime,
	} = props
	const url = DOMAIN + path
	const published = pubTime || null
	const updated = updatedTime || null
	const category = categoryName || null
	const tags = tagArray || null
	return (
		<Helmet
			title={title + POSTFIX_TITLE}
			link={[
				{
					rel: 'canonical',
					href: url,
				},
				{
					rel: 'home',
					href: DOMAIN,
				},
			]}
			meta={getMetaTags({
				category,
				contentType,
				creator,
				description,
				imageAlt,
				imagePath,
				published,
				tags,
				title,
				updated,
				url,
			})}
		/>
	)
}
