// ----------------------------------------------------------------------

/**
 * @param {import("@mui/material").Theme} theme
 */
export default function Tooltip(theme) {
	return {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: theme.palette.grey[800],
				},
				arrow: {
					color: theme.palette.grey[800],
				},
			},
		},
	}
}
