import { Autocomplete, TextField, Typography } from '@mui/material'
import { CATEGORY_ENUM } from '../../../utils/enums'

/**
 * @param {{ formik: {errors: { category: string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | Iterable<import("react").ReactNode> | import("react").ReactPortal; }; handleBlur: {(event: import("react").FocusEvent<HTMLDivElement, Element>): void; (event: import("react").FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void; }; isSubmitting: boolean; setFieldValue: (arg0: string, arg1: any) => void; touched: {category: any; }; values: {category: unknown; }; }; mdUp: boolean; sending: boolean; smUp: boolean; }} props
 */
export default function HelperCategoryAutocomplete(props) {
	const { formik, mdUp, smUp } = props

	const { errors, handleBlur, isSubmitting, setFieldValue, touched, values } = formik

	return (
		<Autocomplete
			id='hlp-onboard-cat'
			disableClearable
			fullWidth
			disabled={isSubmitting}
			getOptionLabel={(option) => option.toString() || ''}
			onChange={(_, value) => setFieldValue('category', value)}
			onBlur={handleBlur}
			options={CATEGORY_ENUM}
			value={values.category}
			ListboxProps={{
				sx: {
					color: (theme) => theme.palette.secondary.main,
				},
			}}
			sx={{ mt: 2, width: mdUp ? '450px' : smUp ? '275px' : 'default' }}
			renderInput={(params) => (
				<TextField
					{...params}
					required
					color='secondary'
					label='Category'
					name='category'
					onBlur={handleBlur}
					value={values.category}
					variant='outlined'
					error={touched.category && Boolean(errors.category)}
					helperText={
						<Typography
							component='span'
							variant='caption'>
							{touched.category && Boolean(errors.category)
								? errors.category
								: 'Choose the most similar option.'}
						</Typography>
					}
				/>
			)}
		/>
	)
}
