import { DoneOutlineTwoTone } from '@mui/icons-material'
import {
	Box,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material'
import CtaButton from '../../inputs/button/CtaButton'
import { H3828 } from '../../../utils/variants'

// -------------------------------------------------------------------

export const ctaButtonId = 'hlp-cta.contractor.splash'

export const listItems = [
	'credits to post every week, forever.',
	'business page with shareable link.',
	'resources to manage your growth.',
]

// -------------------------------------------------------------------

/**
 * @param {{ mdUp: boolean; smDown: boolean; theme: { palette: { grey: any; }; }; }} props
 */
export default function SectionTwo(props) {
	const { mdUp, smDown, theme } = props
	return (
		<Box
			component='section'
			bgcolor='transparent'
			display='flex'
			minHeight={mdUp ? 500 : 650}
			maxHeight={1300}
			position='relative'
			sx={{
				backgroundImage:
					'linear-gradient(to right, rgba(249,250,251,.1) 30%, rgba(227,230,232,.6) 50%, rgba(249,250,251,.1) 70%)',
			}}
			{...(mdUp && { height: '70vh' })}>
			<Grid
				container
				alignItems='flex-end'
				alignSelf='center'
				position='absolute'>
				<Grid
					item
					xs={12}
					md={8}>
					<Grid
						container
						direction='column'
						justifyContent='center'
						alignItems='flex-start'>
						<Grid
							item
							xs={12}
							mt={2}>
							<Typography
								gutterBottom
								align='left'
								color='primary.main'
								component='h2'
								variant='h2'
								my={2}>
								What we offer
							</Typography>
							<Typography
								align='left'
								color='text.secondary'
								component='h3'
								textTransform='none'
								variant='h3'
								mt={2}>
								Join our Family of Happy Helpers!
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							mt={3}>
							<List disablePadding>
								{listItems.map((listItem, i) => (
									<ListItem
										disablePadding
										alignItems='flex-start'
										key={i}
										sx={{ py: 1 }}>
										{smDown && (
											<ListItemIcon>
												<DoneOutlineTwoTone color='primary' />
											</ListItemIcon>
										)}
										<ListItemText
											primaryTypographyProps={{
												display: 'inline',
												variant: 'h5',
											}}>
											<Typography
												color='info.main'
												component='span'
												variant='h5'>
												FREE{' '}
											</Typography>
											{listItem}
										</ListItemText>
									</ListItem>
								))}
							</List>
						</Grid>
						<Grid
							item
							xs={12}
							md={8}
							mt={2}>
							<Stack
								direction={mdUp ? 'row' : 'column'}
								spacing={1}
								width={mdUp ? '300px' : '200px'}
								{...(mdUp
									? { justifyContent: 'center' }
									: { alignItems: 'center' })}>
								<CtaButton
									id={ctaButtonId}
									buttonText='Free Account'
									color='info'
									textColor={theme.palette.grey[50]}
								/>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
				{mdUp ? (
					<Grid
						item
						xs={4}>
						<Stack
							sx={{
								position: 'relative',
								height: '288px',
								width: '384px',
							}}>
							<img
								src={`${process.env.REACT_APP_IMAGES_DOMAIN}/2aacb63f-ac2e-45f3-8ae4-2216e8c5f000/${H3828}`}
								alt='happy helper character with yellow glow in background'
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									border: 'none',
									margin: 'auto',
									width: 0,
									height: 0,
									minWidth: '100%',
									minHeight: '100%',
									maxHeight: '100%',
									objectFit: 'cover',
								}}
							/>
						</Stack>
					</Grid>
				) : null}
			</Grid>
		</Box>
	)
}
