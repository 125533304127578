import { Container, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'

function SuccessStep() {
	const [height, setHeight] = useState(null)
	const [width, setWidth] = useState(null)
	const confettiRef = useRef(null)

	useEffect(() => {
		setHeight(confettiRef.current.clientHeight)
		setWidth(confettiRef.current.clientWidth)
	}, [])

	return (
		<Container
			maxWidth='lg'
			component='section'
			ref={confettiRef}>
			<Typography
				gutterBottom
				align='center'
				component='h1'
				my={4}
				variant='h2'>
				Congratulations!
			</Typography>
			<Confetti
				width={width}
				height={height}
				run={true}
				recycle={true}
				numberOfPieces={200}
				wind={0}
				gravity={0.1}
				initialVelocityX={4}
				initialVelocityY={10}
				opacity={85}
			/>
		</Container>
	)
}

export default SuccessStep
