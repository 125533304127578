import { ArrowUpwardTwoTone } from '@mui/icons-material'
import { Fab, useScrollTrigger, Zoom } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'

// ----------------------------------------------------------------------

export default function ScrollTop() {
	const mdUp = useResponsive('up', 'md')

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 500,
	})

	const handleClick = (
		/** @type {{ target: { ownerDocument: any; }; }} */ event,
	) => {
		const anchor = (event.target.ownerDocument || document).querySelector(
			'#page-top',
		)

		if (anchor) {
			anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}

	return (
		<Zoom in={trigger}>
			<Fab
				color='primary'
				component='button'
				size='small'
				aria-label='scroll back to top'
				onClick={handleClick}
				role='presentation'
				sx={{
					position: 'fixed',
					bottom: (theme) => (mdUp ? theme.spacing(10) : theme.spacing(4)),
					right: (theme) => (mdUp ? theme.spacing(17) : theme.spacing(4)),
					zIndex: 9999,
				}}>
				<ArrowUpwardTwoTone />
			</Fab>
		</Zoom>
	)
}
