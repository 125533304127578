import { Toolbar } from '@mui/material'
import useResponsive from '../../../hooks/useResponsive'
import { useLocation } from 'react-router-dom'

/**
 * @param {object} props
 * @param {string | number | boolean | import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> | import("react").ReactPortal} [props.children]
 */
export default function StyledToolbar(props) {
	const { children } = props
	const mdUp = useResponsive('up', 'md')
	const { pathname } = useLocation()

	return (
		<Toolbar sx={{ height: mdUp && pathname !== '/' ? '128px' : '96px' }}>
			{children}
		</Toolbar>
	)
}
