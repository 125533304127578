import { Box, Grid, Typography } from '@mui/material'
import CtaTabs from '../../navigation/tabs/CtaTabs'

// -------------------------------------------------------------------

/**
 * @param {{ mdUp: boolean; }} props
 */
export default function SectionThree(props) {
	const { mdUp } = props
	return (
		<Box
			component='section'
			bgcolor='background.default'
			minHeight={700}
			maxHeight={1300}
			position='relative'
			{...(mdUp && { height: '70vh' })}>
			<Grid
				container
				alignItems='center'
				direction='column'
				justifyContent='center'
				position='absolute'
				top='35%'
				sx={{ transform: 'translateY(-35%)' }}>
				<Grid
					item
					xs={12}>
					<Typography
						align='center'
						color='primary.main'
						component='h2'
						variant='h2'>
						Who we help
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					mt={3}>
					<CtaTabs />
				</Grid>
				<Grid
					item
					xs={12}
					mt={8}
					mb={-4}>
					<img
						alt='helplisted-banner'
						style={{
							maxHeight: 200,
							maxWidth: mdUp ? 800 : '100%',
						}}
						src={`${process.env.REACT_APP_IMAGES_DOMAIN}/b7744ef5-d126-40a9-35bf-39da30c6c700/350x197`}
						srcSet={`${process.env.REACT_APP_IMAGES_DOMAIN}/b7744ef5-d126-40a9-35bf-39da30c6c700/350x197 350w, ${process.env.REACT_APP_IMAGES_DOMAIN}/b7744ef5-d126-40a9-35bf-39da30c6c700/600x338 600w, ${process.env.REACT_APP_IMAGES_DOMAIN}/b7744ef5-d126-40a9-35bf-39da30c6c700/960x540 940w`}
						sizes='80vw'
					/>
				</Grid>
			</Grid>
		</Box>
	)
}
