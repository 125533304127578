import { useMediaQuery, useTheme } from '@mui/material'

// ----------------------------------------------------------------------

const useResponsive = (
	/** @type {string} */ query,
	/** @type {number | import('@mui/material').Breakpoint} */ key,
	/** @type {number | import('@mui/material').Breakpoint} */ start,
	/** @type {number | import('@mui/material').Breakpoint} */ end,
) => {
	const theme = useTheme()
	const mediaUp = useMediaQuery(theme.breakpoints.up(key))
	const mediaDown = useMediaQuery(theme.breakpoints.down(key))
	const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end))
	const mediaOnly = useMediaQuery(theme.breakpoints.only(key))

	if (query === 'up') {
		return mediaUp
	}
	if (query === 'down') {
		return mediaDown
	}
	if (query === 'between') {
		return mediaBetween
	}
	if (query === 'only') {
		return mediaOnly
	}
	return null
}

export default useResponsive
