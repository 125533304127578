const createLRGradient = (
	/** @type {string} */ color1,
	/** @type {string} */ color2,
) => `linear-gradient(to right, ${color1}, ${color2})`

const createBTGradient = (
	/** @type {string} */ color1,
	/** @type {string} */ color2,
) => `linear-gradient(to top, ${color1}, ${color2})`

export { createLRGradient, createBTGradient }
