import { Typography, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

function Copyright({ ...props }) {
	return (
		<Typography
			component='span'
			variant='body2'
			color='text.secondary'
			align='center'
			{...props}>
			{'© 2022-'}
			{new Date().getFullYear()}{' '}
			<Link
				component={RouterLink}
				color='inherit'
				to='/'
				underline='hover'>
				{' '}
				HelpListed, LLC
			</Link>
		</Typography>
	)
}

export default Copyright
